import React, { useContext, useMemo, useRef, useState } from "react";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import ApiMembers from "../../../../api/basics/members/ApiMembers";
import { AppContext } from "../../../../App";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton } from "@material-ui/core";

import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import SearchIcon from "@material-ui/icons/Search";
import FormatsUtils from "../../../../services/FormatsUtils";
import Table from "../../../../components/grade/Table";

export default function InputLookupBranchs({ label, value, onChangeValue, roleMember, required, disabled }) {
    const { showMessageError } = useContext(AppContext);

    const [openModal, setOpenModal] = useState(false);

    const [rowCount, setRowCount] = useState(0);
    const [rowPage, setRowPage] = useState(5);
    const [page, setPage] = useState(0);
    const [dataSourceQuery, setDataSourceQuery] = useState([]);

    const [filterName, setFilterName] = useState("");

    const [open, setOpen] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState("");

    const mayLoadDataSource = useRef(false);

    const loadDataSource = async () => {
        setLoading(true);

        // Condition
        const condition = {};
        if (inputValue) {
            condition["search"] = encodeURIComponent(inputValue + "+");
        }
        if (roleMember) {
            condition["role_member_in"] = roleMember;
        }

        try {
            const result = await ApiMembers().query({ page: 1, pageSize: 5, condition });

            if (result.status) {
                if (result.data.length === 1) {
                    setDataSource(result.data);
                    onChangeValue(result.data[0]);
                } else {
                    setDataSource(result.data);
                    setOpen(true);
                }
            } else {
                setDataSource([]);
            }
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const metaData = useMemo(() => [
        {
            label: "Selecionar",
            render: (record) => {
                return (
                    <IconButton
                        size="small"
                        onClick={() => {
                            onChangeValue({ ...record });
                            setOpenModal(false);
                        }}
                    >
                        <ArrowDownwardIcon fontSize="inherit" />
                    </IconButton>
                );
            },
        },
        {
            label: "Documento",
            render: (record) => {
                return <>{FormatsUtils().document(record.document, record.type_member)}</>;
            },
        },
        {
            label: "Nome/Razão Social",
            field: "name",
        },
        {
            label: "Cidade",
            field: "city_name",
        },
        {
            label: "UF",
            field: "state_id",
        },
    ]);

    const loadDataSourceQuery = async (newPage, newRowPage) => {
        // Condition
        const condition = {};
        if (filterName) {
            condition["search"] = encodeURIComponent(filterName + "+");
        }
        if (roleMember) {
            condition["role_member_in"] = roleMember;
        }

        try {
            const result = await ApiMembers().query({ page: newPage + 1, pageSize: newRowPage || rowPage, condition });

            if (result.status) {
                setDataSourceQuery(result.data);
                setRowCount(result.info.total);
            } else {
                setDataSource([]);
                if (result.message) {
                    showMessageError({ active: true, message: result.message });
                }
            }
        } catch (e) {
            showMessageError(e.message);
        }
    };

    return (
        <>
            <Autocomplete
                autoHighlight
                fullWidth
                disabled={disabled}
                noOptionsText="Digite o nome e pressione 'Enter'"
                forcePopupIcon={false}
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => {
                    if (!option?.name) {
                        return null;
                    }
                    return `${option.name} - ${option.city_name}-${option.state_id} (${option.document})`;
                }}
                renderOption={(option) => (
                    <>
                        {option.name} <br />
                        Documento: {option.state_id} - Cidade: {option.city_name}-{option.state_id}
                    </>
                )}
                options={dataSource}
                loading={loading}
                value={value}
                onChange={(event, value, reason) => {
                    onChangeValue(value);
                    if (reason === "select-option") {
                        mayLoadDataSource.current = false;
                    }
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                filterOptions={(options, state) => {
                    return options;
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        required={required}
                        label={label}
                        fullWidth
                        size="small"
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : (
                                        <IconButton size="small" onClick={() => setOpenModal(true)} disabled={disabled}>
                                            <SearchIcon fontSize="inherit" />
                                        </IconButton>
                                    )}
                                </React.Fragment>
                            ),
                        }}
                        onKeyDown={(event) => {
                            if (event.keyCode === 13) {
                                if (mayLoadDataSource.current) {
                                    loadDataSource();
                                }
                                mayLoadDataSource.current = false;
                            } else {
                                if (![9, 37, 38, 39, 40].includes(event.keyCode)) {
                                    mayLoadDataSource.current = true;
                                }
                            }
                        }}
                    />
                )}
            />
            <Dialog fullWidth maxWidth={"md"} open={openModal} onClose={() => setOpenModal(false)}>
                <DialogTitle>{label}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                size="small"
                                label="Nome, Razão Social ou Documento"
                                autoFocus
                                fullWidth
                                variant="outlined"
                                value={filterName}
                                onChange={(e) => setFilterName(e.target.value)}
                                onKeyDown={(event) => {
                                    if (event.keyCode === 13 && openModal) {
                                        loadDataSourceQuery(0);
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton size="small" onClick={() => loadDataSourceQuery(0)}>
                                            <SearchIcon fontSize="inherit" />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Table
                        dataSource={dataSourceQuery}
                        metaData={metaData}
                        rowCount={rowCount}
                        rowPage={rowPage}
                        currentPage={page}
                        onChangePage={(event, newPage) => {
                            loadDataSourceQuery(newPage);
                            setPage(newPage);
                        }}
                        onChangeRowPage={(event) => {
                            loadDataSourceQuery(0, event.target.value);
                            setPage(0);
                            setRowPage(event.target.value);
                        }}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}
