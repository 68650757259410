import React, { useState } from "react";

import { Button, Menu, MenuItem } from "@material-ui/core";

export default function ButtonMenu({ icon, label, menuItems, onClickMenu, ...rest }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const openned = Boolean(anchorEl);

    const onHandleClickMenu = (type) => {
        onClickMenu(type);
        setAnchorEl(false);
    };

    return (
        <>
            <Button size="small" onClick={(event) => setAnchorEl(event.currentTarget)} startIcon={icon} {...rest}>
                {label}
            </Button>

            <Menu size="small" anchorEl={anchorEl} keepMounted open={openned} onClose={() => setAnchorEl(null)}>
                {menuItems.map((rec, index) => {
                    return (
                        <MenuItem key={index} onClick={() => onHandleClickMenu(rec.type)} disabled={rec.disabled}>
                            {rec.label}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
}
