import React, { useState, useEffect, createContext, useMemo } from "react";
import { Link } from "react-router-dom";

import api from "../../services/api";

import MenuApp from "./menu";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { IconButton, AppBar, Toolbar, Typography, Drawer, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export const SISTEMA_VERSAO = "v24.9.15.1";

require("dotenv").config();

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: "flex",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        marginLeft: theme.spacing(6),
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appOptions: {
        top: "auto",
        bottom: 0,
    },
    drawer: {
        width: "300px",
        flexShrink: 0,
    },
    drawerHeader: {
        width: "300px",
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: "100%",
        paddingBottom: 60,
        paddingTop: 60,
    },
}));

export const AppOptionsContext = createContext();

export default function Main(props) {
    const [options, setOptions] = useState(null);

    const [empresa, setEmpresa] = useState(process.env.REACT_APP_TRADING_NAME || "");
    const [openMenu, setOpenMenu] = useState(false);
    const [menus, setMenus] = useState([]);
    const [menuOpenned, setMenuOpenned] = useState({});

    const [title, setTitle] = useState("HOME");

    const classes = useStyles();

    const loadData = async () => {
        if (!empresa) {
            const response_empresa = await api.get("/empresas/current");
            setEmpresa(response_empresa.data.nome);
        }

        const response_menus = await api.get("/menus");
        setMenus(response_menus.data);

        const menusOpenedLocal = {};

        for (let i in response_menus.data) {
            menusOpenedLocal["m" + response_menus.data[i].id_menu] = false;
        }

        setMenuOpenned(menusOpenedLocal);
    };

    useEffect(() => {
        loadData();
    }, []);

    const onOpenMenu = (menu, menu_page) => {
        if (!menu_page) setMenuOpenned({ ...menuOpenned, ["m" + menu.id_menu]: !menuOpenned["m" + menu.id_menu] });
        else setOpenMenu(false);
    };

    const onCloseMenu = (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }

        setOpenMenu(false);
    };

    const defaultOptions = useMemo(() => {
        return (
            <div style={{ width: "100%", textAlign: "center" }}>
                <h3>{`nidas.app - ${SISTEMA_VERSAO}`}</h3>
            </div>
        );
    });

    return (
        <AppOptionsContext.Provider value={{ setOptions, setTitle }}>
            <div id="main" className={classes.root}>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar variant="dense">
                        <IconButton color="inherit" aria-label="open drawer" onClick={() => setOpenMenu(true)} edge="start" className={classes.menuButton}>
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h4">{empresa}</Typography>
                        <Typography variant="h6" className={classes.title}>
                            {title}
                        </Typography>
                        <Link to="/logout">
                            <IconButton color="default">
                                <ExitToAppIcon />
                            </IconButton>
                        </Link>
                    </Toolbar>
                </AppBar>
                <Drawer className={classes.drawer} anchor="left" open={openMenu} onClose={onCloseMenu}>
                    <div className={classes.drawerHeader}>
                        {defaultOptions}
                        <IconButton onClick={() => setOpenMenu(false)}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <MenuApp menus={menus} menusOpened={menuOpenned} onOpenMenu={onOpenMenu} />
                </Drawer>
                <main className={classes.content}>{props.children}</main>
                <AppBar position="fixed" color="default" className={classes.appOptions}>
                    <Toolbar variant="dense">{options || defaultOptions}</Toolbar>
                </AppBar>
            </div>
        </AppOptionsContext.Provider>
    );
}
