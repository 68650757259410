export const TypeCalculate = [
    {
        text: "Por dentro",
        value: "inside",
    },
    {
        text: "Por fora",
        value: "outside",
    },
    {
        text: "Retido",
        value: "hold",
    },
];

export default function TaxesUtils() {
    const typeCalculateText = (value) => {
        return TypeCalculate.find((rec) => rec.value === value)?.text || null;
    };

    return {
        typeCalculateText,
    };
}
