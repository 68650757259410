import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import ApiItems from "../../../../api/basics/items/ApiItems";

import { PageControl, TabSheet, InputText, Grade, GradeOpcoes } from "../../../../components";

import { Grid, Button, Select, InputLabel, MenuItem, FormControl } from "@material-ui/core";
import { AppContext } from "../../../../App";

import InputDropDowList from "../../../../components/inputs/input.dropdowlist2";

import QueryMembers from "../../../basics/query/members";
import { AttributesModal } from "../../../../components/attributes";

import FormatsUtils from "../../../../services/FormatsUtils";
import ItemsTaxesChange from "../itemsTaxesChange";
import { AppOptionsContext } from "../../../../components/main/main";

const initialValues = {
    name: "",
    barcode: "",
    type_item: "goods",
    description: "",
    unit_inventory_id: 0,
    amount_unit_inventory: 1,
    suppliers: [],
    taxes: [],
};

const initialValuesSuppliers = {
    supplier_id: 0,
    code_supplier: "",
    keyword_supplier: "",
    attributes: {},
    supplier: {
        id: 0,
        name: "",
        type_member: "",
        document: "",
    },
};

export default function ItemsChange({ id, onLoadRecord, onFinalize, unitsInventory, attributes, showOptions }) {
    const { setLoading, showMessageError, showMessageInfo } = useContext(AppContext);
    const { setOptions } = useContext(AppOptionsContext);

    const [tabIndex, setTabIndex] = useState(0);
    const [item, setItem] = useState(initialValues);
    const [openAttributes, setOpenAttributes] = useState(false);

    // Associates
    const [itemSuppliers, setItemSuppliers] = useState([]);
    const [itemSupplier, setItemSupplier] = useState(initialValuesSuppliers);

    const [itemTaxes, setItemTaxes] = useState([]);

    const unitsInventoryCustom = useMemo(() => {
        return unitsInventory.map((rec) => {
            return {
                value: rec.id,
                text: `${rec.name} (${rec.id})`,
            };
        });
    }, [unitsInventory]);

    const itemSuppliersAttributes = useMemo(() => {
        const attributesCustom = (attributes || []).find((rec) => rec.entity === "items_suppliers");

        if (attributesCustom) {
            return attributesCustom.values;
        }

        return [];
    }, [attributes]);

    const metaDataSuppliers = useMemo(() => [
        {
            label: "Opções",
            render: (record, index) => {
                const options = [
                    { tipo: "edit", label: "Edição" },
                    { tipo: "remove", label: "Exclusão" },
                ];

                return (
                    <span>
                        <GradeOpcoes record={record} indexRecord={index} onClickOpcoes={onClickOptions} opcoes={options} />
                    </span>
                );
            },
        },
        {
            label: "Nome / Razão Social",
            render: (record) => {
                return (
                    <>
                        {record.supplier?.name}
                        <br />
                        {FormatsUtils().document(record.supplier?.document, record.supplier?.type_member)}
                    </>
                );
            },
        },
        { label: "Referencia", field: "code_supplier" },
        { label: "Palavras Chaves", field: "keyword_supplier" },
        {
            label: "Parametros",
            render: (record) => {
                return <>{Object.keys(record.attributes || {}).length}</>;
            },
        },
        { label: "Dh.Criação", field: "created_at", format: "datetime", minWidth: 160 },
        { label: "Dh.Atualização", field: "updated_at", format: "datetime", minWidth: 160 },
    ]);

    useEffect(() => {
        loadRecord(id);
    }, [id]);

    const onClickOptions = async (record, type, index) => {
        switch (type) {
            case "edit":
                setItemSupplier({ ...record });
                break;
            case "remove":
                const itemSuppliersCustom = [...itemSuppliers];
                itemSuppliersCustom.splice(index, 1);
                setItemSuppliers(itemSuppliersCustom);
                break;
            default:
                break;
        }
    };

    const loadRecord = async (id) => {
        if (!id || id === 0) return;

        setLoading(true);
        try {
            const result = await ApiItems().apiDefault.findId(id, {
                associates: ["suppliers", "suppliers.supplier", "taxes", "taxes.branch", "taxes.tax_rule"],
            });

            if (!result.status) {
                showMessageError(result.message);
                return;
            }

            setItem(result.data);
            setItemSuppliers(result.data.suppliers || []);
            setItemTaxes(result.data.taxes || []);

            onLoadRecord();
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onClearRecord = () => {
        setItem(initialValues);
        setItemSupplier(initialValuesSuppliers);
        setItemSuppliers([]);
        setItemTaxes([]);

        if (id) {
            onFinalize(false);
        }
    };

    const onSave = useCallback(
        async (item, itemSuppliers, itemTaxes) => {
            const itemApply = {
                name: item.name,
                type_item: item.type_item,
                description: item.description,
                unit_inventory_id: item.unit_inventory_id,
                amount_unit_inventory: item.amount_unit_inventory,
                suppliers: [],
            };

            // Suppliers
            itemApply.suppliers = (itemSuppliers || []).map((rec) => {
                return {
                    supplier_id: rec.supplier_id || rec.supplier?.id,
                    code_supplier: rec.code_supplier ? String(rec.code_supplier).trim() || null : null,
                    keyword_supplier: rec.keyword_supplier ? String(rec.keyword_supplier).trim() || null : null,
                    attributes: rec.attributes,
                };
            });

            // Taxes
            itemApply.taxes = (itemTaxes || []).map((rec) => {
                return {
                    branch_id: rec.branch_id || rec.branch?.id,
                    tax_rule_id: rec.tax_rule_id || rec.tax_rule?.id,
                    type_operation: rec.type_operation,
                };
            });

            // Validate

            setLoading(true);
            try {
                let response = {};

                if (id) {
                    response = await ApiItems().apiDefault.update(id, itemApply);
                } else {
                    response = await ApiItems().apiDefault.creates(itemApply);
                }

                if (!response.status) {
                    showMessageError(response.message);
                    return;
                }

                showMessageInfo("Registro atualizado com sucesso");

                onClearRecord();
                onFinalize(true);
            } finally {
                setLoading(false);
            }
        },
        [id]
    );

    useEffect(() => {
        if (showOptions) {
            setOptions(
                <>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => onClearRecord()}
                    >
                        {id ? "Cancelar" : "Limpar tela"}
                    </Button>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => onSave(item, itemSuppliers, itemTaxes)}
                    >
                        Gravar
                    </Button>
                </>
            );
        }
    }, [showOptions, item, itemSuppliers, itemTaxes, id]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                    <FormControl variant="outlined" required size="small" fullWidth>
                        <InputLabel id="demo-simple-outlined-label" shrink={true}>
                            Tipo Item
                        </InputLabel>
                        <Select
                            defaultValue={"goods"}
                            labelId="demo-simple-outlined-label"
                            id="demo-simple-outlined"
                            value={item.type_item}
                            onChange={(e) => setItem({ ...item, type_item: e.target.value })}
                        >
                            <MenuItem value={"goods"}>Mercadoria</MenuItem>
                            <MenuItem value={"service"}>Serviço</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputText required label="Nome" value={item.name} onChangeValue={(e) => setItem({ ...item, name: e.target.value })} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputText label="Código de Barras" value={item.barcode} onChangeValue={(e) => setItem({ ...item, barcode: e.target.value })} />
                </Grid>
            </Grid>
            <PageControl style={{ marginTop: "5px" }} tabindex={tabIndex} onchangetab={(index) => setTabIndex(index)}>
                <TabSheet label="Dados Básico">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={2}>
                            <InputDropDowList
                                value={item.unit_inventory_id}
                                onChangeValue={(e) => setItem({ ...item, unit_inventory_id: e.target.value })}
                                data={unitsInventoryCustom}
                                label={"Unidade Inventário"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputText
                                required
                                label="Qtd. Und Inventário"
                                type="number"
                                value={item.amount_unit_inventory}
                                onChangeValue={(e) => setItem({ ...item, amount_unit_inventory: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                        <Grid item xs={12} sm={12}>
                            <InputText
                                required
                                multiline
                                maxRows={3}
                                minRows={3}
                                label="Descrição"
                                value={item.description}
                                onChangeValue={(e) => setItem({ ...item, description: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                </TabSheet>
                <TabSheet label={item.type_item === "goods" ? "Fornecedores" : "Prestadores"}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <QueryMembers
                                title={`Consulta ${item.type_item === "goods" ? "Fornecedores" : "Prestadores"}`}
                                roleMember="branch,supplier"
                                label={item.type_item === "goods" ? "Fornecedor" : "Prestador"}
                                value={itemSupplier.supplier}
                                onChangeValue={(data) => setItemSupplier({ ...itemSupplier, supplier: data })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                        <Grid item xs={12} sm={3}>
                            <InputText
                                label="Referencia"
                                value={itemSupplier.code_supplier}
                                onChangeValue={(e) => setItemSupplier({ ...itemSupplier, code_supplier: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <InputText
                                label="Palavras Chave"
                                value={itemSupplier.keyword_supplier}
                                onChangeValue={(e) => setItemSupplier({ ...itemSupplier, keyword_supplier: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <Button
                                style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={() => setItemSupplier(initialValuesSuppliers)}
                            >
                                Novo {item.type_item === "goods" ? "Fornecedor" : "Prestador"}
                            </Button>
                            <Button
                                style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => setOpenAttributes(true)}
                            >
                                Parametros
                            </Button>
                            <Button
                                style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    // Search list suppliers
                                    const indexItemSupplier = itemSuppliers.findIndex((rec) => rec.supplier_id === itemSupplier.supplier.id);
                                    const itemSuppliersCustom = [...itemSuppliers];

                                    if (indexItemSupplier === -1) {
                                        itemSupplier.supplier_id = itemSupplier.supplier.id;

                                        itemSuppliersCustom.push(itemSupplier);
                                    } else {
                                        itemSuppliersCustom[indexItemSupplier] = itemSupplier;
                                    }

                                    setItemSuppliers(itemSuppliersCustom);
                                    setItemSupplier(initialValuesSuppliers);
                                }}
                            >
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                    {itemSuppliers?.length > 0 && (
                        <Grid container spacing={1}>
                            <Grade dataSource={itemSuppliers} metaData={metaDataSuppliers} disablepagination={true} />
                        </Grid>
                    )}

                    <AttributesModal
                        open={openAttributes}
                        setOpen={(data) => setOpenAttributes(data)}
                        attributes={itemSuppliersAttributes}
                        value={itemSupplier.attributes}
                        onChangeValue={(data) => setItemSupplier({ ...itemSupplier, attributes: data })}
                    />
                </TabSheet>
                <TabSheet label="Tributário">
                    <ItemsTaxesChange itemTaxes={itemTaxes} setItemTaxes={setItemTaxes} />
                </TabSheet>
            </PageControl>
        </>
    );
}
