import React, { Component } from 'react';

import styles from "../../styles/consulta";

import ApiServiceModal from "../../pages/estabelecimentos/ApiService";

import { Grade } from "../../components";

import { Grid, TextField, Button, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

class ModalEstabelecimentos extends Component {
    state = {
        metaData: [
            { label: "Opções", 
                render: (record) => {
                    return (
                        <IconButton size="small" onClick={() => this.onSelectRecord(record)}>
                            <ArrowDownwardIcon fontSize="inherit" />
                        </IconButton>
                    )
                }
            },
            {label: "Número", field: "id_estabelecimento", align: "right"},
            {label: "Matriz", align: "center", 
            render: (record) => {
                return (<span>
                            {(record.id_matriz === 0?"-":record.id_matriz)}
                        </span>)}},
            {label: "CPF/CNPJ", field: "cnpj_cpf", format: "cnpjcpf", minWidth: 175},
            {label: "Nome", field: "razao_nome"},
            {label: "Cidade", field: "cidade_nome"},
            {label: "UF", field: "cidade_uf"},
        ],
        dataSource: [],        
        rowPage: 5,
        currentPage: 0,
        rowCount: 0,

        nome: "",
        nome_anterior: "",
        openModal: false,
    }
    
    componentDidUpdate(prevProps) {
        const { valueId } = this.props;

        if (valueId !== prevProps.valueId) {
            this.loadRecord(valueId);
        }
    }

    handleCloseModal = () => {
        this.setState({
            openModal: false,
        });
    };

    onOpenModal = () => {
        this.setState({
            openModal: true,
        });
    };

    onSearch = async () => {
        await this.loadData(0);
    }

    loadRecord = async (id) => {
        if (id) {
            const response = await ApiServiceModal.GetId(id);

            this.props.onChangeValue(response.data);

            this.setState({
                nome: response.data.razao_nome,
                nome_anterior: response.data.razao_nome,
            });
        } else {
            this.props.onChangeValue(undefined);

            this.setState({
                nome: "",
                nome_anterior: "",
            });
        }
    }

    loadData = async (page = 0, pageSize = this.state.rowPage) => {
        const filtro = {
            nome: this.state.nome,
        };

        const response = await ApiServiceModal.GetAll(page + 1, filtro, pageSize);

        this.setState({ 
            dataSource: response.data.rows,
            currentPage: page,
            rowPage: pageSize, 
            rowCount: response.data.infoRows.count
        });
    }

    onChangePage = async (event, newPage) => {
        await this.loadData(newPage);
    }
    
    onChangeRowPage = async (event) => {
        await this.loadData(0, event.target.value);
    }

    onSelectRecord(record) {
        this.props.onChangeValue(record);
        this.setState({
            openModal: false,
            dataSource: [],
        });
    }

    handleChangeNome = (event) => {
        this.setState({ 
            nome: event.target.value 
        });

        if (event.target.value === "") {
            this.props.onChangeValue(undefined);
        }
    };

    handleLoseFocus = (event) => {
        if (this.state.nome !== this.state.nome_anterior && this.props.valueId !== 0) {
            this.props.onChangeValue(undefined);

            this.setState({
                nome: "",
                nome_anterior: "",
            })
        }
    };

    render() {
        const { nome, dataSource, metaData, rowCount, rowPage, currentPage } = this.state;
        const { classes, valueId, ...other } = this.props;
        const { openModal } = this.state;        

        return (
            <>
                <TextField 
                    {...other}
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={nome}
                    onChange={this.handleChangeNome}
                    onBlur={this.handleLoseFocus}
                    InputProps={{
                        endAdornment: (
                            <Button 
                                size="small"
                                variant="contained"
                                className={classes.buttonRight}
                                onClick={() => this.onOpenModal()}>
                                Buscar
                            </Button>
                        ),
                    }}                    
                />                
                <Dialog
                    fullWidth
                    maxWidth={"md"}
                    open={openModal}
                    onClose={this.handleCloseModal}>
                    <DialogTitle>Consulta Estabelecimentos</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={10}>
                                <TextField
                                    size="small"
                                    className={classes.textSearch}
                                    label="Pesquisa"
                                    autoFocus
                                    fullWidth
                                    variant="outlined"
                                    value={nome}                            
                                    onChange={e => this.setState({ nome: e.target.value })}
                                    onKeyDown={(event) => {
                                        if (event.keyCode === 13) {
                                            this.onSearch();
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Button 
                                    size="small"
                                    variant="contained"
                                    className={classes.buttonSearch}
                                    onClick={() => this.onSearch()}>Pesquisar
                                </Button>
                            </Grid>
                        </Grid>
                        <Grade 
                            dataSource={dataSource} 
                            metaData={metaData}
                            rowCount={rowCount}
                            rowPage={rowPage}
                            currentPage={currentPage}
                            onChangePage={this.onChangePage}
                            onChangeRowPage={this.onChangeRowPage}/>
                    </DialogContent>
                </Dialog>
            </>
        )
    }
}

export default withStyles(styles)(ModalEstabelecimentos);