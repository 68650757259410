import apiForms from "../../services/api-forms";
import * as yup from "yup";

class ParticipantesService extends apiForms {
    participanteSchema = yup.object().shape({
        cnpj_cpf: yup.string().required("CNPJ/CPF é obrigatório").min(11, "CNPJ/CPF inválido").max(14, "CNPJ/CPF inválido"),
        razao_nome: yup.string().required("Nome é obrigatório").min(1, "Nome não pode ser em branco"),
        tipo_contribuinte: yup.number().required("Tipo de contribuinte é obrigatório").oneOf([1, 2, 9], "Tipo de contribuinte inválido"),
        id_pais: yup.number().required("País é obrigatório").moreThan(0, "País é obrigatório"),
        id_cidade: yup.number().required("Cidade é obrigatória").moreThan(0, "Cidade é obrigatória"),
    });

    defaultValues = {
        id_participante: undefined,
        razao_nome: "",
        cnpj_cpf: "",
        fantasia: "",
        tipo_contribuinte: 1,
        inscricao_estadual: "",
        id_pais: 1058,
        id_cidade: 0,
        cidade_uf: "SC",
        cep: "",
        bairro: "",
        endereco: "",
        endereco_numero: "",
        endereco_complemento: "",
        fone: "",
        email: "",
        dh_alt: undefined,
    };

    constructor() {
        super("participantes");
    }
}

export default new ParticipantesService();
