export const TypeStocksKeeperDefault = "owner";

export const TypeStocksKeeper = [
    {
        text: "Próprio",
        value: TypeStocksKeeperDefault,
    },
    {
        text: "Propriedade Terceiros",
        value: "owner_third",
    },
    {
        text: "Posse Terceiros",
        value: "third_owner",
    },
];

export const TypeIssuerItem = [
    {
        text: "Vincular/Criar no Emissor",
        value: "create_issuer",
    },
    {
        text: "Vincular ao Destinatário",
        value: "linked_receiver",
    },
    {
        text: "Vincular ao Emissor",
        value: "linked_issuer",
    },
];

export const TypeStocksKeeperFilter = [
    {
        text: "Todos",
        value: "-",
    },
    ...TypeStocksKeeper,
];

export const TypeStocksKeeperValues = TypeStocksKeeper.map((rec) => rec.value);
export const TypeIssuerItemValues = TypeIssuerItem.map((rec) => rec.value);

export default function OperationsNatureUtils() {
    const typeStocksKeeperText = (value) => {
        return TypeStocksKeeper.find((rec) => rec.value === value)?.text || null;
    };

    const typeIssuerItemText = (value) => {
        return TypeIssuerItem.find((rec) => rec.value === value)?.text || null;
    };

    return {
        typeStocksKeeperText,
        typeIssuerItemText,
    };
}
