import React, { useState, useEffect, useContext, useMemo } from "react";

import { getInicioMes, getFinalMes, getDecMes } from "../../../services/date.services";

import QueryMembers from "../../basics/query/members";
import FormatsUtils from "../../../services/FormatsUtils";

import { PageControl, TabSheet, GradeOpcoes, InputDate, InputDropDowList, InputDropDowList2, InputText, InputSelector } from "../../../components";

import { Button, MenuItem, Grid } from "@material-ui/core";
import { AppContext } from "../../../App";

import SearchIcon from "@material-ui/icons/Search";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";

import { AppOptionsContext } from "../../../components/main/main";

import TablePagination from "../../../components/grade/TablePagination";
import ApiOrdersTypes from "../../../api/basics/ordersTypes";
import OutboundOrdersDetails from "./details";
import ApiOutboundOrders from "../../../api/stocks/outboundOrders/ApiOutboundOrders";
import OutboundOrdersReceipt from "./receipt";

import UpdateIcon from "@material-ui/icons/Update";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import OutboundOrdersChange from "./change";
import Table from "../../../components/grade/Table";

const initialCondition = {
    id_in: "",
    number_in: "",
    branch: {},
    order_type: "-",
    status: "active",
    status_reference: "-",
    issue_date_less_equal: getFinalMes(getDecMes()),
    issue_date_more_equal: getInicioMes(getDecMes()),
    delivery: false,
};

export default function OutboundOrders() {
    const { setLoading, showMessageError, showMessageInfo, showMessageConfirmation } = useContext(AppContext);
    const { setOptions, setTitle } = useContext(AppOptionsContext);

    const [idDetails, setIdDetails] = useState(null);
    const [openDetails, setOpenDetails] = useState(false);

    const [idInEdition, setIdInEdition] = useState(0);
    const [outboundOrderManual, setOutboundOrderManual] = useState({});

    const [tabIndex, setTabIndex] = useState(0);
    const [dataSource, setDataSource] = useState([]);

    const [rowCount, setRowCount] = useState(0);
    const [rowPage, setRowPage] = useState(10);
    const [page, setPage] = useState(0);

    const [condition, setCondition] = useState(initialCondition);
    const [conditionApply, setConditionApply] = useState(initialCondition);

    const [ordersTypes, setOrdersTypes] = useState([]);

    const metaData = useMemo(() => [
        {
            label: "Opções",
            render: (record) => {
                const options = [{ tipo: "details", label: "Detalhes" }];

                if (record.status === "pending") {
                    options.push({ tipo: "edit", label: "Editar" });
                }

                if (record.status_reference !== "referenced") {
                    options.push({ tipo: "references", label: "Buscar Referencias Automáticas" });
                }

                if (record.status === "active" && !record.list_deliveries_id) {
                    options.push({ tipo: "make_delivery", label: "Gerar Remessa/Documento fiscal" });
                }

                return (
                    <span>
                        <GradeOpcoes record={record} onClickOpcoes={onClickOptions} opcoes={options} />
                    </span>
                );
            },
        },
        { label: "Reg.", field: "id", align: "right" },
        {
            label: "Destinatário",
            render: (record) => {
                return (
                    <>
                        {record.receiver_name}
                        <br />
                        Documento: {FormatsUtils().document(record.receiver_document, record.receiver_type)}
                    </>
                );
            },
        },
        { label: "Data Ordem", field: "issue_date", format: "date" },
        { label: "Remessas", field: "list_deliveries_id" },
        { label: "Documentos Fiscais", field: "list_documents_number" },
        {
            label: "Valor Ordem",
            field: "total",
            format: "float",
            decimal: 2,
            align: "right",
        },
        {
            label: "Situação",
            render: (record) => {
                let situacao = "Pendente";
                switch (record.status) {
                    case "active":
                        situacao = "Ativa";
                        break;
                    case "cancelled":
                        situacao = "Cancelada";
                        break;
                    case "reversed":
                        situacao = "Estornada";
                        break;
                    default:
                        break;
                }

                return <span> {situacao} </span>;
            },
        },
        {
            label: "Situação Referencia",
            render: (record) => {
                let situacao = "Pendente";
                switch (record.status_reference) {
                    case "partial":
                        situacao = "Parcial";
                        break;
                    case "referenced":
                        situacao = "Referenciado";
                        break;
                    default:
                        break;
                }

                return <span> {situacao} </span>;
            },
        },
        {
            label: "Estabelecimento",
            render: (record) => {
                return (
                    <>
                        {record.branch_name}
                        <br />
                        Documento: {FormatsUtils().document(record.branch_document, record.branch_type)}
                    </>
                );
            },
        },
    ]);

    useEffect(() => {
        setTitle("Ordens de Saída");
        loadDataSource(0);

        // Types Documents
        ApiOrdersTypes()
            .apiDefault.findAll({ type_operation: "out" })
            .then((rec) => {
                setOrdersTypes(rec.data);
            });

        return () => {
            setOptions(null);
        };
    }, []);

    const ordersTypesFilter = useMemo(() => {
        const result = ordersTypes.map((rec) => {
            return {
                value: rec.id,
                text: rec.name,
            };
        });

        result.unshift({
            text: "Todos",
            value: "-",
        });

        return result;
    }, [ordersTypes]);

    const loadDataSource = async (newPage, newRowPage, newCondition) => {
        setLoading(true);

        // Condition
        const conditionCustom = makeCondition(newCondition || conditionApply);

        try {
            const result = await ApiOutboundOrders().list({ page: newPage + 1, pageSize: newRowPage || rowPage, condition: conditionCustom });

            if (result.status) {
                setDataSource(result.data);
                setRowCount(result.info.total);
            } else {
                setDataSource([]);
                if (result.message) {
                    showMessageError(result.message);
                }
            }
        } catch (e) {
            showMessageError({ active: true, message: e.message });
        } finally {
            setLoading(false);
        }
    };

    const referencesPendingOutboundOrder = async (id) => {
        setLoading(true);

        try {
            const result = await ApiOutboundOrders().referencesPending({ id });

            if (result.status) {
                if (result.message) {
                    showMessageInfo(
                        <>
                            <strong>Processo de Referencia Executado</strong>
                            <br />
                            <li>Mensagens:</li>
                            {result.message.map((msg) => {
                                return (
                                    <>
                                        -{msg}
                                        <br />
                                    </>
                                );
                            })}
                        </>
                    );
                }
            } else {
                setDataSource([]);
                if (result.message) {
                    showMessageError(result.message);
                }
            }
        } catch (e) {
            showMessageError({ active: true, message: e.message });
        } finally {
            setLoading(false);
        }
    };

    const onReferencesPendingOutboundOrder = async (condition) => {
        setLoading(true);

        try {
            const result = await ApiOutboundOrders().referencesPending(condition, true);

            if (result.status) {
                if (result.message) {
                    showMessageInfo(
                        <>
                            <strong>Processo de Referencia Executado</strong>
                            <br />
                            <li>Mensagens:</li>
                            {result.message.map((msg) => {
                                return (
                                    <>
                                        -{msg}
                                        <br />
                                    </>
                                );
                            })}
                        </>
                    );
                }
            } else {
                setDataSource([]);
                if (result.message) {
                    showMessageError(result.message);
                }
            }
        } catch (e) {
            showMessageError({ active: true, message: e.message });
        } finally {
            setLoading(false);
        }
    };

    const makeCondition = (newCondition) => {
        const result = {};

        // Id
        if (newCondition.id_in) {
            result.id_in = newCondition.id_in
                .split(",")
                .map((rec) => Number(rec))
                .filter(Boolean)
                .join(",");
        } else {
            // Number Document
            if (newCondition.number_in) {
                result.number_in = newCondition.number_in
                    .split(",")
                    .map((rec) => Number(rec))
                    .filter(Boolean)
                    .join(",");
            } else {
                // Branch
                if (newCondition.branch?.id) {
                    result.branch_id = newCondition.branch?.id;
                }

                // Date Due
                if (newCondition.issue_date_less_equal) {
                    result.issue_date_less_equal = FormatsUtils().dateAnsi(newCondition.issue_date_less_equal);
                }
                if (newCondition.issue_date_more_equal) {
                    result.issue_date_more_equal = FormatsUtils().dateAnsi(newCondition.issue_date_more_equal);
                }

                // Status
                if (newCondition.status !== "-") {
                    result.status = newCondition.status;
                }

                // Status
                if (newCondition.status_reference !== "-") {
                    if (newCondition.status_reference === "pending") {
                        result.status_reference_in = "partial,pending";
                    } else {
                        result.status_reference = newCondition.status_reference;
                    }
                }

                // Type Document
                if (newCondition.order_type !== "-") {
                    result.order_type_id = Number(newCondition.order_type);
                }

                // Only No Delivery
                if (newCondition.delivery) {
                    result.delivery = true;
                }
            }
        }

        return result;
    };

    const onClickOptions = (record, tipo) => {
        switch (tipo) {
            case "edit":
                setIdInEdition(record.id);
                break;
            case "details":
                setIdDetails(record.id);
                setOpenDetails(true);
                break;
            case "references":
                referencesPendingOutboundOrder(record.id);
                break;
            case "make_delivery":
                showMessageConfirmation("Confirma geração da Remessa/Documento fiscal? Este processo efetuará o movimento de estoque.", () => {
                    onMakeDelivery(record.id);
                });
                break;
            default:
                break;
        }
    };

    const onMakeDelivery = async (id) => {
        setLoading(true);

        try {
            const result = await ApiOutboundOrders().makeDelivery(id);

            if (result.status) {
                showMessageInfo(
                    <>
                        <strong>Remessa Gerada</strong>
                        <br />
                        <li>Remessa: {result.data?.outbound_delivery_id || 0}</li>
                        <li>
                            Documento Fiscal: {result.data?.number || 0}-{result.data?.serial || "U"}
                        </li>
                        <li>Mensagens:</li>
                        {result.message.map((msg) => {
                            return (
                                <>
                                    -{msg}
                                    <br />
                                </>
                            );
                        })}
                    </>
                );
            } else {
                if (result.message) {
                    showMessageError(result.message);
                }
            }
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onFinalize = async (updated) => {
        setTabIndex(0);
        setIdInEdition(0);
        setOutboundOrderManual({});

        if (updated) {
            loadDataSource(page);
        }
    };

    useEffect(() => {
        if (tabIndex === 0) {
            setOptions(
                <>
                    <TablePagination
                        rowCount={rowCount}
                        rowPage={rowPage}
                        page={page}
                        onPageChange={(event, newPage) => {
                            loadDataSource(newPage);
                            setPage(newPage);
                        }}
                        onRowsPerPageChange={(event) => {
                            loadDataSource(0, event.target.value);
                            setPage(0);
                            setRowPage(event.target.value);
                        }}
                    />
                </>
            );
        }
    }, [tabIndex, rowCount, rowPage, page]);

    return (
        <>
            <PageControl tabindex={tabIndex} onchangetab={(index) => setTabIndex(index)}>
                <TabSheet label="Ordens de Saída">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={2}>
                            <InputText label="Registros" value={condition.id_in} onChangeValue={(e) => setCondition({ ...condition, id_in: e.target.value })} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <QueryMembers
                                title="Consulta Estabelecimentos"
                                roleMember="branch"
                                label="Estabelecimento"
                                value={condition.branch}
                                onChangeValue={(data) => setCondition({ ...condition, branch: data })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDate
                                label="Data inicial"
                                value={condition.issue_date_more_equal}
                                onChangeValue={(date) => setCondition({ ...condition, issue_date_more_equal: date })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDate
                                label="Data final"
                                value={condition.issue_date_less_equal}
                                onChangeValue={(date) => setCondition({ ...condition, issue_date_less_equal: date })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDropDowList
                                defaultValue={0}
                                label="Situação"
                                value={condition.status}
                                onChangeValue={(e) => setCondition({ ...condition, status: e.target.value })}
                            >
                                <MenuItem value={"-"}>Todos</MenuItem>
                                <MenuItem value={"pending"}>Pendente (Em edição)</MenuItem>
                                <MenuItem value={"active"}>Ativos</MenuItem>
                                <MenuItem value={"cancelled"}>Cancelados</MenuItem>
                                <MenuItem value={"reversed"}>Estornados</MenuItem>
                            </InputDropDowList>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDropDowList2
                                value={condition.order_type}
                                onChangeValue={(e) => setCondition({ ...condition, order_type: e.target.value })}
                                data={ordersTypesFilter}
                                label={"Tipo Ordem"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDropDowList
                                defaultValue={0}
                                label="Situação Referencia"
                                value={condition.status_reference}
                                onChangeValue={(e) => setCondition({ ...condition, status_reference: e.target.value })}
                            >
                                <MenuItem value={"-"}>Todos</MenuItem>
                                <MenuItem value={"pending"}>Pendente (Inclusive Parcial)</MenuItem>
                                <MenuItem value={"partial"}>Parcial</MenuItem>
                                <MenuItem value={"referenced"}>Referenciado</MenuItem>
                            </InputDropDowList>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputText
                                label="Documents Fiscais"
                                value={condition.number_in}
                                onChangeValue={(e) => setCondition({ ...condition, number_in: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputSelector
                                label={"Somente Ordens sem Remessas"}
                                value={condition.delivery || false}
                                onChangeValue={(e) => setCondition({ ...condition, delivery: e.target.checked })}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                            variant="contained"
                            size="small"
                            color="secondary"
                            onClick={() => {
                                onReferencesPendingOutboundOrder(makeCondition(conditionApply));
                            }}
                            disabled={!dataSource.length}
                            startIcon={<UpdateIcon fontSize="inherit" />}
                        >
                            Buscar Referencias Automáticas
                        </Button>
                        <Button
                            style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                            variant="contained"
                            size="small"
                            type="primary"
                            onClick={() => {
                                loadDataSource(0, rowPage, condition);
                                setPage(0);
                                setConditionApply(condition);
                            }}
                            startIcon={<SearchIcon fontSize="inherit" />}
                        >
                            Pesquisar
                        </Button>
                        <Button
                            style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                            variant="contained"
                            size="small"
                            type="primary"
                            onClick={() => {
                                setCondition(initialCondition);
                            }}
                            startIcon={<DeleteSweepIcon fontSize="inherit" />}
                        >
                            Limpar
                        </Button>
                    </Grid>
                    <Table dataSource={dataSource} metaData={metaData} />
                </TabSheet>
                <TabSheet label={idInEdition ? `Ordem ${idInEdition}` : null} icon={idInEdition ? null : <AddCircleIcon />}>
                    <Grid container spacing={1}>
                        <OutboundOrdersChange
                            id={idInEdition}
                            dataManual={outboundOrderManual}
                            onLoadRecord={() => setTabIndex(1)}
                            onFinalize={(updated) => onFinalize(updated)}
                            showOptions={tabIndex === 1}
                        />
                    </Grid>
                </TabSheet>
                <TabSheet label="Importação de Arquivos">
                    <OutboundOrdersReceipt showOptions={tabIndex === 2} setOutboundOrderManual={setOutboundOrderManual} />
                </TabSheet>
            </PageControl>
            <OutboundOrdersDetails id={idDetails} open={openDetails} setOpen={setOpenDetails} setLoading={setLoading} />
        </>
    );
}
