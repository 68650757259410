import ApiDefault from "../../ApiDefault";

export default function ApiProcessFiles() {
    const apiDefault = ApiDefault("v2/system/process-files");

    const findAll = async (typeLayout, { page = 1, pageSize = 5, condition, orderBy }) => {
        let urlCustom = `/${apiDefault.url}/${typeLayout}?page=${page}&limit=${pageSize}`;

        for (let field in condition) {
            if (condition[field] !== "") {
                urlCustom += `&${field}=${condition[field]}`;
            }
        }

        if (orderBy) {
            urlCustom += `&orderby=${orderBy}`;
        }

        return await apiDefault.api
            .get(urlCustom)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    

    return {
        apiDefault,
        findAll,
    };
}
