import React, { useState, useEffect } from "react";

import { InputText, ModalEstabelecimentos, PageControl,
         TabSheet, InputSelector, InputDecimal } from "../../components";

import ApiService from "./ApiService";

import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    buttonClear: {
        margin: theme.spacing(1, 1, 0, 0),
        float: "left"
    },
    buttonApply: {
        margin: theme.spacing(1, 1, 0, 0),
        float: "left"
    },
}));

export default function ManutencaoOperadorasCartoes(props) {
    const [ tabIndex, setTabIndex ] = useState(0);
    const [ data, setData ] = useState(ApiService.defaultValues);
    const [ atributos, setAtributos ] = useState(ApiService.defaultValuesAtributos);

    const classes = useStyles();

    useEffect(() => {
        loadRecord(props.id);
    }, [props.id]);

    const loadRecord = async(id) => {
        if (!id || id === 0) return;

        const response = await ApiService.GetId(id);
        if (!response.status) {
            onError(response.message);
            return;
        }

        setData(response.data);
        setAtributos(response.data.atributos);

        props.onLoadRecord();
    } 

    const saveRecord = async(data) => {
        const data_apply = { ...data };

        //Atributos
        data_apply.atributos = atributos;

        const validate = await ApiService.ValidateData(ApiService.operadorasCartoesSchema, data_apply);
        if (!validate.status) {
            onError(validate.message);
            return;
        }

        let response = {};

        if (props.id) {
            response = await ApiService.Update(props.id, data_apply);
        } else {
            response = await ApiService.Append(data_apply);
        }

        if (!response.status) {
            onError(response.message);
            return;
        }

        onClearRecord();
        props.onFinalize(true); 
    }

    const onGravar = () => {
        saveRecord(data);
    }

    const onClearRecord = () => {
        setData(ApiService.defaultValues);
        setAtributos(ApiService.defaultValuesAtributos);

        if (props.id) {
            props.onFinalize(false);
        }
    }

    const onError = (messages) => {
        props.onError(messages)
    }
    
    const handleChangeEstabelecimento = (estabelecimento) => {
        let valor = null;         

        if (estabelecimento) {
            valor = estabelecimento.id_estabelecimento;
        }

        setData({ ...data, id_estabelecimento: valor });
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>  
                    <ModalEstabelecimentos
                        required
                        label="Estabelecimento"
                        valueId={data.id_estabelecimento}
                        onChangeValue={handleChangeEstabelecimento}
                    />  
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputText 
                        required 
                        className={classes.textUpperCase}
                        label="Nome da Operadora Cartao" 
                        value={data.nome}                            
                        onChangeValue={e => setData({ ...data, nome: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputText
                        className={classes.textUpperCase}
                        label="Código Integrador"
                        value={data.tag_integrador}                            
                        onChangeValue={e => setData({ ...data, tag_integrador: e.target.value })}
                    />
                </Grid>
                <PageControl  tabindex={tabIndex} onchangetab={(index) => setTabIndex(index)}>
                    <TabSheet label="Geral">
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <InputText 
                                    className={classes.textUpperCase}
                                    type="number"
                                    label="Leiaute" 
                                    value={atributos.id_leiaute}                            
                                    onChangeValue={e => setAtributos({ ...atributos, id_leiaute: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputDecimal
                                    label="Taxa"
                                    symbol="R$"
                                    decimal={2}
                                    value={atributos.taxa}                                    
                                    onChangeValue={e => setAtributos({ ...atributos, taxa: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputSelector
                                    label="Replica vendas para Matriz?" 
                                    value={atributos.venda_replica_matriz === "S"}
                                    onChangeValue={e => setAtributos({ ...atributos, venda_replica_matriz: (e.target.checked?"S":"N") })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputSelector
                                    label="Replica recebimentos para Matriz?" 
                                    value={atributos.receb_replica_matriz === "S"}
                                    onChangeValue={e => setAtributos({ ...atributos, receb_replica_matriz: (e.target.checked?"S":"N") })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputSelector
                                    label="Efetua lançamento de TED?" 
                                    value={atributos.receb_ted === "S"}
                                    onChangeValue={e => setAtributos({ ...atributos, receb_ted: (e.target.checked?"S":"N") })}
                                />
                            </Grid>
                        </Grid>
                    </TabSheet>
                    <TabSheet label="Vendas">
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <InputText 
                                    className={classes.textUpperCase}
                                    type="number"
                                    label="Conta Crédito" 
                                    value={atributos.venda_conta_cred}                            
                                    onChangeValue={e => setAtributos({ ...atributos, venda_conta_cred: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputText
                                    className={classes.textUpperCase}
                                    type="number"
                                    label="Histórico Crédito"
                                    value={atributos.venda_hist_cred}                            
                                    onChangeValue={e => setAtributos({ ...atributos, venda_hist_cred: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputText 
                                    className={classes.textUpperCase}
                                    type="number"
                                    label="Conta Débito" 
                                    value={atributos.venda_conta_deb}                            
                                    onChangeValue={e => setAtributos({ ...atributos, venda_conta_deb: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputText
                                    className={classes.textUpperCase}
                                    type="number"
                                    label="Histórico Débito"
                                    value={atributos.venda_hist_deb}                            
                                    onChangeValue={e => setAtributos({ ...atributos, venda_hist_deb: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                    </TabSheet>
                    <TabSheet label="Recebimentos">
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <InputSelector
                                    label="Considerar Valor Bruto na Conta Débito (Contra partida Conta Débito Taxa)" 
                                    value={atributos.receb_conta_deb_bruto === "S"}
                                    onChangeValue={e => setAtributos({ ...atributos, receb_conta_deb_bruto: (e.target.checked?"S":"N") })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputText 
                                    className={classes.textUpperCase}
                                    type="number"
                                    label="Conta Crédito" 
                                    value={atributos.receb_conta_cred}                            
                                    onChangeValue={e => setAtributos({ ...atributos, receb_conta_cred: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputText
                                    className={classes.textUpperCase}
                                    type="number"
                                    label="Histórico Crédito"
                                    value={atributos.receb_hist_cred}                            
                                    onChangeValue={e => setAtributos({ ...atributos, receb_hist_cred: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputText 
                                    className={classes.textUpperCase}
                                    type="number"
                                    label="Conta Débito (Taxa)" 
                                    value={atributos.receb_conta_deb_taxa}                            
                                    onChangeValue={e => setAtributos({ ...atributos, receb_conta_deb_taxa: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputText
                                    className={classes.textUpperCase}
                                    type="number"
                                    label="Histórico Débito (Taxa)"
                                    value={atributos.receb_hist_deb_taxa}                            
                                    onChangeValue={e => setAtributos({ ...atributos, receb_hist_deb_taxa: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputText 
                                    className={classes.textUpperCase}
                                    type="number"
                                    label="Conta Débito" 
                                    value={atributos.receb_conta_deb}                            
                                    onChangeValue={e => setAtributos({ ...atributos, receb_conta_deb: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputText
                                    className={classes.textUpperCase}
                                    type="number"
                                    label="Histórico Débito"
                                    value={atributos.receb_hist_deb}                            
                                    onChangeValue={e => setAtributos({ ...atributos, receb_hist_deb: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputText 
                                    disabled={atributos.receb_replica_matriz !== "S"}
                                    type="number"
                                    label="Conta Crédito Matriz" 
                                    value={atributos.receb_conta_cred_matriz}                            
                                    onChangeValue={e => setAtributos({ ...atributos, receb_conta_cred_matriz: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputText
                                    disabled={atributos.receb_replica_matriz !== "S"}
                                    type="number"
                                    label="Histórico Matriz"
                                    value={atributos.receb_hist_matriz}
                                    onChangeValue={e => setAtributos({ ...atributos, receb_hist_matriz: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputText
                                    disabled={atributos.receb_replica_matriz !== "S"}
                                    type="number"
                                    label="Conta Débito Matriz" 
                                    value={atributos.receb_conta_deb_matriz}                            
                                    onChangeValue={e => setAtributos({ ...atributos, receb_conta_deb_matriz: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                    </TabSheet>
                    <TabSheet label="Ajustes">
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <InputText 
                                    className={classes.textUpperCase}
                                    type="number"
                                    label="Conta Crédito" 
                                    value={atributos.ajuste_conta_cred}                            
                                    onChangeValue={e => setAtributos({ ...atributos, ajuste_conta_cred: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputText
                                    className={classes.textUpperCase}
                                    type="number"
                                    label="Histórico Crédito"
                                    value={atributos.ajuste_hist_cred}                            
                                    onChangeValue={e => setAtributos({ ...atributos, ajuste_hist_cred: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputText 
                                    className={classes.textUpperCase}
                                    type="number"
                                    label="Conta Débito" 
                                    value={atributos.ajuste_conta_deb}                            
                                    onChangeValue={e => setAtributos({ ...atributos, ajuste_conta_deb: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputText
                                    className={classes.textUpperCase}
                                    type="number"
                                    label="Histórico Débito"
                                    value={atributos.ajuste_hist_deb}                            
                                    onChangeValue={e => setAtributos({ ...atributos, ajuste_hist_deb: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                    </TabSheet>
                    <TabSheet label="TED">
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <InputText 
                                    disabled={atributos.receb_ted !== "S"}
                                    type="number"
                                    label="Conta Crédito" 
                                    value={atributos.receb_conta_cred_ted}                            
                                    onChangeValue={e => setAtributos({ ...atributos, receb_conta_cred_ted: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputText 
                                    disabled={atributos.receb_ted !== "S"}
                                    type="number"
                                    label="Histórico" 
                                    value={atributos.receb_hist_ted}                            
                                    onChangeValue={e => setAtributos({ ...atributos, receb_hist_ted: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputText
                                    disabled={atributos.receb_ted !== "S"}
                                    type="number"
                                    label="Conta Débito"
                                    value={atributos.receb_conta_deb_ted}                            
                                    onChangeValue={e => setAtributos({ ...atributos, receb_conta_deb_ted: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputDecimal
                                    disabled={atributos.receb_ted !== "S"}
                                    label="Valor da TED"
                                    symbol="R$"
                                    decimal={2}
                                    value={atributos.receb_valor_ted}                                    
                                    onChangeValue={e => setAtributos({ ...atributos, receb_valor_ted: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputText 
                                    disabled={atributos.receb_ted !== "S" || atributos.receb_replica_matriz !== "S"}
                                    type="number"
                                    label="Conta Crédito Matriz" 
                                    value={atributos.receb_conta_cred_ted_matriz}                            
                                    onChangeValue={e => setAtributos({ ...atributos, receb_conta_cred_ted_matriz: e.target.value })}
                                />
                            </Grid>                            
                            <Grid item xs={12} sm={6}>
                                <InputText
                                    disabled={atributos.receb_ted !== "S" || atributos.receb_replica_matriz !== "S"}
                                    type="number"
                                    label="Histórico Matriz"
                                    value={atributos.receb_hist_ted_matriz}
                                    onChangeValue={e => setAtributos({ ...atributos, receb_hist_ted_matriz: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputText
                                    disabled={atributos.receb_ted !== "S" || atributos.receb_replica_matriz !== "S"}
                                    type="number"
                                    label="Conta Débito Matriz" 
                                    value={atributos.receb_conta_deb_ted_matriz}                            
                                    onChangeValue={e => setAtributos({ ...atributos, receb_conta_deb_ted_matriz: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                    </TabSheet>
                </PageControl>
                <Grid item xs={12}>
                    <Button
                        size="small"
                        className={classes.buttonClear}
                        variant="contained"
                        color="secondary"
                        onClick={() => onClearRecord()}>{props.id?"Cancelar":"Limpar tela"}
                    </Button>
                    <Button 
                        size="small"
                        className={classes.buttonApply}
                        variant="contained"
                        color="primary"
                        onClick={() => onGravar()}>Gravar
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}