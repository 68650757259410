import React, { useState, useEffect, useContext } from "react";

import ApiService from "./ApiService";
import ManutencaoOperadorasCartoes from "./ManutencaoOperadorasCartoes";

import { Grade, PageControl, TabSheet, MsgError, GradeOpcoes, InputText, ModalEstabelecimentos } from "../../components";

import { Backdrop, CircularProgress, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AppOptionsContext } from "../../components/main/main";

const useStyles = makeStyles((theme) => ({
    buttonSearch: {
        margin: theme.spacing(1, 1, 0, 0),
        float: "right"
    },
    textSearch: {
        margin: theme.spacing(0),
    },
    loading: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const initialFilter = {
    nome: "",
}

export default function Marketplaces() {
    const { setOptions, setTitle } = useContext(AppOptionsContext);
    
    //Controle de state
    const [ tabIndex, setTabIndex ] = useState(0);
    const [ data, setData ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ rowsCount, setRowsCount] = useState(0);
    const [ condition, setCondition] = useState({ 
        rowsPage: 5, 
        currentPage: 0, 
        filter: initialFilter,
        orderBy: "nome,dh_alt-desc"
    });
    const [ filter, setFilter] = useState(initialFilter);
    const [ error, setError ] = useState({
        active: false,
        msg: null,
    });
    const [ currentIdRecord, setCurrentIdRecord ] = useState(0);

    //Outras contasntes
    const classes = useStyles();
    const metaData = [
        {label: "Opções", 
            render: (record) => {
                return (<span>
                            <GradeOpcoes 
                                record={record}  
                                onClickOpcoes={handleClickOpcoes} 
                                opcoes={[
                                    {   
                                        tipo: "editar",
                                        label: "Editar",
                                    },
                                ]}
                            />
                        </span>)
            }
        },
        {label: "Nome", field: "nome"},
        {label: "CPF/CNPJ", field: "cnpj_cpf", format: "cnpjcpf", minWidth: 175},
        {label: "Razão/Nome", field: "razao_nome"},
        {label: "Dh.Registro", field: "dh_alt", format: "datetime", minWidth: 160},
    ];

    useEffect(() => {
        onLoadData();
    }, [condition]);

    useEffect(() => {
        setTitle("Operadoras de Cartão");
        return () => {
            setOptions(null);
        };
    }, []);

    const onLoadData = async () => {
        setLoading(true);

        const where = {};

        if (condition.filter) {
            if ((condition.filter.id_estabelecimento) && (condition.filter.id_estabelecimento > 0)) {
                where.id_estabelecimento = condition.filter.id_estabelecimento;
            }

            if ((condition.filter.nome) && (condition.filter.nome !== "")) {
                where.nome = condition.filter.nome;
            }            
        }        

        try {
            const response = await ApiService.GetAll(
                condition.currentPage + 1, 
                where,
                condition.rowsPage, 
                condition.orderBy
            );

            if (!response.status) {
                setError({ active: true, msg: response.message});
                return;
            }

            setData(response.data.rows);
            setRowsCount(response.data.infoRows.count);
        } finally {
            setLoading(false);
        }
    }
    
    const handleClickOpcoes = async (record, tipo) => {
        switch (tipo) {
            case "editar":
                setCurrentIdRecord(record.id_operadora_cartao);
                break;
            default:
                break;
        }
    }

    const onFinalize = async (updated) => {
        setTabIndex(0);
        setCurrentIdRecord(0);

        if (updated) {
            onLoadData();
        }
    }

    const handleChangeEstabelecimento = (data) => {
        let valor = undefined;         

        if (data) {
            valor = data.id_estabelecimento;
        }        
        
        setFilter({ ...filter, id_estabelecimento: valor });
    }

    return (
        <>
            <PageControl tabindex={tabIndex} onchangetab={(index) => setTabIndex(index)}>
                <TabSheet label="Consulta">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <ModalEstabelecimentos
                                label="Estabelecimento"
                                valueId={filter.id_estabelecimento}
                                onChangeValue={(data) => handleChangeEstabelecimento(data)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <InputText
                                className={classes.textSearch}
                                label="Pesquisa"
                                autoFocus
                                value={filter.nome}                            
                                onChangeValue={e => setFilter({ ...filter, nome: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button 
                                variant="contained"
                                className={classes.buttonSearch}
                                size="small"
                                type="primary"
                                onClick={() => setCondition({ ...condition, filter, currentPage: 0})}>Pesquisar
                            </Button>
                        </Grid>
                    </Grid>
                    <Grade 
                        dataSource={data} 
                        metaData={metaData}
                        rowCount={rowsCount}
                        rowPage={condition.rowsPage}
                        currentPage={condition.currentPage}
                        onChangePage={(e, page) => setCondition({ ...condition, currentPage: page})}
                        onChangeRowPage={(e) => setCondition({ ...condition, rowsPage: e.target.value})}/>
                </TabSheet>
                <TabSheet label={currentIdRecord>0?"Registro " + currentIdRecord:"Novo Registro"}>
                    <ManutencaoOperadorasCartoes
                        id={currentIdRecord}
                        onLoadRecord={() => setTabIndex(1)}
                        onError={(messages) => setError({ active: true, msg: messages})}
                        onFinalize={onFinalize} />        
                </TabSheet>
            </PageControl>
            <MsgError error={error.active} errorMsg={error.msg} onclose={() => setError({ active: false, msg: null})}/>
            <Backdrop className={classes.loading} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}