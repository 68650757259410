import "./styles/app.css";

import React, { useState, createContext } from "react";
import Routes from "./routes";

import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MsgError } from "./components";
import MsgInfo from "./components/msg/msg-information";
import MsgConfirmation from "./components/msg/Confirmation";

export const AppContext = createContext();

const useStyles = makeStyles((theme) => ({
    loading: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const initialMsgConfirmation = { open: false, message: "", onConfirmation: null, onCancel: null };

export default function App() {
    const [loading, setLoading] = useState(false);
    const [messageError, setMessageError] = useState({ active: false, message: "" });
    const [messageInfo, setMessageInfo] = useState({ active: false, message: "" });
    const [msgConfirmationState, setMsgConfirmationState] = useState(initialMsgConfirmation);

    const classes = useStyles();

    const showMessageError = (message) => {
        setMessageError({
            active: true,
            message,
        });
    };

    const showMessageInfo = (message) => {
        setMessageInfo({
            active: true,
            message,
        });
    };

    const showMessageConfirmation = (message, onConfirmation, onCancel = null) => {
        setMsgConfirmationState({
            open: true,
            message,
            onCancel,
            onConfirmation,
        });
    };

    return (
        <AppContext.Provider value={{ setLoading, setMessageError, setMessageInfo, showMessageError, showMessageInfo, showMessageConfirmation }}>
            <Routes />
            <MsgError error={messageError.active} errorMsg={messageError.message} onclose={() => setMessageError({ active: false, message: null })} />
            <MsgInfo error={messageInfo.active} errorMsg={messageInfo.message} onclose={() => setMessageInfo({ active: false, message: null })} />
            <Backdrop open={loading} className={classes.loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <MsgConfirmation
                open={msgConfirmationState.open}
                message={msgConfirmationState.message}
                setOpen={() => setMsgConfirmationState(initialMsgConfirmation)}
                onConfirmation={msgConfirmationState.onConfirmation}
                onCancel={msgConfirmationState.onCancel}
            />
        </AppContext.Provider>
    );
}
