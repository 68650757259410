import * as yup from "yup";

export function ValidateCustomizations(list) {
    const validateBranch = yup.object().shape({
        branch_id: yup.number().required("Estabelecimento não informado"),
    });

    const validateRules = yup.object().shape({
        name: yup.string().required("Nome da regra é obrigatório").min(3, "Nome da regra deve ter mais que três caracteres"),
        priority: yup.number().required("Prioridade da regra não informado").moreThan(0, "Prioridade da regra inválida"),
        value: yup.string().required("Valor da regra é obrigatória").min(1, "Valor da regra deve ter mais que um caracteres"),
    });

    return yup.object().shape({
        name: yup.string().required("Nome é obrigatório").min(3, "Nome deve ter mais que três caracteres"),
        internal_layout: yup.string().required("Leiaute interno é obrigatório").oneOf(list, "Layout interno inválido"),
        branchs: yup.array().required("Estabelecimentos não informados").min(1, "Nenhum estabelecimento informado").of(validateBranch),
        rules: yup.array().required("Regras não informados").min(1, "Nenhuma regra informada").of(validateRules),
    });
}
