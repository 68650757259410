import React, { useState } from "react";

import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem } from "@material-ui/core";
import QueryCustomizations from "../../query/customizations";
import { InputDropDowList } from "../../../../components";

const initialValues = {
    data: {},
    type: "new",
};

export function CustomizationsRulesModalClone({ typeInternalLayout, onApplyOptions, open, setOpen }) {
    const [options, setOptions] = useState(initialValues);

    return (
        <Dialog
            fullWidth
            maxWidth={"md"}
            open={open}
            onClose={(e, reason) => {
                if (!["backdropClick", "escapeKeyDown"].includes(reason)) {
                    setOpen(false);
                }
            }}
        >
            <DialogTitle>Clonagem de Regras de Customizações</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <QueryCustomizations
                            label={"Selecione a customização"}
                            onChangeValue={(data) => setOptions({ ...options, data })}
                            value={options.data}
                            typeInternalLayout={typeInternalLayout}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputDropDowList
                            defaultValue={0}
                            label="Opção de clonagem"
                            value={options.type}
                            onChangeValue={(e) => setOptions({ ...options, type: e.target.value })}
                        >
                            <MenuItem value={"clear"}>Limpar e substituir regras</MenuItem>
                            <MenuItem value={"merge"}>Mesclar regras pelo atributo de "valor"</MenuItem>
                            <MenuItem value={"new"}>Inserir apenas regras novas, de acordo com atributo de "valor"</MenuItem>
                        </InputDropDowList>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <Button
                            style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                onApplyOptions(options);
                                setOpen(false);
                            }}
                            disabled={!options.data?.id}
                        >
                            Confirmar
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
