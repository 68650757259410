import React, { useState, useEffect, useContext, useMemo } from "react";

import ApiItems from "../../../api/basics/items/ApiItems";
import ApiUnitsInventory from "../../../api/basics/unitsInventory/ApiUnitsInventory";
import ApiAttributes from "../../../api/system/attributes/ApiAttributes";

import { AppContext } from "../../../App";

import { Grade, GradeOpcoes, PageControl, TabSheet, InputText, InputDropDowList, InputSelector } from "../../../components";
import { Button, MenuItem, Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import ItemsChange from "./itemsChange";
import { AppOptionsContext } from "../../../components/main/main";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import TablePagination from "../../../components/grade/TablePagination";
import ReplaceForm from "../../../components/forms/replace";

const initialCondition = {
    id_in: "",
    name: "",
    type_item: "-",
    inactivate: false,
};

export default function Items() {
    const { setLoading, showMessageError } = useContext(AppContext);
    const { setOptions, setTitle } = useContext(AppOptionsContext);

    const [tabIndex, setTabIndex] = useState(0);
    const [dataSource, setDataSource] = useState([]);

    const [rowCount, setRowCount] = useState(0);
    const [rowPage, setRowPage] = useState(10);
    const [page, setPage] = useState(0);

    const [condition, setCondition] = useState(initialCondition);
    const [conditionApply, setConditionApply] = useState(initialCondition);

    const [idInEdition, setIdInEdition] = useState(0);

    const [replaceFrom, setReplaceFrom] = useState("");
    const [replaceTo, setReplaceTo] = useState("");

    const [unitsInventory, setUnitsInventory] = useState([]);
    const [attributesItem, setAttributesItem] = useState([]);

    const metaData = useMemo(() => [
        {
            label: "Opções",
            render: (record) => {
                const options = [
                    { tipo: "edit", label: "Edição" },
                    { tipo: "inactive", label: "Inativação" },
                    { tipo: "replace-from", label: "Substituir" },
                    { tipo: "replace-to", label: "Substituto" },
                ];

                return (
                    <span>
                        <GradeOpcoes record={record} onClickOpcoes={onClickOptions} opcoes={options} />
                    </span>
                );
            },
        },
        { label: "Código", field: "id", align: "right" },
        { label: "Nome", field: "name" },
        { label: "Und.Inv.", field: "unit_inventory_id" },
        { label: "Qt.Und.Inv", field: "amount_unit_inventory" },
        { label: "Dh.Criação", field: "created_at", format: "datetime", minWidth: 160 },
        { label: "Dh.Atualização", field: "updated_at", format: "datetime", minWidth: 160 },
    ]);

    useEffect(() => {
        setTitle("Itens - Mercadorias e Serviços");
        loadDataSource(0);

        // Units Inventory
        ApiUnitsInventory()
            .apiDefault.findAll()
            .then((rec) => {
                setUnitsInventory(rec.data);
            });

        // Attributes
        ApiAttributes()
            .apiDefault.findAll({ entity_in: "items,items_suppliers" })
            .then((rec) => {
                setAttributesItem(rec.data);
            });

        return () => {
            setOptions(null);
        };
    }, []);

    const loadDataSource = async (newPage, newRowPage, newCondition) => {
        setLoading(true);

        // Condition
        const conditionCustom = makeCondition(newCondition || conditionApply);

        try {
            const result = await ApiItems().apiDefault.find({
                page: newPage + 1,
                pageSize: newRowPage || rowPage,
                condition: conditionCustom,
                orderBy: ["name"],
            });

            if (result.status) {
                setDataSource(result.data);
                setRowCount(result.info.total);
            } else {
                setDataSource([]);
                if (result.message) {
                    showMessageError(result.message);
                }
            }
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const makeCondition = (newCondition) => {
        const result = {};

        // Id
        if (newCondition.id_in) {
            result.id_in = newCondition.id_in
                .split(",")
                .map((rec) => Number(rec))
                .filter(Boolean)
                .join(",");
        } else {
            if (newCondition.name) {
                result.name_like = encodeURIComponent(newCondition.name + "+");
            }

            if (!newCondition.inactivate) {
                result.inactivate_at_is_null = true;
            }

            if (newCondition.type_item !== "-") {
                result.type_item = newCondition.type_item;
            }
        }

        return result;
    };

    const onClickOptions = (record, tipo) => {
        switch (tipo) {
            case "edit":
                setIdInEdition(record.id);
                break;
            case "inactive":
                break;
            case "replace-from":
                setReplaceFrom(record.id);
                break;
            case "replace-to":
                setReplaceTo(record.id);
                break;
            default:
                break;
        }
    };

    const onFinalize = async (updated) => {
        setTabIndex(0);
        setIdInEdition(0);

        if (updated) {
            loadDataSource(page);
        }
    };

    useEffect(() => {
        if (tabIndex === 0) {
            setOptions(
                <>
                    <TablePagination
                        rowCount={rowCount}
                        rowPage={rowPage}
                        page={page}
                        onPageChange={(event, newPage) => {
                            loadDataSource(newPage);
                            setPage(newPage);
                        }}
                        onRowsPerPageChange={(event) => {
                            loadDataSource(0, event.target.value);
                            setPage(0);
                            setRowPage(event.target.value);
                        }}
                    />
                </>
            );
        }
    }, [tabIndex, rowCount, rowPage, page]);

    return (
        <>
            <PageControl tabindex={tabIndex} onchangetab={(index) => setTabIndex(index)}>
                <TabSheet label="Consulta">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={2}>
                            <InputText label="Registros" value={condition.id_in} onChangeValue={(e) => setCondition({ ...condition, id_in: e.target.value })} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputText
                                label="Nome ou parte do nome do item"
                                size="small"
                                autoFocus
                                fullWidth
                                variant="outlined"
                                value={condition.name}
                                onChangeValue={(e) => setCondition({ ...condition, name: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDropDowList
                                defaultValue={0}
                                label="Tipo item"
                                value={condition.type_item}
                                onChangeValue={(e) => setCondition({ ...condition, type_item: e.target.value })}
                            >
                                <MenuItem key={"all"} value={"-"}>
                                    Todos
                                </MenuItem>
                                <MenuItem key={"goods"} value={"goods"}>
                                    Mercadorias
                                </MenuItem>
                                <MenuItem key={"service"} value={"service"}>
                                    Serviços
                                </MenuItem>
                            </InputDropDowList>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputSelector
                                label={"Exibir inativos"}
                                value={condition.inactivate || false}
                                onChangeValue={(e) => setCondition({ ...condition, inactivate: e.target.checked })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Button
                                style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                                variant="contained"
                                size="small"
                                type="primary"
                                onClick={() => {
                                    loadDataSource(0, rowPage, condition);
                                    setConditionApply(condition);
                                }}
                                startIcon={<SearchIcon fontSize="inherit" />}
                            >
                                Pesquisar
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grade dataSource={dataSource} metaData={metaData} disablepagination={true} />
                    </Grid>
                </TabSheet>
                <TabSheet label={idInEdition ? `Item ${idInEdition}` : null} icon={idInEdition ? null : <AddCircleIcon />}>
                    <Grid container spacing={1}>
                        <ItemsChange
                            id={idInEdition}
                            onLoadRecord={() => setTabIndex(1)}
                            onFinalize={(updated) => onFinalize(updated)}
                            unitsInventory={unitsInventory}
                            attributes={attributesItem}
                            showOptions={tabIndex === 1}
                        />
                    </Grid>
                </TabSheet>
                <TabSheet label="Sustituir/Eliminar">
                    <ReplaceForm type="items" showOptions={tabIndex === 2} from={replaceFrom} to={replaceTo} />
                </TabSheet>
            </PageControl>
        </>
    );
}
