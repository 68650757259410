import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

function MaskCEP(props) {
    const { inputRef, ...other } = props;
    const masks = {
        cep: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,'-', /[0-9]/, /[0-9]/, /[0-9]/]
    };

    const defineMask = function(rawValue){
        return masks.cep;
    }

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={defineMask}
            placeholderChar={'\u2000'}
            showMask={false}
        />
    );
}
  
MaskCEP.propTypes = {
    inputRef: PropTypes.func.isRequired
};

export default MaskCEP;