import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { InputText, TabSheet, PageControl } from "../../../../components";

import { Grid, Button } from "@material-ui/core";
import { AppContext } from "../../../../App";

import { AppOptionsContext } from "../../../../components/main/main";
import ApiCustomizations from "../../../../api/system/customizations/ApiCustomizations";
import CustomizationsRulesChange from "../rulesChange";
import ListUtils from "../../../../services/ListUtils";
import CustomizationsBranchsChange from "../branchsChange";
import InputDropDowList from "../../../../components/inputs/input.dropdowlist2";
import { ValidateCustomizations } from "../validates";

const initialValues = {
    name: "",
    internal_layout: "",
};

export default function CustomizationsChange({ id, onLoadRecord, onFinalize, showOptions, attributes, types, typesInternalLayout }) {
    const { setLoading, showMessageError, showMessageInfo } = useContext(AppContext);
    const { setOptions } = useContext(AppOptionsContext);

    const [tabIndex, setTabIndex] = useState(0);

    const [customization, setCustomization] = useState(initialValues);
    const [customizationRules, setCustomizationRules] = useState([]);
    const [customizationBranchs, setCustomizationBranchs] = useState([]);

    useEffect(() => {
        loadRecord(id);
    }, [id]);

    const typesRules = useMemo(() => {
        if (!types?.length) {
            return [];
        }

        return types.find((rec) => rec.entity === "customizations_rules" && rec.field === `$${customization.internal_layout}`)?.values || [];
    }, [customization.internal_layout]);

    const loadRecord = async (id) => {
        if (!id || id === 0) return;

        setLoading(true);

        try {
            const result = await ApiCustomizations().apiDefault.findId(id, { associates: ["rules", "branchs", "branchs.branch"] });

            if (!result.status) {
                showMessageError(result.message);
                return;
            }

            setCustomization(result.data);

            let rulesCustom = result.data.rules || [];
            if (rulesCustom?.length) {
                rulesCustom = ListUtils().sort([...rulesCustom], "priority");
            }

            setCustomizationRules(rulesCustom);
            setCustomizationBranchs(result.data.branchs || []);

            onLoadRecord();
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onClearRecord = () => {
        setCustomization(initialValues);
        setCustomizationRules([]);
        setCustomizationBranchs([]);

        setTabIndex(0);

        if (id) {
            onFinalize(false);
        }
    };

    const onSave = useCallback(
        async (customization, rules, branchs) => {
            const customizationApply = {
                name: customization.name,
                internal_layout: customization.internal_layout,
                rules: [],
                branchs: [],
            };

            customizationApply.rules = (rules || []).map((rec) => {
                return {
                    name: rec.name,
                    priority: rec.priority,
                    rules: rec.rules,
                    value: rec.value,
                };
            });

            customizationApply.branchs = (branchs || []).map((rec) => {
                return {
                    branch_id: rec.branch_id,
                };
            });

            // Validate
            const listInternalLayoutValues = typesInternalLayout.map((rec) => rec.value);
            const validate = await ApiCustomizations().apiDefault.validate(ValidateCustomizations(listInternalLayoutValues), customizationApply);
            if (!validate.status) {
                showMessageError(validate.message);
                return;
            }

            // Validate unique key from branch
            const filterUniqueKey = {
                internal_layout: customization.internal_layout,
                "branchs.branch_id_in": customizationApply.branchs.map((rec) => rec.branch_id).join(","),
            };

            if (id) {
                filterUniqueKey["id_diff"] = id;
            }

            const responseValidateUniqueKey = await ApiCustomizations().apiDefault.findAll(filterUniqueKey);
            if (responseValidateUniqueKey.status) {
                const messageUniqueKey = [
                    `Já existem registros com estabelecimentos informados para este tipo de leiaute interno:`,
                    ...(responseValidateUniqueKey.data?.map((rec) => `-${rec.id}-${rec.name}`) || "..."),
                ];

                showMessageError(messageUniqueKey);
                return;
            }

            setLoading(true);
            try {
                let response = {};

                if (id) {
                    response = await ApiCustomizations().apiDefault.update(id, customizationApply);
                } else {
                    response = await ApiCustomizations().apiDefault.creates(customizationApply);
                }

                if (!response.status) {
                    showMessageError(response.message);
                    return;
                }

                showMessageInfo("Registro atualizado com sucesso");

                onClearRecord();
                onFinalize(true);
            } finally {
                setLoading(false);
            }
        },
        [id, typesInternalLayout]
    );

    useEffect(() => {
        if (showOptions) {
            setOptions(
                <>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => onClearRecord()}
                    >
                        {id ? "Cancelar" : "Limpar tela"}
                    </Button>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => onSave(customization, customizationRules, customizationBranchs)}
                    >
                        Gravar
                    </Button>
                </>
            );
        }
    }, [showOptions, customization, customizationRules, customizationBranchs, id]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <InputText
                        required
                        label="Nome"
                        value={customization.name}
                        onChangeValue={(e) => setCustomization({ ...customization, name: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputDropDowList
                        value={customization.internal_layout}
                        onChangeValue={(e) => setCustomization({ ...customization, internal_layout: e.target.value })}
                        data={typesInternalLayout}
                        label={"Leaiute Interno"}
                        disabled={customizationRules?.length}
                    ></InputDropDowList>
                </Grid>
            </Grid>
            <PageControl style={{ marginTop: "10px" }} tabindex={tabIndex} onchangetab={(index) => setTabIndex(index)}>
                <TabSheet label="Estabelecimentos">
                    <CustomizationsBranchsChange branchs={customizationBranchs} setBranchs={setCustomizationBranchs} types={types} />
                </TabSheet>
                <TabSheet label="Regras" disabled={!customization.internal_layout || !typesRules.length}>
                    <CustomizationsRulesChange
                        rules={customizationRules}
                        setRules={setCustomizationRules}
                        types={types}
                        typesRules={typesRules}
                        internalLayoutValue={customization.internal_layout}
                    />
                </TabSheet>
            </PageControl>
        </>
    );
}
