import * as yup from "yup";
import { TypeItemValues, TypeOperationValues } from "../utils";

export function ValidateOrdersTypes() {
    return yup.object().shape({
        name: yup.string().required("Nome é obrigatório").min(3, "Nome deve ter mais que três caracteres"),
        type_operation: yup.string().required("Tipo de operação é obrigatório").oneOf(TypeOperationValues, "Tipo de operação inválido"),
        type_item: yup.string().required("Tipo de item é obrigatório").oneOf(TypeItemValues, "Tipo de item inválido"),
        document_type_id: yup.string().required("Tipo de documento é obrigatório"),
    });
}
