import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import logo from "../../files/logo.ico";

import usuariosService from "../usuarios/usuarios.service";

import { MsgError } from "../../components";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Alert from "@material-ui/lab/Alert";

const styles = (theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class RecuperarSenha extends Component {
    state = {
        data: {
            usuario: "",
        },

        changed: false,
        redirect: false,

        error: false,
        errorMsg: "",
    };

    onRecovery = async (e) => {
        e.preventDefault();

        const { data } = this.state;

        const service = new usuariosService();
        const validate = await service.ValidateData(usuariosService.recuperarSenhaSchema, data);

        if (!validate.status) {
            this.setState({
                error: true,
                errorMsg: validate.message,
            });
            return;
        }

        /* Validação da senha atual */
        const response = await service.Recovery(data.usuario);

        if (!response.status) {
            this.setState({
                error: true,
                errorMsg: response.message,
            });
            return;
        }

        this.setState({
            changed: true,
            redirect: false,
        });
    };

    onCloseError = async () => {
        this.setState({
            error: false,
            errorMsg: "",
        });
    };

    handleCloseChanged = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        this.setState({
            changed: false,
            redirect: true,
        });
    };

    render() {
        const { classes } = this.props;
        const { redirect, data, error, errorMsg, changed } = this.state;

        if (redirect) {
            return <Redirect to="/" />;
        }

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <img src={logo} style={{ width: "100px", height: "100px" }} alt="Logo" />
                    <Typography component="h1" variant="h5">
                        Recuperação de Login
                    </Typography>
                    <form className={classes.form}>
                        <Alert severity="info">
                            Informe o e-mail/usuário para recuperação da senha. Ao clicar em recuperar senha, será enviado um e-mail com instruções.
                        </Alert>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="E-mail/Usuário"
                            autoFocus
                            value={data.usuario}
                            onChange={(e) => this.setState({ data: { ...this.state.data, usuario: e.target.value } })}
                        />
                        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} onClick={this.onRecovery}>
                            Recuperar senha
                        </Button>
                    </form>
                </div>
                <MsgError error={error} errorMsg={errorMsg} onclose={this.onCloseError} />
                <Snackbar open={changed} autoHideDuration={3000} onClose={this.handleCloseChanged}>
                    <MuiAlert elevation={6} variant="filled" onClose={this.handleCloseChanged} severity="success">
                        Recuperação de senha enviada com sucesso!
                    </MuiAlert>
                </Snackbar>
            </Container>
        );
    }
}

export default withStyles(styles)(RecuperarSenha);
