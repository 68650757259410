import React from "react";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import { Avatar } from "@material-ui/core";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}));

function NumberFormatCustom(props) {
    const { inputRef, onChange, symbol, decimal, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                        valueNumber: values.floatValue || 0,
                    },
                });
            }}
            decimalScale={decimal}
            isNumericString
            fixedDecimalScale
            thousandSeparator="."
            decimalSeparator=","
            prefix={symbol ? symbol + " " : ""}
            endAdornment
        />
    );
}

export default function InputDecimal({ label, value, symbol, symbolEnds, decimal, onChangeValue, ...rest }) {
    const classes = useStyles();

    return (
        <TextField
            label={label}
            fullWidth
            size="small"
            variant="outlined"
            value={value}
            onChange={onChangeValue}
            InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                    symbol: symbol,
                    decimal: decimal,
                },
                endAdornment: <>{symbolEnds}</>,
            }}
            {...rest}
        />
    );
}
