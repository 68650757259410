import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { Grade, GradeOpcoes, InputDropDowList2 as InputDropDowList, InputText } from "../../../../components";
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { TypeNature } from "../../../basics/members/utils";
import { TypePayment } from "../../../financial/paymentMethods/utils";
import InputSelectMultiple from "../../../../components/inputs/InputSelectMultiple";
import ApiItems from "../../../../api/basics/items/ApiItems";
import QueryItems from "../../../basics/query/items";
import { AppContext } from "../../../../App";
import ApiMembers from "../../../../api/basics/members/ApiMembers";
import ApiPaymentMethods from "../../../../api/financial/paymentMethods/ApiPaymentMethods";
import ApiTaxes from "../../../../api/fiscal/taxes/ApiTaxes";
import QueryMembers from "../../../basics/query/members";
import QueryPaymentMethods from "../../../financial/query/paymentMethods";
import QueryTaxes from "../../../fiscal/query/taxes";
import { Alert } from "@material-ui/lab";
import ApiDocumentsTypes from "../../../../api/basics/documentsTypes";
import QueryDocumentsTypes from "../../../basics/query/documentsTypes";
import ApiSuppliersGroups from "../../../../api/basics/suppliersGroups";
import QuerySuppliersGroups from "../../../basics/query/suppliersGroups";

const initialValues = {
    index: -1,
    name: "",
    values: [],
};

const itensAllowAdd = [
    "list-item-id",
    "list-branch-id",
    "list-payment-method-id",
    "list-tax-id",
    "list-member-id",
    "list-document-type-id",
    "list-supplier-group-id",
];
const itensAllowList = [...itensAllowAdd, "list-type-nature", "list-type-payment", "list-type-tax-regime", "list-document-finality"];
const itensAllowText = ["text", "text-match"];

export function CustomizationsRulesModalChange({ typesRules, typesRulesFilter, types, value, onChangeValue, open, setOpen, title }) {
    const { setLoading } = useContext(AppContext);

    const [valueRules, setValueRules] = useState(value);
    const [rule, setRule] = useState(initialValues);

    const [labelSourceRules, setLabelSourceRules] = useState("Não definido");
    const [dataSourceRules, setDataSourceRules] = useState([]);

    const [newSourceRules, setNewSourceRules] = useState({});

    const typeTaxRegime = useMemo(() => {
        return (
            types
                .find((rec) => rec.entity === "members" && rec.field === "type_tax_regime")
                ?.values?.map((rec) => {
                    return {
                        value: rec.value,
                        text: rec.name,
                    };
                }) || []
        );
    }, [types]);

    const typeFinality = useMemo(() => {
        return (
            types
                .find((rec) => rec.entity === "documents" && rec.field === "type_finality")
                ?.values?.map((rec) => {
                    return {
                        value: rec.value,
                        text: rec.name,
                    };
                }) || []
        );
    }, [types]);

    const typeRule = useMemo(() => {
        if (!typesRules?.length) {
            return null;
        }

        return typesRules.find((rec) => rec.name === rule.name);
    }, [typesRules, rule.name]);

    const metaData = useMemo(() => {
        return [
            {
                label: "Opções",
                render: (record, index) => {
                    const options = [
                        { tipo: "edit", label: "Edição" },
                        { tipo: "remove", label: "Exclusão" },
                    ];

                    return (
                        <span>
                            <GradeOpcoes record={record} indexRecord={index} onClickOpcoes={onClickOptions} opcoes={options} />
                        </span>
                    );
                },
            },
            {
                label: "Condição",
                render: (record) => {
                    const type = typesRulesFilter.find((rec) => rec.value === record.name);
                    return <>{type?.text || "NDA"}</>;
                },
            },
            {
                label: "Valores",
                render: (record) => {
                    const type = typesRules.find((rec) => rec.name === record.name)?.type || "nda";

                    let valuesCustom = [...record.values] || [];
                    switch (type) {
                        case "list-type-nature":
                            valuesCustom = valuesCustom.map((rec) => {
                                const typeNature = TypeNature.find((recType) => recType.value === rec);
                                return typeNature.text;
                            });
                            break;
                        case "list-type-payment":
                            valuesCustom = valuesCustom.map((rec) => {
                                const typePayment = TypePayment.find((recType) => recType.value === rec);
                                return typePayment.text;
                            });
                            break;
                        case "list-type-tax-regime":
                            valuesCustom = valuesCustom.map((rec) => {
                                const typeTaxRegimeCustom = typeTaxRegime.find((recType) => recType.value === rec);
                                return typeTaxRegimeCustom.text;
                            });
                            break;
                        case "list-document-finality":
                            valuesCustom = valuesCustom.map((rec) => {
                                const typeFinalityCustom = typeFinality.find((recType) => recType.value === rec);
                                return typeFinalityCustom.text;
                            });
                            break;
                        default:
                            break;
                    }

                    // Text
                    if (itensAllowText.includes(type)) {
                        return <>{record.values}</>;
                    }

                    return <>{valuesCustom.join("; ")}</>;
                },
            },
        ];
    }, [typesRulesFilter, typesRules, valueRules, typeTaxRegime, typeFinality]);

    const onClickOptions = async (record, type, index) => {
        switch (type) {
            case "edit":
                setRule({ ...record, index: index });
                break;
            case "remove":
                const rulesCustom = [...valueRules];
                rulesCustom.splice(index, 1);
                setValueRules(rulesCustom);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        setValueRules(value);
    }, [value]);

    useEffect(() => {
        if (open) {
            setValueRules(value);
        }
    }, [open, value]);

    const loadItems = useCallback(async (items) => {
        if (!items?.length) {
            return [];
        }

        setLoading(true);
        try {
            const result = await ApiItems().apiDefault.findAll({ id_in: (items || []).join(",") });

            if (result.status) {
                return result.data.map((rec) => {
                    return {
                        value: rec.id,
                        text: rec.name,
                    };
                });
            }
        } finally {
            setLoading(false);
        }

        return [];
    });

    const loadMembers = useCallback(async (members) => {
        if (!members?.length) {
            return [];
        }

        setLoading(true);
        try {
            const result = await ApiMembers().apiDefault.findAll({ id_in: (members || []).join(",") });

            if (result.status) {
                return result.data.map((rec) => {
                    return {
                        value: rec.id,
                        text: rec.name,
                    };
                });
            }
        } finally {
            setLoading(false);
        }

        return [];
    });

    const loadPaymentMethods = useCallback(async (paymentMethods) => {
        if (!paymentMethods?.length) {
            return [];
        }

        setLoading(true);
        try {
            const result = await ApiPaymentMethods().apiDefault.findAll({ id_in: (paymentMethods || []).join(",") });

            if (result.status) {
                return result.data.map((rec) => {
                    return {
                        value: rec.id,
                        text: rec.name,
                    };
                });
            }
        } finally {
            setLoading(false);
        }

        return [];
    });

    const loadTaxes = useCallback(async (taxes) => {
        if (!taxes?.length) {
            return [];
        }

        setLoading(true);
        try {
            const result = await ApiTaxes().apiDefault.findAll({ id_in: (taxes || []).join(",") });

            if (result.status) {
                return result.data.map((rec) => {
                    return {
                        value: rec.id,
                        text: rec.name,
                    };
                });
            }
        } finally {
            setLoading(false);
        }

        return [];
    });

    const loadDocumentsTypes = useCallback(async (taxes) => {
        if (!taxes?.length) {
            return [];
        }

        setLoading(true);
        try {
            const result = await ApiDocumentsTypes().apiDefault.findAll({ id_in: (taxes || []).join(",") });

            if (result.status) {
                return result.data.map((rec) => {
                    return {
                        value: rec.id,
                        text: rec.name,
                    };
                });
            }
        } finally {
            setLoading(false);
        }

        return [];
    });

    const loadSuppliersGroups = useCallback(async (taxes) => {
        if (!taxes?.length) {
            return [];
        }

        setLoading(true);
        try {
            const result = await ApiSuppliersGroups().apiDefault.findAll({ id_in: (taxes || []).join(",") });

            if (result.status) {
                return result.data.map((rec) => {
                    return {
                        value: rec.id,
                        text: rec.name,
                    };
                });
            }
        } finally {
            setLoading(false);
        }

        return [];
    });

    useEffect(() => {
        setLabelSourceRules(typeRule?.description);

        switch (typeRule?.type) {
            case "list-branch-id":
                loadMembers(rule.values).then((list) => {
                    setDataSourceRules(list);
                });
                break;
            case "list-member-id":
                loadMembers(rule.values).then((list) => {
                    setDataSourceRules(list);
                });
                break;
            case "list-type-nature":
                setDataSourceRules(TypeNature);
                break;
            case "list-type-tax-regime":
                setDataSourceRules(typeTaxRegime);
                break;
            case "list-document-finality":
                setDataSourceRules(typeFinality);
                break;
            case "list-item-id":
                loadItems(rule.values).then((list) => {
                    setDataSourceRules(list);
                });
                break;
            case "list-payment-method-id":
                loadPaymentMethods(rule.values).then((list) => {
                    setDataSourceRules(list);
                });
                break;
            case "list-type-payment":
                setDataSourceRules(TypePayment);
                break;
            case "list-tax-id":
                loadTaxes(rule.values).then((list) => {
                    setDataSourceRules(list);
                });
                break;
            case "list-document-type-id":
                loadDocumentsTypes(rule.values).then((list) => {
                    setDataSourceRules(list);
                });
                break;
            case "list-supplier-group-id":
                loadSuppliersGroups(rule.values).then((list) => {
                    setDataSourceRules(list);
                });
                break;
            default:
                break;
        }
    }, [rule.name]);

    return (
        <Dialog
            fullWidth
            maxWidth={"md"}
            open={open}
            onClose={(e, reason) => {
                if (!["backdropClick", "escapeKeyDown"].includes(reason)) {
                    setOpen(false);
                }
            }}
        >
            <DialogTitle>Condições para {title || "ND"}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <InputDropDowList
                            value={rule.name}
                            onChangeValue={(e) => setRule({ ...rule, name: e.target.value })}
                            data={typesRulesFilter}
                            label={"Condição"}
                        ></InputDropDowList>
                    </Grid>
                    {typeRule?.type === "list-item-id" && (
                        <Grid item xs={12} sm={6}>
                            <QueryItems
                                label={"Selecione novos itens"}
                                title={"Consulta de Itens"}
                                onChangeValue={(item) => {
                                    if (!item) {
                                        setNewSourceRules({});
                                    } else {
                                        setNewSourceRules({
                                            id: item.id,
                                            name: item.name,
                                        });
                                    }
                                }}
                                value={newSourceRules}
                            />
                        </Grid>
                    )}
                    {typeRule?.type === "list-branch-id" && (
                        <Grid item xs={12} sm={6}>
                            <QueryMembers
                                label={"Selecione novos estabelecimentos"}
                                roleMember={"branch"}
                                title={"Consulta de Estabelecimentos"}
                                onChangeValue={(item) => {
                                    if (!item) {
                                        setNewSourceRules({});
                                    } else {
                                        setNewSourceRules({
                                            id: item.id,
                                            name: item.name,
                                        });
                                    }
                                }}
                                value={newSourceRules}
                            />
                        </Grid>
                    )}
                    {typeRule?.type === "list-member-id" && (
                        <Grid item xs={12} sm={6}>
                            <QueryMembers
                                label={"Selecione novos remetente/destinatário"}
                                roleMember={"customer,supplier"}
                                title={"Consulta de Remetente/Destinatário"}
                                onChangeValue={(item) => {
                                    if (!item) {
                                        setNewSourceRules({});
                                    } else {
                                        setNewSourceRules({
                                            id: item.id,
                                            name: item.name,
                                        });
                                    }
                                }}
                                value={newSourceRules}
                            />
                        </Grid>
                    )}
                    {typeRule?.type === "list-payment-method-id" && (
                        <Grid item xs={12} sm={6}>
                            <QueryPaymentMethods
                                label={"Selecione novos metodos de pagamento"}
                                title={"Consulta de Metodos Pagamentos"}
                                onChangeValue={(item) => {
                                    if (!item) {
                                        setNewSourceRules({});
                                    } else {
                                        setNewSourceRules({
                                            id: item.id,
                                            name: item.name,
                                        });
                                    }
                                }}
                                value={newSourceRules}
                            />
                        </Grid>
                    )}
                    {typeRule?.type === "list-tax-id" && (
                        <Grid item xs={12} sm={6}>
                            <QueryTaxes
                                label={"Selecione novos tributos"}
                                title={"Consulta de Tributos"}
                                onChangeValue={(item) => {
                                    if (!item) {
                                        setNewSourceRules({});
                                    } else {
                                        setNewSourceRules({
                                            id: item.id,
                                            name: item.name,
                                        });
                                    }
                                }}
                                value={newSourceRules}
                            />
                        </Grid>
                    )}
                    {typeRule?.type === "list-document-type-id" && (
                        <Grid item xs={12} sm={6}>
                            <QueryDocumentsTypes
                                label={"Selecione novos tipos de documentos"}
                                title={"Consulta de Tipos de Documentos"}
                                onChangeValue={(item) => {
                                    if (!item) {
                                        setNewSourceRules({});
                                    } else {
                                        setNewSourceRules({
                                            id: item.id,
                                            name: item.name,
                                        });
                                    }
                                }}
                                value={newSourceRules}
                            />
                        </Grid>
                    )}
                    {typeRule?.type === "list-supplier-group-id" && (
                        <Grid item xs={12} sm={6}>
                            <QuerySuppliersGroups
                                label={"Selecione novos grupos de fornecedores"}
                                title={"Consulta de Grupos de Fornecedores"}
                                onChangeValue={(item) => {
                                    if (!item) {
                                        setNewSourceRules({});
                                    } else {
                                        setNewSourceRules({
                                            id: item.id,
                                            name: item.name,
                                        });
                                    }
                                }}
                                value={newSourceRules}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={12}>
                        {itensAllowList.includes(typeRule?.type) && (
                            <InputSelectMultiple
                                label={labelSourceRules}
                                data={dataSourceRules}
                                value={rule.values}
                                onChangeValue={(e) => setRule({ ...rule, values: e })}
                            />
                        )}
                        {itensAllowText.includes(typeRule?.type) && (
                            <InputText
                                required
                                label={typeRule.description}
                                value={rule.values}
                                onChangeValue={(e) => setRule({ ...rule, values: e.target.value })}
                            />
                        )}
                        {!typeRule?.type && (
                            <Alert severity="warning" size="small">
                                Selecione na listagem de condições para exibir as opções.
                            </Alert>
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        {itensAllowAdd.includes(typeRule?.type) && (
                            <Button
                                style={{ float: "right", marginTop: "5px", marginBottom: "5px" }}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    const rulesCustom = [...rule.values] || [];
                                    if (!rulesCustom.some((rec) => rec === newSourceRules.id)) {
                                        rulesCustom.push(newSourceRules.id);

                                        setRule({ ...rule, values: rulesCustom });

                                        const dataSourceRulesCustom = [...dataSourceRules];
                                        dataSourceRulesCustom.push({
                                            value: newSourceRules.id,
                                            text: newSourceRules.name,
                                        });

                                        setDataSourceRules(dataSourceRulesCustom);
                                        setNewSourceRules({});
                                    }
                                }}
                            >
                                Adicionar novo item
                            </Button>
                        )}
                        <Button
                            style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                setRule({ ...rule, values: [] });
                            }}
                        >
                            Limpar valores
                        </Button>
                        <Button
                            style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                const valueRulesCustom = [...valueRules];

                                if (rule.index > -1 && rule.index !== null) {
                                    valueRulesCustom[rule.index] = {
                                        name: rule.name,
                                        values: rule.values,
                                    };
                                } else {
                                    valueRulesCustom.push({
                                        name: rule.name,
                                        values: rule.values,
                                    });
                                }

                                setValueRules(valueRulesCustom);

                                setNewSourceRules({});
                                setRule(initialValues);
                            }}
                        >
                            Salvar condição
                        </Button>
                        <Button
                            style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setNewSourceRules({});
                                setRule(initialValues);
                            }}
                        >
                            Nova condição
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grade dataSource={valueRules} metaData={metaData} disablepagination={true} />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <Button
                            style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                onChangeValue(valueRules);
                                setOpen(false);
                            }}
                        >
                            Confirmar
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
