import React, { useContext, useEffect, useState } from "react";

import ApiService from "./ApiService";

import {
    MaskCNPJ_CPF,
    MaskFone,
    MaskCEP,
    ListaPaises,
    ListaEstados,
    ListaCidades,
    ModalEstabelecimentos,
    PageControl,
    TabSheet,
    InputText,
    InputDecimal,
    GradeOpcoes,
    Grade,
} from "../../components";

import { Grid, Button, Select, InputLabel, MenuItem, FormControl, FormControlLabel, Switch, makeStyles } from "@material-ui/core";
import { AppContext } from "../../App";
import { getDateTimeJson } from "../../services/date.services";
import ModalParticipantes from "../../components/modals/participantes";
import ModalPlanosContas from "../../components/modals/planosContas";
import InputDropDowList from "../../components/inputs/input.dropdowlist2";

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1, 1, 0, 0),
        float: "left",
    },
    buttonSelecao: {
        margin: theme.spacing(1, 0, 1, 1),
        float: "left",
    },
}));

const initialValueConsorcio = {
    status: "new",
    id_consorcio: null,
    consorcio_razao_nome: null,
    id_plano_conta: null,
    plano_conta_nome: null,
    percentual_contabilizacao: 100,
    centro_custo_padrao: "",
    historico_padrao: "",
    conta_lancamento_dif: "",
    dh_desativacao: null,
    leiaute: 1,
};

const layoutConsorcio = [
    {
        value: 1,
        text: "Constremac",
    },
    {
        value: 2,
        text: "Domínio Sistemas",
    },
];

export default function ManutencaoEstabelecimentos(props) {
    const { setLoading, setMessageError } = useContext(AppContext);

    const [tabIndex, setTabIndex] = useState(0);
    const [data, setData] = useState(ApiService.defaultValues);
    const [integracoes, setIntegracoes] = useState(ApiService.defaultValuesIntegracoes);
    const [consorcios, setConsorcios] = useState([]);
    const [consorcio, setConsorcio] = useState(initialValueConsorcio);

    const classes = useStyles();

    const metaDataConsorcios = [
        {
            label: "Opções",
            render: (record) => {
                const opcoes = [{ tipo: "editar", label: "Editar" }];
                if (["new", "edit"].includes(record.status)) {
                    opcoes.push({ tipo: "excluir", label: "Excluir" });
                } else {
                    if (record.dh_desativacao) {
                        opcoes.push({ tipo: "ativar", label: "Ativar" });
                    } else {
                        opcoes.push({ tipo: "desativar", label: "Desativar" });
                    }
                }

                return (
                    <span>
                        <GradeOpcoes record={record} onClickOpcoes={handleClickOpcoes} opcoes={opcoes} />
                    </span>
                );
            },
        },
        {
            label: "Situação",
            align: "center",
            render: (record) => {
                return <span>{record.dh_desativacao ? "Inativo" : "Ativo"}</span>;
            },
        },
        { label: "Consorcio", field: "consorcio_razao_nome" },
        { label: "Plano Contas", field: "plano_conta_nome" },
        { label: "%Contab.", field: "percentual_contabilizacao", format: "float", decimal: 2, align: "right" },
        { label: "Centro Custo", field: "centro_custo_padrao" },
        { label: "Histórico", field: "historico_padrao" },
        { label: "Conta Dif. Contab.", field: "conta_lancamento_dif" },
        {
            label: "Leiaute",
            render: (record) => {
                let leiaute = layoutConsorcio.find((rec) => rec.value === (record.leiaute || 1));

                if (!leiaute) {
                    leiaute = layoutConsorcio[0];
                }

                return <span>{leiaute.text}</span>;
            },
        },
    ];

    useEffect(() => {
        loadRecord(props.id);
    }, [props.id]);

    const handleClickOpcoes = async (record, tipo) => {
        const index = consorcios.findIndex((rec) => rec.id_consorcio === record.id_consorcio);

        switch (tipo) {
            case "editar":
                setConsorcio({ ...record });
                break;
            case "desativar":
                const consorciosCustom1 = [...consorcios];
                consorciosCustom1[index].dh_desativacao = getDateTimeJson();
                setConsorcios(consorciosCustom1);
                break;
            case "ativar":
                const consorciosCustom2 = [...consorcios];
                consorciosCustom2[index].dh_desativacao = null;
                setConsorcios(consorciosCustom2);
                break;
            case "excluir":
                const consorciosCustom3 = [...consorcios];
                consorciosCustom3.splice(index, 1);
                setConsorcios(consorciosCustom3);
                break;
            default:
                break;
        }
    };

    const newConsorcio = async () => {
        const consorciosCustom = [...consorcios];
        const consorcioCustom = consorciosCustom.find((rec) => rec.id_consorcio === consorcio.id_consorcio);

        if (consorcioCustom && consorcio.status === "new") {
            setMessageError({ active: true, message: "Registro já existe. Para edição selecione na grade e clique em editar" });
            return;
        }

        if (consorcio.status === "new") {
            consorciosCustom.unshift({ ...consorcio, status: "edit" });
        } else {
            consorcioCustom.id_plano_conta = consorcio.id_plano_conta;
            consorcioCustom.percentual_contabilizacao = consorcio.percentual_contabilizacao;
            consorcioCustom.centro_custo_padrao = consorcio.centro_custo_padrao;
            consorcioCustom.historico_padrao = consorcio.historico_padrao;
            consorcioCustom.conta_lancamento_dif = consorcio.conta_lancamento_dif;
            consorcioCustom.consorcio_razao_nome = consorcio.consorcio_razao_nome;
            consorcioCustom.plano_conta_nome = consorcio.plano_conta_nome;
            consorcioCustom.leiaute = consorcio.leiaute;
        }

        setConsorcios(consorciosCustom);
        setConsorcio(initialValueConsorcio);
    };

    const loadRecord = async (id) => {
        if (!id || id === 0) return;

        const response = await ApiService.GetId(id);
        if (!response.status) {
            setMessageError({ active: true, message: response.message });
            return;
        }

        setData(response.data);
        setIntegracoes(response.data.integracoes || ApiService.defaultValuesIntegracoes);
        setConsorcios(response.data.consorcios || []);

        props.onLoadRecord();
    };

    const saveRecord = async (data) => {
        const data_apply = { ...data };

        //Atributos
        data_apply.integracoes = integracoes;

        if (consorcios.length) {
            data_apply.consorcios = consorcios.map(({ status, plano_conta_nome, consorcio_razao_nome, ...rest }) => rest);
        }

        const validate = await ApiService.ValidateData(ApiService.estabelecimentoSchema, data_apply);
        if (!validate.status) {
            setMessageError({ active: true, message: validate.message });
            return;
        }

        delete data_apply.integracoes.ultimo_nsu_download;
        delete data_apply.integracoes.ultimo_nsu_download_cte;

        setLoading(true);
        try {
            let response = {};

            if (props.id) {
                response = await ApiService.Update(props.id, data_apply);
            } else {
                response = await ApiService.Append(data_apply);
            }

            if (!response.status) {
                setMessageError({ active: true, message: response.message });
                return;
            }

            onClearRecord();
            props.onFinalize(true);
        } finally {
            setLoading(false);
        }
    };

    const onGravar = () => {
        saveRecord(data);
    };

    const onClearRecord = () => {
        setData(ApiService.defaultValues);
        setIntegracoes(ApiService.defaultValuesIntegracoes);
        setConsorcios([]);

        if (props.id) {
            props.onFinalize(false);
        }
    };

    const handleChangeCNPJ_CPF = (event) => {
        const valor = event.target.value.replace(".", "").replace(".", "").replace(".", "").replace(".", "").replace("/", "").replace("-", "").trim();
        setData({ ...data, cnpj_cpf: valor });
    };

    const handleChangeFone = (event) => {
        const valor = event.target.value.replace("(", "").replace(")", "").replace("-", "").trim();
        setData({ ...data, fone: valor });
    };

    const handleChangeCEP = (event) => {
        const valor = event.target.value.replace("-", "").trim();
        setData({ ...data, cep: valor });
    };

    const handleChangePais = (event, value) => {
        const valor = value ? value.id_pais : 1058;
        setData({ ...data, id_pais: valor });
    };

    const handleChangeEstado = (event, value) => {
        const valor = value ? value.id_estado : "SC";
        setData({ ...data, cidade_uf: valor });
    };

    const handleChangeCidade = (event, value) => {
        const valor = value ? value.id_cidade : null;
        setData({ ...data, id_cidade: valor });
    };

    const handleChangeMatriz = (value) => {
        const valor = value ? value.id_estabelecimento : null;
        setData({ ...data, id_matriz: valor });
    };

    const handleChangeConsorcio = (value) => {
        const valor = value ? value.id_participante : null;
        const nome = value ? value.razao_nome : null;
        setConsorcio({ ...consorcio, id_consorcio: valor, consorcio_razao_nome: nome });
    };

    const handleChangePlanoConta = (value) => {
        const valor = value ? value.id_plano_conta : null;
        const nome = value ? value.nome : null;
        setConsorcio({ ...consorcio, id_plano_conta: valor, plano_conta_nome: nome });
    };

    const onCidadeInvalida = () => {
        setData({ ...data, id_cidade: 0 });
    };

    return (
        <>
            <PageControl tabindex={tabIndex} onchangetab={(index) => setTabIndex(index)}>
                <TabSheet label="Geral">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={2}>
                            <InputText
                                required
                                size="small"
                                label="Número"
                                disabled={!!props.id}
                                fullWidth
                                variant="outlined"
                                value={data.id_estabelecimento}
                                onChangeValue={(e) => setData({ ...data, id_estabelecimento: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputText
                                required
                                size="small"
                                label="CNPJ/CPF"
                                fullWidth
                                variant="outlined"
                                value={data.cnpj_cpf}
                                onChangeValue={(e) => handleChangeCNPJ_CPF(e)}
                                InputProps={{
                                    inputComponent: MaskCNPJ_CPF,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ModalEstabelecimentos
                                label="Matriz"
                                size="small"
                                fullWidth
                                variant="outlined"
                                valueId={data.id_matriz}
                                onChangeValue={(e) => handleChangeMatriz(e)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputText
                                required
                                size="small"
                                label="Razão/Nome"
                                fullWidth
                                variant="outlined"
                                value={data.razao_nome}
                                onChangeValue={(e) => setData({ ...data, razao_nome: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputText
                                label="Fantasia"
                                size="small"
                                fullWidth
                                variant="outlined"
                                value={data.fantasia}
                                onChangeValue={(e) => setData({ ...data, fantasia: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" required size="small">
                                <InputLabel id="demo-simple-outlined-label">Situação</InputLabel>
                                <Select
                                    defaultValue={1}
                                    labelId="demo-simple-outlined-label"
                                    id="demo-simple-outlined"
                                    value={data.tipo_contribuinte}
                                    onChange={(e) => setData({ ...data, tipo_contribuinte: e.target.value })}
                                >
                                    <MenuItem value={1}>Contribuinte</MenuItem>
                                    <MenuItem value={2}>Isento</MenuItem>
                                    <MenuItem value={9}>Não contribuinte</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputText
                                label="Inscrição Estadual"
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={data.inscricao_estadual}
                                onChangeValue={(e) => setData({ ...data, inscricao_estadual: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <ListaPaises size="small" valueId={data.id_pais} onChange={(e, value) => handleChangePais(e, value)} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <ListaEstados size="small" valueId={data.cidade_uf} onChange={(e, value) => handleChangeEstado(e, value)} />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <ListaCidades
                                size="small"
                                valueUF={data.cidade_uf}
                                valueId={data.id_cidade}
                                onChange={(e, value) => handleChangeCidade(e, value)}
                                onCidadeInvalida={() => onCidadeInvalida()}
                            />
                        </Grid>
                    </Grid>
                </TabSheet>
                <TabSheet label="Endereço/Contato">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={2}>
                            <InputText
                                size="small"
                                label="CEP"
                                fullWidth
                                variant="outlined"
                                value={data.cep}
                                onChangeValue={(e) => handleChangeCEP(e)}
                                InputProps={{
                                    inputComponent: MaskCEP,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Endereço (Rua, Avenida, Rodovia)"
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={data.endereco}
                                onChangeValue={(e) => setData({ ...data, endereco: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputText
                                label="Número"
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={data.endereco_numero}
                                onChangeValue={(e) => setData({ ...data, endereco_numero: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputText
                                label="Bairro"
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={data.bairro}
                                onChangeValue={(e) => setData({ ...data, bairro: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Complemento Endereço (Proximo, em frente, etc...)"
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={data.endereco_complemento}
                                onChangeValue={(e) => setData({ ...data, endereco_complemento: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputText
                                label="Fone"
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={data.fone}
                                onChangeValue={(e) => handleChangeFone(e)}
                                InputProps={{
                                    inputComponent: MaskFone,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputText
                                label="E-mail"
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={data.email}
                                onChangeValue={(e) => setData({ ...data, email: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                </TabSheet>
                <TabSheet label="Integrações">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Nome do Certificado Digital"
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={integracoes.certificado_nome}
                                onChangeValue={(e) => setIntegracoes({ ...integracoes, certificado_nome: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputText
                                type="password"
                                label="Senha do Certificado Digital"
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={integracoes.certificado_senha}
                                onChangeValue={(e) => setIntegracoes({ ...integracoes, certificado_senha: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                size="small"
                                control={
                                    <Switch
                                        checked={integracoes.download_nfe}
                                        onChange={(e) => setIntegracoes({ ...integracoes, download_nfe: e.target.checked })}
                                        color="primary"
                                    />
                                }
                                label="Efetua o download de XML de NF-e emitido contra o CNPJ?"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                size="small"
                                control={
                                    <Switch
                                        checked={integracoes.download_cte}
                                        onChange={(e) => setIntegracoes({ ...integracoes, download_cte: e.target.checked })}
                                        color="primary"
                                    />
                                }
                                label="Efetua o download de XML de CT-e emitido contra o CNPJ?"
                            />
                        </Grid>
                    </Grid>
                </TabSheet>
                <TabSheet label="Consórcios">
                    <Grid item xs={12}>
                        <Button
                            size="small"
                            variant="contained"
                            className={classes.buttonSelecao}
                            color="secondary"
                            onClick={() => setConsorcio(initialValueConsorcio)}
                        >
                            Novo registro
                        </Button>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <ModalParticipantes
                                label="Consorcio"
                                size="small"
                                fullWidth
                                variant="outlined"
                                valueId={consorcio.id_consorcio}
                                onChangeValue={(e) => handleChangeConsorcio(e)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ModalPlanosContas
                                label="Plano Contas"
                                size="small"
                                fullWidth
                                variant="outlined"
                                valueId={consorcio.id_plano_conta}
                                onChangeValue={(e) => handleChangePlanoConta(e)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDecimal
                                label="Contabilização"
                                symbol="%"
                                decimal={5}
                                value={consorcio.percentual_contabilizacao}
                                onChangeValue={(e) => setConsorcio({ ...consorcio, percentual_contabilizacao: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputText
                                required
                                label="Centro de Custo"
                                className={classes.textUpperCase}
                                value={consorcio.centro_custo_padrao}
                                onChangeValue={(e) => setConsorcio({ ...consorcio, centro_custo_padrao: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputText
                                required
                                label="Histórico Padrão"
                                className={classes.textUpperCase}
                                value={consorcio.historico_padrao}
                                onChangeValue={(e) => setConsorcio({ ...consorcio, historico_padrao: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputText
                                required
                                label="Conta Dif."
                                className={classes.textUpperCase}
                                value={consorcio.conta_lancamento_dif}
                                onChangeValue={(e) => setConsorcio({ ...consorcio, conta_lancamento_dif: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDropDowList
                                defaultValue={1}
                                value={consorcio.leiaute}
                                onChangeValue={(e) => setConsorcio({ ...consorcio, leiaute: e.target.value })}
                                data={layoutConsorcio}
                            ></InputDropDowList>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button size="small" variant="contained" className={classes.buttonSelecao} color="secondary" onClick={() => newConsorcio()}>
                                {consorcio.status === "new" ? "Adicionar" : "Atualizar"}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grade dataSource={consorcios} metaData={metaDataConsorcios} disablepagination={true} />
                </TabSheet>
            </PageControl>
            <Grid item xs={12}>
                <Button size="small" className={classes.button} variant="contained" color="secondary" onClick={() => onClearRecord()}>
                    {props.id ? "Cancelar" : "Limpar tela"}
                </Button>
                <Button size="small" className={classes.button} variant="contained" color="primary" onClick={() => onGravar()}>
                    Gravar
                </Button>
            </Grid>
        </>
    );
}
