export const TypeItem = [
    {
        text: "Serviços",
        value: "services",
    },
    {
        text: "Mercadorias",
        value: "goods",
    },
];

export const TypeItemFilter = [
    {
        text: "Todos",
        value: "-",
    },
    ...TypeItem,
];

export const TypeOperation = [
    {
        text: "Entrada",
        value: "in",
    },
    {
        text: "Saída",
        value: "out",
    },
];

export const TypeOperationFilter = [
    {
        text: "Todos",
        value: "-",
    },
    ...TypeOperation,
];

export const TypeOperationValues = TypeOperation.map((rec) => rec.value);
export const TypeItemValues = TypeItem.map((rec) => rec.value);

export default function OrdersTypesUtils() {
    const typeItemText = (value) => {
        return TypeItem.find((rec) => rec.value === value)?.text || null;
    };

    const typeOperationText = (value, third) => {
        if (value === "in" && third) {
            return "Entrada Terceiros";
        }

        return TypeOperation.find((rec) => rec.value === value)?.text || null;
    };

    return {
        typeItemText,
        typeOperationText,
    };
}
