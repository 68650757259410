import React, { useEffect, useContext, useState, useMemo, useCallback } from "react";

import { Grid } from "@material-ui/core";

import { Button } from "@material-ui/core";
import { Grade, InputDate, InputDecimal, InputSelector, InputText } from "../../../../components";
import QueryOperationsNature from "../../../basics/query/operationsNature";
import { AppContext } from "../../../../App";
import { AppOptionsContext } from "../../../../components/main/main";
import ApiInboundOrders from "../../../../api/suppliers/inboundOrders/ApiInboundOrders";
import { ValidateReferencesManual } from "../validates";
import FormatsUtils from "../../../../services/FormatsUtils";

const defaultData = {
    id: 0,
    total: 0,
    total_pending: 0,
    total_reference: 0,
    reference_all: true,
    stock_movement: false,
    movement_date: new Date(),
    operation_nature: {},
    justify: "",
};

export function InboundOrdersManualReferences({ id, onLoadRecord, onFinalize, showOptions }) {
    const { setLoading, showMessageError, showMessageInfo } = useContext(AppContext);
    const { setOptions } = useContext(AppOptionsContext);

    const [data, setData] = useState(defaultData);
    const [items, setItems] = useState([]);

    const metaDataItems = useMemo(() => {
        return [
            { label: "Seq", field: "item_seq" },
            { label: "Código", field: "item_id" },
            { label: "Nome do item", field: "item_name", minWidth: 175 },
            { label: "Nat.Operação", field: "operation_nature_id", align: "center" },
            { label: "Quant.", field: "amount", format: "float", decimal: 3, align: "right" },
            { label: "Preço Unit.", field: "price_unit", format: "float", decimal: 2, align: "right" },
            { label: "Total", field: "total", format: "float", decimal: 2, align: "right" },
            { label: "Qtd.Ref.", field: "amount_reference", format: "float", decimal: 3, align: "right" },
            { label: "Ref.Man.", field: "amount_reference_manual", format: "float", decimal: 3, align: "right" },
            {
                label: "Qtd.Ref.Manual",
                align: "right",
                render: (record, index) => {
                    if (!record.visible) {
                        return <>.</>;
                    }

                    return (
                        <InputDecimal
                            id={`id-${record.item_seq}`}
                            value={record.amount_reference_manual_new}
                            symbolEnds={record.unit_inventory_id}
                            decimal={3}
                            onChangeValue={(e) => {
                                const itemsCustom = [...items];
                                itemsCustom[index].amount_reference_manual_new = e.target.valueNumber;
                                setItems(itemsCustom);
                            }}
                            disabled={data.reference_all}
                        />
                    );
                },
            },
        ];
    }, [data.reference_all]);

    useEffect(() => {
        loadRecord(id);
    }, [id]);

    useEffect(() => {
        let totalPending = 0,
            totalReference = 0;

        items.forEach((rec) => {
            totalPending += rec.price_unit * (rec.amount - rec.amount_reference - (rec.amount_reference_manual_new || 0));
            totalReference += rec.price_unit * (rec.amount_reference + (rec.amount_reference_manual_new || 0));
        });

        setData({ ...data, total_pending: totalPending, total_reference: totalReference });
    }, [items]);

    useEffect(() => {
        if (data.reference_all) {
            const itemsCustom = [...items];
            itemsCustom.forEach((rec) => (rec.amount_reference_manual_new = rec.amount_reference_manual_calc));
            setItems(itemsCustom);
        }
    }, [data.reference_all]);

    const loadRecord = async (id) => {
        if (!id || id === 0) return;

        setLoading(true);
        try {
            const result = await ApiInboundOrders().apiDefault.findId(id, {
                associates: ["items", "items.item"],
            });

            if (!result.status) {
                showMessageError(result.message);
                return;
            }

            setData({ ...data, id, total: result.data.total });
            setItems([
                ...result.data.items
                    .map((rec) => {
                        return {
                            item_seq: rec.item_seq,
                            item_id: rec.item_id,
                            item_name: rec.item.name,
                            unit_inventory_id: rec.item.unit_inventory_id,
                            amount: rec.amount_delivery,
                            price_unit: rec.price_unit,
                            total: rec.total,
                            amount_reference: rec.amount_reference,
                            amount_reference_manual: rec.amount_reference_manual,
                            amount_reference_manual_new: rec.amount_delivery - rec.amount_reference,
                            amount_reference_manual_calc: rec.amount_delivery - rec.amount_reference,
                            operation_nature_id: rec.operation_nature_id,
                            purchase_number: rec.purchase_number,
                            purchase_number_item: rec.purchase_number_item,
                        };
                    })
                    .map((rec) => {
                        return {
                            ...rec,
                            visible: rec.amount_reference !== null && rec.amount_reference_manual_calc > 0,
                        };
                    }),
            ]);

            onLoadRecord();
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onClearRecord = () => {
        setItems([]);

        if (id) {
            onFinalize(false);
        }
    };

    const onSave = useCallback(
        async (id, data, items) => {
            const dataApply = {
                movement_date: FormatsUtils().dateAnsi(data.movement_date),
                operation_nature_id: data.operation_nature?.id || "",
                justify: String(data.justify).trim(),
            };

            // Validate
            if (data.stock_movement) {
                const validate = await ApiInboundOrders().apiDefault.validate(ValidateReferencesManual(), dataApply);
                if (!validate.status) {
                    showMessageError(validate.message);
                    return;
                }

                // Validate Movement Date
                const movementDate = data.movement_date;
                movementDate.setHours(0,0,0,0);
                const today = new Date();
                today.setHours(0,0,0,0);           

                if (movementDate > today) {
                    showMessageError(`Data de movimento não pode ser maior que hoje.`);
                    return;
                }
            }

            // Validate Items
            const message = [];
            if (!data.reference_all) {
                dataApply.items = [];

                items.forEach((rec) => {
                    // Send Item
                    if (rec.amount_reference_manual_new) {
                        if (rec.amount_reference_manual_new > rec.amount_reference_manual_calc) {
                            message.push(`Item ${rec.item_seq} possui quantidade informada maior que disponível.`);
                        } else {
                            dataApply.items.push({
                                item_seq: rec.item_seq,
                                amount_reference_manual: rec.amount_reference_manual_new + rec.amount_reference_manual,
                            });
                        }
                    }
                });

                if (!dataApply.items.length) {
                    message.push(`Nenhum item informando com quantidade valida para referencial manual.`);
                }
            }

            if (message.length) {
                showMessageError(message);
                return;
            }

            dataApply.stock_movement = data.stock_movement;

            setLoading(true);
            try {
                const response = await ApiInboundOrders().manualReferences(id, dataApply);

                if (!response.status) {
                    showMessageError(response.message);
                    return;
                }

                showMessageInfo("Registro atualizado com sucesso");

                onClearRecord();
                onFinalize(true);
            } finally {
                setLoading(false);
            }
        },
        [id]
    );

    useEffect(() => {
        if (showOptions) {
            setOptions(
                <>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => onClearRecord()}
                    >
                        Cancelar
                    </Button>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => onSave(id, data, items)}
                    >
                        Atualizar Referencias/Movimento
                    </Button>
                </>
            );
        }
    }, [showOptions, id, data, items]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={1}>
                    <InputText label="Registro" disabled={true} value={data.id} />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <InputDecimal label="Total" disabled={true} value={data.total} symbol="R$" decimal={2} />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <InputDecimal label="Total Referenciado" disabled={true} value={data.total_reference} symbol="R$" decimal={2} />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <InputDecimal label="Total Pendente Ref." disabled={true} value={data.total_pending} symbol="R$" decimal={2} />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <InputSelector
                        label={"Referenciar integralmente a ordem de entrada"}
                        value={data.reference_all}
                        onChangeValue={(e) => setData({ ...data, reference_all: e.target.checked })}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputSelector
                        label={"Gerar movimentação de estoque"}
                        value={data.stock_movement}
                        onChangeValue={(e) => setData({ ...data, stock_movement: e.target.checked })}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <InputDate
                        label="Data movimento"
                        disabled={!data.stock_movement}
                        value={data.movement_date}
                        onChangeValue={(date) => setData({ ...data, movement_date: date })}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <QueryOperationsNature
                        title={`Consulta Natureza de Operação`}
                        label={"Natureza Operação"}
                        disabled={!data.stock_movement}
                        orderType={data.operation_nature?.id}
                        value={data.operation_nature}
                        onChangeValue={(date) => setData({ ...data, operation_nature: date })}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <InputText
                        label="Justificativa"
                        disabled={!data.stock_movement}
                        value={data.justify}
                        onChangeValue={(e) => setData({ ...data, justify: e.target.value })}
                    />
                </Grid>
            </Grid>
            <Grade dataSource={items} metaData={metaDataItems} disablepagination={true} />
        </>
    );
}
