import React, { useEffect, useMemo, useState, useContext } from "react";

import { Grade } from "../../../../components";
import { Grid, TextField, Dialog, DialogContent, DialogTitle, IconButton } from "@material-ui/core";

import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import SearchIcon from "@material-ui/icons/Search";

import { AppContext } from "../../../../App";
import OrdersTypesUtils from "../../ordersTypes/utils";
import ApiOrdersTypes from "../../../../api/basics/ordersTypes";

export default function QueryOrdersTypes({ label, title, value, onChangeValue, ...rest }) {
    const { setLoading, setMessageError } = useContext(AppContext);

    const [openModal, setOpenModal] = useState(false);

    const [rowCount, setRowCount] = useState(0);
    const [rowPage, setRowPage] = useState(5);
    const [page, setPage] = useState(0);

    const [dataSource, setDataSource] = useState([]);
    const [filterName, setFilterName] = useState("");
    const [name, setName] = useState("");

    const metaData = useMemo(() => [
        {
            label: "Selecionar",
            render: (record) => {
                return (
                    <IconButton
                        size="small"
                        onClick={() => {
                            onChangeValue({ ...record });
                            setOpenModal(false);
                        }}
                    >
                        <ArrowDownwardIcon fontSize="inherit" />
                    </IconButton>
                );
            },
        },
        { label: "Código", field: "id", align: "right" },
        { label: "Nome", field: "name" },
        {
            label: "Tipo Operação",
            render: (record) => {
                return <span> {OrdersTypesUtils().typeOperationText(record.type_operation, record.operation_third) || "-"} </span>;
            },
        },
        {
            label: "Tipo Item",
            render: (record) => {
                return <span> {OrdersTypesUtils().typeItemText(record.type_item) || "-"} </span>;
            },
        },
        {
            label: "Tipo de Documento",
            render: (record) => {
                return <span> {record.document_type_id} </span>;
            },
        },
    ]);

    const lookup = useMemo(() => {
        if (!value?.id) {
            return "";
        }

        return `${value?.name}`;
    }, [value]);

    useEffect(() => {
        if (value?.id) {
            setName(lookup);
        } else {
            setName("");
        }
    }, [value]);

    const loadDataSource = async (newPage, newRowPage) => {
        setLoading(true);

        // Condition
        const condition = {};
        if (filterName) {
            condition["name_like"] = encodeURIComponent(filterName + "+");
        }

        try {
            const result = await ApiOrdersTypes().apiDefault.find({ page: newPage + 1, pageSize: newRowPage || rowPage, condition });

            if (result.status) {
                setDataSource(result.data);
                setRowCount(result.info.total);
            } else {
                setDataSource([]);
                if (result.message) {
                    setMessageError({ active: true, message: result.message });
                }
            }
        } catch (e) {
            setMessageError({ active: true, message: e.message });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <TextField
                fullWidth
                size="small"
                variant="outlined"
                label={label}
                value={name}
                onChange={(e) => setName(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <IconButton size="small" onClick={() => setOpenModal(true)}>
                            <SearchIcon fontSize="inherit" />
                        </IconButton>
                    ),
                }}
                onKeyDown={(event) => {
                    if (event.keyCode === 13 && name !== lookup && name) {
                        setFilterName(name);
                        setOpenModal(true);
                    }
                }}
                onBlur={() => {
                    if (name !== lookup) {
                        onChangeValue({});
                    }
                }}
                {...rest}
            />
            <Dialog fullWidth maxWidth={"md"} open={openModal} onClose={() => setOpenModal(false)}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                size="small"
                                label="Nome"
                                autoFocus
                                fullWidth
                                variant="outlined"
                                value={filterName}
                                onChange={(e) => setFilterName(e.target.value)}
                                onKeyDown={(event) => {
                                    if (event.keyCode === 13) {
                                        loadDataSource(0);
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton size="small" onClick={() => loadDataSource(0)}>
                                            <SearchIcon fontSize="inherit" />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grade
                        dataSource={dataSource}
                        metaData={metaData}
                        rowCount={rowCount}
                        rowPage={rowPage}
                        currentPage={page}
                        onChangePage={(event, newPage) => {
                            loadDataSource(newPage);
                            setPage(newPage);
                        }}
                        onChangeRowPage={(event) => {
                            loadDataSource(0, event.target.value);
                            setPage(0);
                            setRowPage(event.target.value);
                        }}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}
