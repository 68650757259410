import React, { useRef } from "react";

import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    inputFile: {
        display: "none",
    },
}));

export default function InputFile({ label, onFileSelect, multiple, fileExtension, ...rest}) {
    const classes = useStyles();

    const inputFile = useRef(null);

    const onOpenFileSelect = async(e) => {
        inputFile.current.click();
    }

    const onSelectedFiles = async(e) => {
        onFileSelect(e);
        inputFile.current.value = null;
    }

    return (
        <>
            <Button 
                size="small"
                variant="contained"
                onClick={() => onOpenFileSelect()}
                {...rest}>
                {label}
            </Button>
            <input
                className={classes.inputFile}
                multiple={multiple}
                type="file"
                accept={fileExtension}
                onChange={(e) => onSelectedFiles(e)}
                ref={inputFile}/>
        </>
    )
}
