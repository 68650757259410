import React from "react";

import { Link } from "react-router-dom";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 300,
        backgroundColor: theme.palette.background.paper,
    },
    nested1: {
        paddingLeft: theme.spacing(4),
    },
    nested2: {
        paddingLeft: theme.spacing(6),
    },
    nested3: {
        paddingLeft: theme.spacing(8),
    },
}));

export default function MenuApp({ menus, menusOpened, onOpenMenu }) {
    const classes = useStyles();

    const getNivel = (nivel) => {
        switch (nivel) {
            case 1:
                return classes.nested1;
            case 2:
                return classes.nested2;
            case 3:
                return classes.nested3;
            default:
                return null;
        }
    };

    const renderSubMenu = (menu, nivel) => {
        const menusFilter = menus.filter((rec) => rec.id_menu_pai === menu.id_menu);
        if (!menusFilter.length) {
            return null;
        }

        return (
            <>
                <ListItem key={`submenu${menu.id_menu}`} button onClick={() => onOpenMenu(menu, false)} className={getNivel(nivel)}>
                    <ListItemText>{menu.nome}</ListItemText>
                    {menusOpened["m" + menu.id_menu] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse key={`collapse${menu.id_menu}`} in={menusOpened["m" + menu.id_menu]} timeout="auto" unmountOnExit>
                    <List component="div">
                        {menusFilter.map((rec) => {
                            if (rec.acesso_site !== null) {
                                return renderMenuItem(rec, nivel + 1);
                            } else {
                                return renderSubMenu(rec, nivel + 1);
                            }
                        })}
                    </List>
                </Collapse>
            </>
        );
    };

    const renderMenuItem = (menu, nivel) => {
        return (
            <ListItem key={`menu${menu.id_menu}`} button className={getNivel(nivel)}>
                <ListItemText>
                    <Link to={menu.acesso_site} onClick={() => onOpenMenu(menu, true)}>
                        {menu.nome}
                    </Link>
                </ListItemText>
            </ListItem>
        );
    };

    const renderMenus = () => {
        return menus
            .filter((rec) => rec.id_menu_pai === null)
            .map((menu) => {
                if (menu.acesso_site !== null) {
                    return renderMenuItem(menu, 0);
                } else {
                    return renderSubMenu(menu, 0);
                }
            });
    };

    return (
        <List component="nav" className={classes.root} aria-labelledby="nested-list-subheader">
            <ListItem button>
                <ListItemText>
                    <Link to={"/"}>Favoritos</Link>
                </ListItemText>
            </ListItem>
            {renderMenus()}
        </List>
    );
}
