import React, { useMemo, useState } from "react";

import { Grade, GradeOpcoes } from "../../../../components";
import { Grid, Button } from "@material-ui/core";
import ListUtils from "../../../../services/ListUtils";

import FormatsUtils from "../../../../services/FormatsUtils";
import QueryMembers from "../../../basics/query/members";

const initialValues = {
    branch_id: 0,
    branch: {},
};

export default function CustomizationsBranchsChange({ branchs, setBranchs, types }) {
    const [branch, setBranch] = useState(initialValues);

    const metaData = useMemo(() => [
        {
            label: "Opções",
            render: (record, index) => {
                const options = [
                    { tipo: "edit", label: "Edição" },
                    { tipo: "remove", label: "Exclusão" },
                ];

                return (
                    <span>
                        <GradeOpcoes record={record} indexRecord={index} onClickOpcoes={onClickOptions} opcoes={options} />
                    </span>
                );
            },
        },
        { label: "Cód.Estab", field: "branch_id", align: "right" },
        {
            label: "Estabelecimento",
            render: (record) => {
                return <>{record.branch?.name}</>;
            },
        },
        {
            label: "Documento",
            render: (record) => {
                return <>{FormatsUtils().document(record.branch?.document, record.branch?.type_member)}</>;
            },
        },
        { label: "Dh.Criação", field: "created_at", format: "datetime", minWidth: 160 },
        { label: "Dh.Atualização", field: "updated_at", format: "datetime", minWidth: 160 },
    ]);

    const onClickOptions = async (record, type, index) => {
        switch (type) {
            case "edit":
                setBranch({ ...record, priorityAnt: record.priority });
                break;
            case "remove":
                const branchsCustom = ListUtils().sort([...branchs], "priority");
                branchsCustom.splice(index, 1);

                setBranchs(branchsCustom);
                break;
            default:
                break;
        }
    };

    return (
        <>
            <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <QueryMembers
                            title="Consulta Estabelecimentos"
                            roleMember="branch"
                            label="Estabelecimento"
                            value={branch.branch}
                            onChangeValue={(data) => setBranch({ ...branch, branch: data })}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                    <Grid item xs={12} sm={8}>
                        <Button
                            style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => setBranch(initialValues)}
                        >
                            Novo estabelecimento
                        </Button>
                        <Button
                            style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                const branchsCustomIndex = branchs.findIndex((rec) => rec.branch_id === branch.branch.id);
                                const branchsCustom = [...branchs];

                                branch.branch_id = branch.branch.id;

                                if (branchsCustomIndex === -1) {
                                    branchsCustom.push(branch);
                                } else {
                                    branchsCustom[branchsCustomIndex] = branch;
                                }

                                setBranchs(branchsCustom);
                                setBranch(initialValues);
                            }}
                        >
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grade dataSource={branchs} metaData={metaData} disablepagination={true} />
            </Grid>
        </>
    );
}
