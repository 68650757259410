import React, { useCallback, useMemo, useState } from "react";

import { Grade, GradeOpcoes, InputText } from "../../../../components";
import { Grid, Button } from "@material-ui/core";
import QueryTaxes from "../../query/taxes";
import { AttributesModal } from "../../../../components/attributes";

const initialValues = {
    tax_id: 0,
    tax: {},
    base_legal: "",
    attributes: {},
};

export default function TaxesRulesItemsChange({ items, setItems, types, attributes }) {
    const [item, setItem] = useState(initialValues);
    const [openItemAttributes, setOpenItemAttributes] = useState(false);

    const metaData = useMemo(() => [
        {
            label: "Opções",
            render: (record, index) => {
                const options = [
                    { tipo: "edit", label: "Edição" },
                    { tipo: "remove", label: "Exclusão" },
                ];

                return (
                    <span>
                        <GradeOpcoes record={record} indexRecord={index} onClickOpcoes={onClickOptions} opcoes={options} />
                    </span>
                );
            },
        },
        { label: "Cód.Tributo", field: "tax_id", align: "right" },
        {
            label: "Nome Tributo",
            render: (record) => {
                return <>{record.tax?.name || ""}</>;
            },
        },
        { label: "Base Legal", field: "base_legal" },
        { label: "Dh.Criação", field: "created_at", format: "datetime", minWidth: 160 },
        { label: "Dh.Atualização", field: "updated_at", format: "datetime", minWidth: 160 },
    ]);

    const onClickOptions = async (record, type, index) => {
        switch (type) {
            case "edit":
                setItem({ ...record, priorityAnt: record.priority });
                break;
            case "remove":
                const itemsCustom = [...items];
                itemsCustom.splice(index, 1);
                setItems(itemsCustom);
                break;
            default:
                break;
        }
    };

    const taxRulesItemsAttributes = useMemo(() => {
        const attributesCustom = attributes.filter((rec) => rec.entity === "taxes_rules_items");
        if (attributesCustom.length) {
            return attributesCustom;
        }
        return [];
    }, [attributes]);

    const attributesByTaxRefId = useCallback(
        (tax) => {
            if (!tax || !tax.id) {
                return [];
            }

            const field = `$${tax.type_tax_ref}`;
            const attr = taxRulesItemsAttributes.find((rec) => rec.field === field);

            if (attr) {
                return attr.values;
            }

            return [];
        },
        [taxRulesItemsAttributes]
    );

    return (
        <>
            <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <QueryTaxes label={"Tributo"} value={item.tax} onChangeValue={(data) => setItem({ ...item, tax: data })} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputText
                            required
                            multiline
                            maxRows={3}
                            rows={3}
                            label="Base Legal"
                            value={item.base_legal || ""}
                            onChangeValue={(e) => setItem({ ...item, base_legal: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => setItem(initialValues)}
                    >
                        Novo item
                    </Button>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => setOpenItemAttributes(true)}
                    >
                        Parâmetros
                    </Button>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            const indexTaxRule = items.findIndex((rec) => rec.tax_id === item.tax.id);
                            const itemTaxesCustom = [...items];

                            item.tax_id = item.tax.id;

                            if (indexTaxRule === -1) {
                                itemTaxesCustom.push(item);
                            } else {
                                itemTaxesCustom[indexTaxRule] = item;
                            }

                            setItems(itemTaxesCustom);
                            setItem(initialValues);
                        }}
                    >
                        Salvar
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grade dataSource={items} metaData={metaData} disablepagination={true} />
            </Grid>
            <AttributesModal
                open={openItemAttributes}
                setOpen={(open) => setOpenItemAttributes(open)}
                attributes={attributesByTaxRefId(item.tax)}
                value={item.attributes || {}}
                onChangeValue={(data) => setItem({ ...item, attributes: data })}
            />
        </>
    );
}
