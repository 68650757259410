import React from "react";

import { Grid } from "@material-ui/core";

import { InputText, ModalEstabelecimentos } from "../../../../components";

export default function ArquivosSaidas({ atributos, setAtributos }) {
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <ModalEstabelecimentos
                        label="Estabelecimento"
                        valueId={atributos?.estabelecimento?.id_estabelecimento || null}
                        onChangeValue={(data) => setAtributos({ ...atributos, estabelecimento: data })}
                    />
                </Grid>
                <Grid item xs={1}>
                    <InputText
                        label="Dia"
                        type="number"
                        min="1"
                        max="31"
                        value={atributos?.dia || 5}
                        onChangeValue={(e) => setAtributos({ ...atributos, dia: e.target.value })}
                    />
                </Grid>
            </Grid>
        </>
    );
}
