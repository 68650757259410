import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

function MaskCNPJ_CPF(props) {
    const { inputRef, ...other } = props;
    const masks = {
        cpf: [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/],
        cnpj: [/[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]
    };

    const defineMask = function(rawValue){
        let valor = rawValue
                        .replace(".", "")
                        .replace(".", "")
                        .replace(".", "")
                        .replace(".", "")
                        .replace("/", "")
                        .replace("-", "").trim();

        if (valor.length <= 11) {
            return masks.cpf;
        } else {
            return masks.cnpj;
        }
    }

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={defineMask}
            placeholderChar={'\u2000'}
            showMask={false}
        />
    );
}
  
MaskCNPJ_CPF.propTypes = {
    inputRef: PropTypes.func.isRequired
};

export default MaskCNPJ_CPF;