import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { InputText } from "../../../../components";

import { Grid, Button, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { AppContext } from "../../../../App";

import { AppOptionsContext } from "../../../../components/main/main";
import ApiDocumentsTypes from "../../../../api/basics/documentsTypes";
import { TypeNature } from "../utils";
import { AttributesModal } from "../../../../components/attributes";
import { ValidateDocumentsTypes } from "../validates";

const initialValues = {
    id: "",
    name: "",
    type_nature: "services",
    attributes: {},
};

export default function DocumentsTypesChange({ id, onLoadRecord, onFinalize, showOptions, attributes }) {
    const { setLoading, showMessageError, showMessageInfo } = useContext(AppContext);
    const { setOptions } = useContext(AppOptionsContext);

    const [documentType, setDocumentType] = useState(initialValues);
    const [openAttributes, setOpenAttributes] = useState(false);

    const documentTypeAttributes = useMemo(() => {
        const attributesCustom = (attributes || []).find((rec) => rec.entity === "documents_types" && rec.field === "attributes");

        if (attributesCustom) {
            return attributesCustom.values;
        }

        return [];
    }, [attributes]);

    useEffect(() => {
        loadRecord(id);
    }, [id]);

    const loadRecord = async (id) => {
        if (!id || id === 0) return;

        setLoading(true);

        try {
            const result = await ApiDocumentsTypes().apiDefault.findId(id, {});

            if (!result.status) {
                showMessageError(result.message);
                return;
            }

            setDocumentType(result.data);

            onLoadRecord();
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onClearRecord = () => {
        setDocumentType(initialValues);

        if (id) {
            onFinalize(false);
        }
    };

    const onSave = useCallback(
        async (documentType) => {
            const documentTypeApply = {
                id: documentType.id,
                name: documentType.name,
                type_nature: documentType.type_nature,
                attributes: documentType.attributes,
            };

            // Validate
            const listTypeNature = TypeNature.map((rec) => rec.value);
            const validate = await ApiDocumentsTypes().apiDefault.validate(ValidateDocumentsTypes(listTypeNature), documentTypeApply);
            if (!validate.status) {
                showMessageError(validate.message);
                return;
            }

            setLoading(true);
            try {
                let response = {};

                if (id) {
                    delete documentTypeApply.id;

                    response = await ApiDocumentsTypes().apiDefault.update(id, documentTypeApply);
                } else {
                    response = await ApiDocumentsTypes().apiDefault.creates(documentTypeApply);
                }

                if (!response.status) {
                    showMessageError(response.message);
                    return;
                }

                showMessageInfo("Registro atualizado com sucesso");

                onClearRecord();
                onFinalize(true);
            } finally {
                setLoading(false);
            }
        },
        [id]
    );

    useEffect(() => {
        if (showOptions) {
            setOptions(
                <>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => onClearRecord()}
                    >
                        {id ? "Cancelar" : "Limpar tela"}
                    </Button>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => onSave(documentType)}
                    >
                        Gravar
                    </Button>
                </>
            );
        }
    }, [showOptions, documentType, id]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={1}>
                    <InputText
                        required
                        label="Código"
                        value={documentType.id}
                        onChangeValue={(e) => setDocumentType({ ...documentType, id: String(e.target.value).toUpperCase().substring(0, 3) })}
                        disabled={!!id}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputText
                        required
                        label="Nome"
                        value={documentType.name}
                        onChangeValue={(e) => setDocumentType({ ...documentType, name: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormControl variant="outlined" required size="small" fullWidth>
                        <InputLabel id="demo-simple-outlined-label" shrink={true}>
                            Natureza
                        </InputLabel>
                        <Select
                            defaultValue={"sight"}
                            labelId="demo-simple-outlined-label"
                            id="demo-simple-outlined"
                            value={documentType.type_nature}
                            onChange={(e) => setDocumentType({ ...documentType, type_nature: e.target.value })}
                        >
                            {TypeNature.map((rec) => {
                                return <MenuItem value={rec.value}>{rec.text}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                <Grid item xs={12} sm={4}>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => setOpenAttributes(true)}
                    >
                        Parâmetros
                    </Button>
                </Grid>
            </Grid>
            <AttributesModal
                open={openAttributes}
                setOpen={(data) => setOpenAttributes(data)}
                attributes={documentTypeAttributes}
                value={documentType.attributes || {}}
                onChangeValue={(data) => setDocumentType({ ...documentType, attributes: data })}
            />
        </>
    );
}
