import React, { Component } from "react";

import ApiLocalidade from "./api-localidade";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
});

class ListaPaises extends Component { 
    state = {
        data: [],
        value : {}
    }  

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        const { valueId } = this.props;

        if (valueId !== prevProps.valueId && valueId > 0) {
            this.setValue(valueId);
        }
    }

    setValue = async (id) => {
        let valor = {} 
        const paises = this.state.data;
        
        for (let pais in paises) {
            if (paises[pais].id_pais === id) {
                valor = paises[pais];
                break;
            }
        }

        this.setState({
            value: valor,
        })
    }

    loadData = async () => {
        const response = await ApiLocalidade.GetPaises();

        this.setState({
            data: response.data,
        });

        await this.setValue(1058);
    }

    render() {
        const { classes, ...other } = this.props;
        const { data, value } = this.state;   

        return (
            <Autocomplete
                {...other}
                id="paises-select-demo"
                options={data}
                autoHighlight
                fullWidth
                getOptionLabel={(option) => option.nome}
                renderOption={(option) => (
                        <React.Fragment>
                            {option.nome} ({option.id_pais})
                        </React.Fragment>
                    )}
                value={value}
                renderInput={(params) => (
                <TextField
                    {...params}
                    label="País"
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                    }}
                />
                )}
            />
        )
    }
}

export default withStyles(styles)(ListaPaises);