import api from "../../services/api";

class DashboardService {
    CurrentUser = async () => {
        return await api.get("/usuarios/current")    
        .then(response => {
            return { 
                status: (response.status === 200),
                data: response.data 
            };
        })
        .catch(error => {
            return { 
                status: false, 
                message: error.response.data.message 
            };
        });
    }
}

export default DashboardService;