import React, { useContext, useEffect, useMemo, useState } from "react";

import { Grid, InputLabel } from "@material-ui/core";

import { InputText, InputDecimal, InputDate, InputSelector } from "../../components";
import InputDropDowList from "../../components/inputs/input.dropdowlist2";

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { AppContext } from "../../App";

/*
    group: string,
    name: string,
    description: string,
    type: "text" | "integer" | "decimal" | "date" | "boolean" | "enum",
    size?: number,
    precision?: number,
    values?: [{ text: string, value: string, defaultValue: boolean }]
*/

export default function Attributes({ attributes, value, onChangeValue }) {
    const onChangeFieldValue = (field, valueField) => {
        onChangeValue({ ...value, [field]: valueField });
    };

    const metaData = useMemo(() => {
        const attributesNode = [];

        attributes.forEach((rec) => {
            let attr = undefined;

            switch (rec.type) {
                case "text":
                    attr = <InputText labelId={`id-${rec.name}`} value={value[rec.name]} onChangeValue={(e) => onChangeFieldValue(rec.name, e.target.value)} />;
                    break;
                case "integer":
                    attr = (
                        <InputText
                            labelId={`id-${rec.name}`}
                            value={value[rec.name]}
                            type="number"
                            onChangeValue={(e) => onChangeFieldValue(rec.name, e.target.value)}
                        />
                    );
                    break;
                case "decimal":
                    attr = (
                        <InputDecimal
                            labelId={`id-${rec.name}`}
                            value={value[rec.name]}
                            decimal={rec.precision || 2}
                            onChangeValue={(e) => onChangeFieldValue(rec.name, e.target.value)}
                        />
                    );
                    break;
                case "date":
                    attr = (
                        <InputDate
                            labelId={`id-${rec.name}`}
                            label={rec.description}
                            value={value[rec.name]}
                            onChangeValue={(date) => onChangeFieldValue(rec.name, date)}
                        />
                    );
                    break;
                case "boolean":
                    attr = (
                        <InputSelector
                            labelId={`id-${rec.name}`}
                            value={value[rec.name]}
                            onChangeValue={(e) => onChangeFieldValue(rec.name, e.target.checked)}
                        />
                    );
                    break;
                case "enum":
                    attr = <InputDropDowList value={value[rec.name]} onChangeValue={(e) => onChangeFieldValue(rec.name, e.target.value)} data={rec.values} />;
                    break;
                default:
                    break;
            }

            attributesNode.push(
                <>
                    <Grid item xs={12} sm={8}>
                        <InputLabel style={{ paddingTop: "10px" }} id={`id-${rec.name}`}>
                            {rec.description}
                        </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {attr}
                    </Grid>
                </>
            );
        });

        return attributesNode;
    }, [attributes, value]);

    return (
        <Grid container spacing={1}>
            {metaData.map((rec) => rec)}
        </Grid>
    );
}

export function AttributesModal({ attributes, value, onChangeValue, open, setOpen }) {
    const { showMessageInfo } = useContext(AppContext);

    const [valueAttr, setValueAttr] = useState(value);

    useEffect(() => {
        setValueAttr(value);
    }, [value]);

    useEffect(() => {
        if (open) {
            setValueAttr(value);

            if (!attributes?.length) {
                showMessageInfo("Não existem dados para serem listados.");
                setOpen(false);
            }
        }
    }, [open]);

    return (
        <Dialog
            fullWidth
            maxWidth={"md"}
            open={open}
            onClose={(e, reason) => {
                if (!["backdropClick", "escapeKeyDown"].includes(reason)) {
                    setOpen(false);
                }
            }}
        >
            <DialogTitle>Atributos</DialogTitle>
            <DialogContent>
                <Attributes attributes={attributes} onChangeValue={setValueAttr} value={valueAttr} />
            </DialogContent>
            <DialogActions>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <Button
                            style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                onChangeValue(valueAttr);
                                setOpen(false);
                            }}
                        >
                            Confirmar
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
