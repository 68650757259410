class auth {
    IsAuthenticed() {
        return localStorage.getItem("token") != null;
    }

    SetToken(token) {
        localStorage.setItem("token", token);
    }

    GetToken() {
        return localStorage.getItem("token") || null;
    }

    RemoveToken() {
        localStorage.removeItem("token");
    }

    RemoveUser() {
        localStorage.removeItem("usuario");
    }

    SetUser(token) {
        localStorage.setItem("usuario", token);
    }

    GetUser() {
        return localStorage.getItem("usuario") || null;
    }
}

export default new auth();
