import React, { useCallback, useContext, useEffect, useState } from "react";

import { InputText, TabSheet, PageControl } from "../../../../components";

import { Grid, Button } from "@material-ui/core";
import { AppContext } from "../../../../App";

import { AppOptionsContext } from "../../../../components/main/main";
import TaxesRulesItemsChange from "../itemsChange";
import ApiTaxesRules from "../../../../api/fiscal/taxes/ApiTaxesRules";

const initialValues = {
    name: "",
    items: [],
};

export default function TaxesRulesChange({ id, onLoadRecord, onFinalize, showOptions, attributes, types }) {
    const { setLoading, showMessageError, showMessageInfo } = useContext(AppContext);
    const { setOptions } = useContext(AppOptionsContext);

    const [tabIndex, setTabIndex] = useState(0);

    const [taxRule, setTaxRule] = useState(initialValues);
    const [taxRulesItems, setTaxRulesItems] = useState([]);

    useEffect(() => {
        loadRecord(id);
    }, [id]);

    const loadRecord = async (id) => {
        if (!id || id === 0) return;

        setLoading(true);

        try {
            const result = await ApiTaxesRules().apiDefault.findId(id, { associates: ["items", "items.tax"] });

            if (!result.status) {
                showMessageError(result.message);
                return;
            }

            setTaxRule(result.data);
            setTaxRulesItems(result.data.items || []);

            onLoadRecord();
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onClearRecord = () => {
        setTaxRule(initialValues);
        setTaxRulesItems([]);

        if (id) {
            onFinalize(false);
        }
    };

    const onSave = useCallback(
        async (taxRule, taxRuleItems) => {
            const taxRuleApply = {
                name: taxRule.name,
            };

            taxRuleApply.items = (taxRuleItems || []).map((rec) => {
                return {
                    tax_id: rec.tax_id || rec.tax.id,
                    base_legal: rec.base_legal,
                    attributes: rec.attributes,
                };
            });

            setLoading(true);
            try {
                let response = {};

                if (id) {
                    response = await ApiTaxesRules().apiDefault.update(id, taxRuleApply);
                } else {
                    response = await ApiTaxesRules().apiDefault.creates(taxRuleApply);
                }

                if (!response.status) {
                    showMessageError(response.message);
                    return;
                }

                showMessageInfo("Registro atualizado com sucesso");

                onClearRecord();
                onFinalize(true);
            } finally {
                setLoading(false);
            }
        },
        [id]
    );

    useEffect(() => {
        if (showOptions) {
            setOptions(
                <>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => onClearRecord()}
                    >
                        {id ? "Cancelar" : "Limpar tela"}
                    </Button>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => onSave(taxRule, taxRulesItems)}
                    >
                        Gravar
                    </Button>
                </>
            );
        }
    }, [showOptions, taxRule, taxRulesItems, id]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <InputText required label="Nome" value={taxRule.name} onChangeValue={(e) => setTaxRule({ ...taxRule, name: e.target.value })} />
                </Grid>
            </Grid>
            <PageControl style={{ marginTop: "10px" }} tabindex={tabIndex} onchangetab={(index) => setTabIndex(index)}>
                <TabSheet label="Tributos">
                    <TaxesRulesItemsChange items={taxRulesItems} setItems={setTaxRulesItems} types={types} attributes={attributes} />
                </TabSheet>
            </PageControl>
        </>
    );
}
