export const TypePayment = [
    {
        text: "A vista",
        value: "sight",
    },
    {
        text: "A prazo",
        value: "installments",
    },
];

export default function PaymentMethodsUtils() {
    const typePaymentText = (value) => {
        return TypePayment.find((rec) => rec.value === value)?.text || null;
    };

    return {
        typePaymentText,
    };
}
