import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

export default function MsgConfirmation({ open, setOpen, message, onConfirmation, onCancel }) {
    return (
        <Dialog
            open={open}
            onClose={(e, reason) => {
                if (!["backdropClick", "escapeKeyDown"].includes(reason)) {
                    setOpen(false);
                }
            }}
        >
            <DialogTitle>Confirmação</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={(e) => {
                        setOpen(false);
                        onConfirmation();
                    }}
                    variant="contained"
                    size="small"
                    color="primary"
                >
                    SIM
                </Button>
                <Button
                    onClick={(e) => {
                        setOpen(false);
                        if (onCancel) {
                            onCancel();
                        }
                    }}
                    variant="contained"
                    size="small"
                    color="secondary"
                    autoFocus
                >
                    Não
                </Button>
            </DialogActions>
        </Dialog>
    );
}
