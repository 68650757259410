import apiForms from "../../services/api-forms";
import * as yup from 'yup';

class ApiService extends apiForms {
    consorcioSchema = yup.object().shape({
        id_consorcio: yup
            .number()
            .required("Consórcio é obrigatório"),
        id_plano_conta: yup
            .number()
            .required("Plano de contas é obrigatório"),
        percentual_contabilizacao: yup
            .number()
            .required("Percentual de contabilização é obrigatório")
            .moreThan(0, "Percentual de contabilização deve ser maior que zero")
            .lessThan(100.00001, "Percentual de contabilização deve ser menor ou igual a zero"),
    });

    estabelecimentoSchema = yup.object().shape({
        id_estabelecimento: yup
            .number()
            .required("Número do estabelecimento é obrigatório")
            .moreThan(0, "Número do estabelecimento é obrigatório"),
        razao_nome: yup
            .string()
            .required("Nome é obrigatório"),
        tipo_contribuinte: yup
            .number()
            .required("Tipo de contribuinte é obrigatório"),
        id_pais: yup
            .number()
            .required("País é obrigatório")
            .moreThan(0, "País é obrigatório"),
        id_cidade: yup
            .number()
            .required("Cidade é obrigatória")
            .moreThan(0, "Cidade é obrigatória"),
        consorcios: yup.array().of(this.consorcioSchema).notRequired()
    });    

    defaultValues = {
        id_estabelecimento: 0,
        id_matriz: null,
        id_participante: undefined,
        razao_nome: "",
        cnpj_cpf: "",
        fantasia: "",
        tipo_contribuinte: 1,
        inscricao_estadual: "",
        id_pais: 1058,
        id_cidade: 0,
        cidade_uf: "SC",
        cep: "",
        bairro: "",
        endereco: "",
        endereco_numero: "",
        endereco_complemento: "",
        fone: "",
        email: "",
    }

    defaultValuesIntegracoes = {
        certificado_nome: "",
        certificado_senha: "",
        download_nfe: false,
        ultimo_nsu_download: undefined,
        download_cte: false,
        ultimo_nsu_download_cte: undefined,
    }    

    constructor() {
        super("estabelecimentos", ["integracoes", "consorcios"]);
    }
}

export default new ApiService();