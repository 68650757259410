import ApiDefault from "../../ApiDefault";

export default function ToolsApi() {
    const apiDefault = ApiDefault("v2/system/tools");

    const replace = async (data) => {
        return await apiDefault.api
            .post(`/${apiDefault.url}/replace-ocurrences`, data)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    return {
        replace,
    };
}
