import React, { useCallback, useContext, useEffect, useState } from "react";

import { InputSelector, InputText } from "../../../../components";

import { Grid, Button } from "@material-ui/core";
import { AppContext } from "../../../../App";

import { AppOptionsContext } from "../../../../components/main/main";
import { ValidateSuppliersGroups } from "../validates";
import ApiSuppliersGroups from "../../../../api/basics/suppliersGroups";

const initialValues = {
    name: "",
    group_default: false,
    attributes: {},
};

export default function SuppliersGroupsChange({ id, onLoadRecord, onFinalize, showOptions }) {
    const { setLoading, showMessageError, showMessageInfo } = useContext(AppContext);
    const { setOptions } = useContext(AppOptionsContext);

    const [suppliersGroups, setSuppliersGroups] = useState(initialValues);

    useEffect(() => {
        loadRecord(id);
    }, [id]);

    const loadRecord = async (id) => {
        if (!id || id === 0) return;

        setLoading(true);

        try {
            const result = await ApiSuppliersGroups().apiDefault.findId(id, {});

            if (!result.status) {
                showMessageError(result.message);
                return;
            }

            setSuppliersGroups(result.data);

            onLoadRecord();
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onClearRecord = () => {
        setSuppliersGroups(initialValues);

        if (id) {
            onFinalize(false);
        }
    };

    const onSave = useCallback(
        async (suppliersGroups) => {
            const suppliersGroupsApply = {
                name: suppliersGroups.name,
                group_default: suppliersGroups.group_default,
                attributes: suppliersGroups.attributes,
            };

            // Validate
            const validate = await ApiSuppliersGroups().apiDefault.validate(ValidateSuppliersGroups, suppliersGroupsApply);
            if (!validate.status) {
                showMessageError(validate.message);
                return;
            }

            setLoading(true);
            try {
                let response = {};

                if (id) {
                    response = await ApiSuppliersGroups().apiDefault.update(id, suppliersGroupsApply);
                } else {
                    response = await ApiSuppliersGroups().apiDefault.creates(suppliersGroupsApply);
                }

                if (!response.status) {
                    showMessageError(response.message);
                    return;
                }

                showMessageInfo("Registro atualizado com sucesso");

                onClearRecord();
                onFinalize(true);
            } finally {
                setLoading(false);
            }
        },
        [id]
    );

    useEffect(() => {
        if (showOptions) {
            setOptions(
                <>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => onClearRecord()}
                    >
                        {id ? "Cancelar" : "Limpar tela"}
                    </Button>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => onSave(suppliersGroups)}
                    >
                        Gravar
                    </Button>
                </>
            );
        }
    }, [showOptions, suppliersGroups, id]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <InputText
                        required
                        label="Nome"
                        value={suppliersGroups.name}
                        onChangeValue={(e) => setSuppliersGroups({ ...suppliersGroups, name: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputSelector
                        label={"Grupo Padrão"}
                        value={suppliersGroups.group_default || false}
                        onChangeValue={(e) =>
                            setSuppliersGroups({
                                ...suppliersGroups,
                                group_default: e.target?.checked || false,
                            })
                        }
                    />
                </Grid>
            </Grid>
        </>
    );
}
