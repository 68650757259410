import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Grid, IconButton } from "@material-ui/core";
import InputText from "../../inputs/input.text";
import InputSelector from "../../inputs/input.selector";
import FindReplaceIcon from "@material-ui/icons/FindReplace";
import Grade from "../../grade/grade";
import ToolsApi from "../../../api/system/tools";
import { AppContext } from "../../../App";
import { AppOptionsContext } from "../../main/main";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

export default function ReplaceForm({ type, showOptions, from, to }) {
    const { setLoading, showMessageError, showMessageInfo } = useContext(AppContext);
    const { setOptions } = useContext(AppOptionsContext);

    const [replaceFrom, setReplaceFrom] = useState("");
    const [replaceTo, setReplaceTo] = useState("");
    const [remove, setRemove] = useState(true);
    const [dataSource, setDataSource] = useState([]);

    const metaData = useMemo(() => [
        {
            label: "Remover",
            render: (record, index) => {
                return (
                    <IconButton
                        size="small"
                        onClick={() => {
                            const dataSourceCustom = [...dataSource];
                            dataSourceCustom.splice(index, 1);
                            setDataSource(dataSourceCustom);
                        }}
                    >
                        <DeleteOutlineIcon />
                    </IconButton>
                );
            },
        },
        { label: "Substituir estes", field: "from" },
        { label: "Substituto", field: "to" },
        { label: "Remover", field: "remove", format: "checkbox" },
    ]);

    const onClear = async () => {
        setReplaceFrom("");
        setReplaceTo("");
        setRemove(true);
        setDataSource([]);
    };

    const onApplyReplace = async (replaces) => {
        setLoading(true);

        const data = {
            type,
            replace: replaces.map((rec) => {
                return {
                    values: rec.from.split(","),
                    to: rec.to,
                    remove: rec.remove,
                };
            }),
        };

        try {
            const result = await ToolsApi().replace(data);

            if (!result.status) {
                showMessageError(result.message);
                return;
            }

            showMessageInfo(
                <>
                    <strong>Registros Atualizados</strong>
                    {result.data.map((rec) => {
                        return (
                            <>
                                Código: {rec.to}, com {rec.quantity} registro(s) alterado(s).
                                <br />
                            </>
                        );
                    })}
                </>
            );

            onClear();
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (from) {
            if (!replaceFrom.split(",").includes(from)) {
                setReplaceFrom(replaceFrom + replaceFrom ? "," : "" + from);
            }
        }
    }, [from]);

    useEffect(() => {
        if (to) {
            setReplaceTo(to);
        }
    }, [to]);

    useEffect(() => {
        if (showOptions) {
            setOptions(
                <>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => onClear()}
                    >
                        Limpar tela
                    </Button>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        disabled={!dataSource.length}
                        onClick={() => onApplyReplace(dataSource)}
                    >
                        Aplicar Substituição
                    </Button>
                </>
            );
        }
    }, [showOptions, dataSource]);

    return (
        <Grid container spacing={1}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={5}>
                    <InputText label="Substituir este (Separa por vírgula)" value={replaceFrom} onChangeValue={(e) => setReplaceFrom(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <InputText label="Substituto" value={replaceTo} onChangeValue={(e) => setReplaceTo(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <InputSelector label={"Remover substituídos"} value={remove || false} onChangeValue={(e) => setRemove(e.target.checked)} />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                        variant="contained"
                        size="small"
                        type="primary"
                        onClick={() => {
                            const dataSourceCustom = [...dataSource];
                            dataSourceCustom.push({
                                from: replaceFrom
                                    .split(",")
                                    .map((rec) => String(rec).trim())
                                    .filter(Boolean)
                                    .join(","),
                                to: replaceTo,
                                remove,
                            });

                            setDataSource(dataSourceCustom);

                            setReplaceFrom("");
                            setReplaceTo("");
                            setRemove(true);
                        }}
                        startIcon={<FindReplaceIcon fontSize="inherit" />}
                    >
                        Adicionar
                    </Button>
                </Grid>
            </Grid>
            <Grid container>
                <Grade dataSource={dataSource} metaData={metaData} disablepagination={true} />
            </Grid>
        </Grid>
    );
}
