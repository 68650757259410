import apiForms from "../../../services/api-forms";
import api from "../../../services/api";
import fileDownload from "js-file-download";

class ApiService extends apiForms {
    constructor() {
        super("");
    }

    execute = async (tipo, files, fileName, opcoes) => {
        let url = `/ferramentas/arquivos/${tipo}`;
        
        if (opcoes && Object.keys(opcoes)) {
            url += `?${Object.keys(opcoes).map(field => `${field}=${opcoes[field]}`).join("&")}`;
        }

        return await api
            .post(url, files, {
                responseType: "blob",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                fileDownload(response.data, fileName);

                return {
                    status: response.status === 200,
                    data: [],
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };
}

export default new ApiService();
