import React, { useState, useContext, useEffect } from "react";

import ApiService from "./ApiService";
import { InputDropDowList2 as InputDropDowList, InputFile } from "../../../components";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AppContext } from "../../../App";
import ArquivosSaidas from "./tipos/ArquivosSaidas";
import ArquivosRelatorioCaixa from "./tipos/ArquivosRelatorioCaixa";
import { AppOptionsContext } from "../../../components/main/main";

const useStyles = makeStyles((theme) => ({
    buttonSearch: {
        margin: theme.spacing(1, 1, 0, 0),
        float: "right",
    },
    textSearch: {
        margin: theme.spacing(0),
    },
    loading: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    buttonVinculos: {
        margin: theme.spacing(1, 1, 0, 0),
        float: "left",
    },
    buttonExportar: {
        margin: theme.spacing(1, 1, 0, 0),
        float: "left",
    },
}));

const TIPOS = [
    {
        text: "Corrigir Duplicidade 0200",
        value: 1,
    },
    {
        text: "Corrigir Duplicidade H010",
        value: 2,
    },
    {
        text: "Gerar Arquivo de Saídas 2000",
        value: 3,
    },
    {
        text: "Relatório Caixa (6000)",
        value: 4,
    },
];

export default function EntradasSaidasRef() {
    const { setLoading, setMessageError } = useContext(AppContext);
    const { setOptions, setTitle } = useContext(AppOptionsContext);

    const [tipo, setTipo] = useState(1);
    const [atributos, setAtributos] = useState({});
    const [files, setFiles] = useState([]);
    const classes = useStyles();

    const onClearData = () => {
        setFiles([]);
        setAtributos({});
    };

    const onLoadFiles = async (e) => {
        setFiles(Array.from(e.target.files));
    };

    const onExport = async () => {
        const message = [];
        let opcoes = {};

        switch (tipo) {
            case 3:
                if (!atributos?.estabelecimento?.id_estabelecimento) {
                    message.push("Informe o estabelecimento para geração.");
                }

                if (!atributos?.dia) {
                    message.push("Informe o dia para geração (1..31).");
                }

                if (message.length === 0) {
                    opcoes = {
                        estabelecimento: atributos?.estabelecimento?.id_estabelecimento,
                        cnpj: atributos?.estabelecimento?.cnpj_cpf,
                        uf: atributos?.estabelecimento?.cidade_uf,
                        cidade: atributos?.estabelecimento?.id_cidade,
                        dia: Number(atributos?.dia),
                    };
                }
                break;
            case 4:
                if (!atributos?.estabelecimento?.id_estabelecimento) {
                    message.push("Informe o estabelecimento para geração.");
                }

                if (!atributos?.conta_debito) {
                    message.push("Informe a conta débito.");
                }

                if (!atributos?.conta_credito) {
                    message.push("Informe a conta crédito.");
                }

                if (!atributos?.historico) {
                    message.push("Informe o histórico.");
                }

                if (message.length === 0) {
                    opcoes = {
                        estabelecimento: atributos?.estabelecimento?.id_estabelecimento,
                        cnpj: atributos?.estabelecimento?.cnpj_cpf,
                        uf: atributos?.estabelecimento?.cidade_uf,
                        cidade: atributos?.estabelecimento?.id_cidade,
                        conta_debito: atributos?.conta_debito,
                        conta_credito: atributos?.conta_credito,
                        historico: atributos?.historico,
                    };
                }
                break;
            default:
                break;
        }

        if (message.length) {
            setMessageError({ active: true, message });
            return;
        }

        let fileNameExport = files[0].name;
        fileNameExport = fileNameExport.substr(0, fileNameExport.lastIndexOf(".")) + "-corrigido.xlsx";

        if (tipo === 3) {
            fileNameExport = `ArquivoSaida-${opcoes.cnpj}.txt`;
        }

        if (tipo === 4) {
            fileNameExport = `RelatorioCaixa-${opcoes.cnpj}.txt`;
        }

        setLoading(true);
        try {
            const formData = new FormData();
            files.forEach((rec) => {
                formData.append(rec.name, rec, rec.name);
            });

            const response = await ApiService.execute(tipo, formData, fileNameExport, opcoes);
            if (!response.status) {
                setMessageError({ active: true, msg: response.message });
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setTitle("Arquivos Diversos");
        return () => {
            setOptions(null);
        };
    }, []);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <InputDropDowList label="Tipo" value={tipo} onChangeValue={(e) => setTipo(Number(e.target?.value) || 1)} defaultValue={1} data={TIPOS} />
                </Grid>
            </Grid>
            {tipo === 3 && <ArquivosSaidas atributos={atributos} setAtributos={setAtributos} />}
            {tipo === 4 && <ArquivosRelatorioCaixa atributos={atributos} setAtributos={setAtributos} />}
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <InputFile
                        className={classes.buttonExportar}
                        color="primary"
                        multiple={false}
                        onFileSelect={(e) => onLoadFiles(e)}
                        fileExtension={".xlsx"}
                        label={"Importar Arquivos"}
                    />
                    <Button
                        size="small"
                        className={classes.buttonExportar}
                        variant="contained"
                        color="secundary"
                        onClick={() => onExport()}
                        disabled={!files.length}
                    >
                        Processar arquivo
                    </Button>
                    <Button
                        size="small"
                        className={classes.buttonVinculos}
                        variant="contained"
                        color="secondary"
                        onClick={() => onClearData()}
                        disabled={!files.length}
                    >
                        Limpar Dados
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}
