import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { PageControl, TabSheet, InputText, Grade, GradeOpcoes, InputDate, InputDecimal, InputDropDowList2 } from "../../../../../components";

import { Grid, Button, Select, InputLabel, MenuItem, FormControl } from "@material-ui/core";
import { AppContext } from "../../../../../App";

import FormatsUtils from "../../../../../services/FormatsUtils";
import QueryMembers from "../../../../basics/query/members";
import ApiDocuments from "../../../../../api/fiscal/documents/ApiDocumentsInbound";
import QueryItems from "../../../../basics/query/items";

import CachedIcon from "@material-ui/icons/Cached";
import { AppOptionsContext } from "../../../../../components/main/main";
import QueryPaymentMethods from "../../../../financial/query/paymentMethods";
import DocumentsTaxesChange from "../documentsTaxesChange";

const initialValues = {
    issuer_id: 0,
    issuer: {},
    receiver_id: 0,
    receiver: {},
    type_document: "",
    type_operation: "",
    number: 0,
    serial: "U",
    date_due: new Date(),
    date_in_out: null,
    total_items: 0,
    total: 0.0,
    net_weight: 0.0,
    gross_weight: 0.0,
    status: "pending",
};

const initialValuesItems = {
    item_seq: -1,
    item_id: 0,
    item: {},
    amount: 1.0,
    price_unit: 0.0,
    total: 0.0,
};

const initialValuesInvoices = {
    payment_method_id: 0,
    payment_method: {},
    installment: 1,
    total_payment: 0.0,
    date_due: new Date(),
};

export default function DocumentsChange({ documentsTypes, typeOperationChange, id, onLoadRecord, onFinalize, types, showOptions }) {
    const { setLoading, showMessageError, showMessageInfo } = useContext(AppContext);
    const { setOptions } = useContext(AppOptionsContext);

    const [tabIndex, setTabIndex] = useState(0);

    const [document, setDocument] = useState(initialValues);

    const [documentItems, setDocumentItems] = useState([]);
    const [documentItem, setDocumentItem] = useState(initialValuesItems);

    const [documentTaxes, setDocumentTaxes] = useState([]);

    const [documentInvoices, setDocumentInvoices] = useState([]);
    const [documentInvoice, setDocumentInvoice] = useState(initialValuesInvoices);

    const documentsTypesOptions = useMemo(() => {
        return documentsTypes.map((rec) => {
            return {
                value: rec.id,
                text: rec.name,
            };
        });
    }, [documentsTypes]);

    const metaDataItems = useMemo(() => [
        {
            label: "Opções",
            render: (record, index) => {
                const options = [
                    { tipo: "edit", label: "Edição" },
                    { tipo: "remove", label: "Exclusão" },
                ];

                return (
                    <span>
                        <GradeOpcoes record={record} indexRecord={index} onClickOpcoes={onClickOptionsItems} opcoes={options} />
                    </span>
                );
            },
        },
        { label: "#", field: "item_seq", align: "right" },
        { label: "Código", field: "item_id", align: "right" },
        {
            label: "Nome do item",
            render: (record) => {
                return <>{record.item?.name}</>;
            },
        },
        { label: "Quantidade", field: "amount", format: "float", decimal: 3, align: "right" },
        {
            label: "UN",
            render: (record) => {
                return (
                    <>
                        {record.item?.unit_inventory_id}-{FormatsUtils().float(record.item?.amount_unit_inventory, 0)}
                    </>
                );
            },
        },
        { label: "Preço Unitário", field: "price_unit", format: "float", decimal: 2, align: "right" },
        { label: "Total", field: "total", format: "float", decimal: 2, align: "right" },
        { label: "Dh.Criação", field: "created_at", format: "datetime", minWidth: 160 },
        { label: "Dh.Atualização", field: "updated_at", format: "datetime", minWidth: 160 },
    ]);

    const metaDataInvoices = useMemo(() => [
        {
            label: "Opções",
            render: (record, index) => {
                const options = [
                    { tipo: "edit", label: "Edição" },
                    { tipo: "remove", label: "Exclusão" },
                ];

                return (
                    <span>
                        <GradeOpcoes record={record} indexRecord={index} onClickOpcoes={onClickOptionsInvoices} opcoes={options} />
                    </span>
                );
            },
        },
        { label: "Código", field: "payment_method_id", align: "right" },
        {
            label: "Nome do metodo pagamento",
            render: (record) => {
                return <>{record.payment_method?.name}</>;
            },
        },
        { label: "Vencimento", field: "date_due", format: "date", align: "center" },
        { label: "Parcelas", field: "installment", align: "center" },
        { label: "Total", field: "total_payment", format: "float", decimal: 2, align: "right" },
        { label: "Dh.Criação", field: "created_at", format: "datetime", minWidth: 160 },
        { label: "Dh.Atualização", field: "updated_at", format: "datetime", minWidth: 160 },
    ]);

    useEffect(() => {
        loadRecord(id);
    }, [id]);

    const onClickOptionsItems = async (record, type, index) => {
        switch (type) {
            case "edit":
                setDocumentItem({ ...record });
                break;
            case "remove":
                const documentItemsCustom = [...documentItems];
                documentItemsCustom.splice(index, 1);
                setDocumentItems(documentItemsCustom);
                break;
            default:
                break;
        }
    };

    const onClickOptionsInvoices = async (record, type, index) => {
        switch (type) {
            case "edit":
                setDocumentInvoice({ ...record, date_due: new Date(record.date_due) });
                break;
            case "remove":
                const documentInvoicesCustom = [...documentInvoices];
                documentInvoicesCustom.splice(index, 1);
                setDocumentInvoices(documentInvoicesCustom);
                break;
            default:
                break;
        }
    };

    const loadRecord = async (id) => {
        if (!id || id === 0) return;

        setLoading(true);
        try {
            const result = await ApiDocuments().apiDefault.findId(id, {
                associates: ["items", "taxes", "issuer", "receiver", "items.item", "invoices", "taxes.tax", "invoices.payment_method"],
            });

            if (!result.status) {
                showMessageError(result.message);
                return;
            }

            setDocument({
                ...result.data,
                date_due: new Date(result.data.date_due),
                date_in_out: result.data.date_in_out ? new Date(result.data.date_in_out) : null,
            });
            setDocumentItems(result.data.items || []);
            setDocumentTaxes(result.data.taxes || []);
            setDocumentInvoices(result.data.invoices || []);

            onLoadRecord();
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onClearRecord = () => {
        setDocument(initialValues);
        setDocumentItems([]);
        setDocumentItem(initialValuesItems);
        setDocumentTaxes([]);
        setDocumentInvoices([]);
        setDocumentInvoice(initialValuesInvoices);

        if (id) {
            onFinalize(false);
        }
    };

    const onSave = useCallback(
        async (document, documentItems, documentTaxes, documentInvoices) => {
            const documentApply = {
                issuer_id: document.issuer.id,
                receiver_id: document.receiver.id,
                type_document: document.type_document,
                type_operation: typeOperationChange,
                number: Number(document.number),
                serial: document.serial,
                date_due: FormatsUtils().dateAnsi(document.date_due),
                date_in_out: document.date_in_out ? FormatsUtils().dateAnsi(document.date_in_out) : null,
                total_items: document.total_items,
                total: document.total,
                status: document.status,
                items: [],
                taxes: [],
            };

            // Items
            documentApply.items = (documentItems || []).map((rec) => {
                return {
                    item_seq: rec.item_seq,
                    item_id: rec.item.id,
                    amount: rec.amount,
                    price_unit: rec.price_unit,
                    total: rec.total,
                };
            });

            // Taxes
            documentApply.taxes = (documentTaxes || []).map((rec) => {
                return {
                    tax_id: rec.tax.id,
                    base: rec.base,
                    total: rec.total,
                };
            });

            // Invoices
            documentApply.invoices = (documentInvoices || []).map((rec) => {
                return {
                    payment_method_id: rec.payment_method.id,
                    installment: rec.installment || 1,
                    date_due: rec.date_due,
                    total_payment: rec.total_payment,
                };
            });

            // Validate

            setLoading(true);
            try {
                let response = {};

                if (id) {
                    response = await ApiDocuments().apiDefault.update(id, documentApply);
                } else {
                    response = await ApiDocuments().apiDefault.creates(documentApply);
                }

                if (!response.status) {
                    showMessageError(response.message);
                    return;
                }

                showMessageInfo("Registro atualizado com sucesso");

                onClearRecord();
                onFinalize(true);
            } finally {
                setLoading(false);
            }
        },
        [id]
    );

    useEffect(() => {
        if (showOptions) {
            setOptions(
                <>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => onClearRecord()}
                    >
                        {id ? "Cancelar" : "Limpar tela"}
                    </Button>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => onSave(document, documentItems, documentTaxes, documentInvoices)}
                    >
                        Gravar
                    </Button>
                </>
            );
        }
    }, [showOptions, document, documentItems, documentTaxes, documentInvoices, id]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <QueryMembers
                        disabled={!!id}
                        title="Consulta Emissor"
                        roleMember={typeOperationChange === "out" ? "branch" : "supplier"}
                        label="Emissor"
                        value={document.issuer}
                        onChangeValue={(data) => setDocument({ ...document, issuer: data })}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <QueryMembers
                        title="Consulta Destinatários"
                        roleMember={typeOperationChange === "out" ? "customer,supplier" : "branch"}
                        label="Destinatário"
                        value={document.receiver}
                        onChangeValue={(data) => setDocument({ ...document, receiver: data })}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                <Grid item xs={12} sm={4}>
                    <InputDropDowList2
                        value={document.type_document}
                        disabled={!!id}
                        onChangeValue={(e) => setDocument({ ...document, type_document: e.target.value })}
                        data={documentsTypesOptions}
                        label={"Tipo Documento"}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <InputText
                        required
                        disabled={!!id}
                        type="number"
                        label="Número"
                        value={document.number}
                        onChangeValue={(e) => setDocument({ ...document, number: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} sm={1}>
                    <InputText
                        required
                        disabled={!!id}
                        label="Série"
                        value={document.serial}
                        onChangeValue={(e) => setDocument({ ...document, serial: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <InputDate label="Data emissão" value={document.date_due} onChangeValue={(date) => setDocument({ ...document, date_due: date })} />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <InputDate label="Data prestação" value={document.date_in_out} onChangeValue={(date) => setDocument({ ...document, date_in_out: date })} />
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" required size="small" fullWidth>
                        <InputLabel id="demo-simple-outlined-label" shrink={true}>
                            Situação
                        </InputLabel>
                        <Select
                            defaultValue={"pending"}
                            labelId="demo-simple-outlined-label"
                            id="demo-simple-outlined"
                            value={document.status}
                            onChange={(e) => setDocument({ ...document, status: e.target.value })}
                        >
                            <MenuItem value={"pending"}>Pendentes</MenuItem>
                            <MenuItem value={"active"}>Ativos</MenuItem>
                            <MenuItem value={"cancelled"}>Cancelados</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <InputDecimal
                        label={"Valor Itens"}
                        value={document.total_items}
                        symbol="R$"
                        decimal={2}
                        onChangeValue={(e) => setDocument({ ...document, total_items: e.target.valueNumber })}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <InputDecimal
                        label={"Valor Líquido"}
                        value={document.total}
                        symbol="R$"
                        decimal={2}
                        onChangeValue={(e) => setDocument({ ...document, total: e.target.valueNumber })}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                        variant="contained"
                        size="small"
                        type="primary"
                        onClick={() => {
                            const totalItems = documentItems.reduce((total, item) => {
                                return total + item.total;
                            }, 0);

                            const totalTaxesHold = documentTaxes.reduce((total, tax) => {
                                if (tax.tax.type_calculate === "hold") {
                                    return total + tax.total;
                                }

                                return total;
                            }, 0);

                            const totalTaxesOutside = documentTaxes.reduce((total, tax) => {
                                if (tax.tax.type_calculate === "outside") {
                                    return total + tax.total;
                                }

                                return total;
                            }, 0);

                            setDocument({ ...document, total: totalItems - totalTaxesHold + totalTaxesOutside, total_items: totalItems });
                        }}
                        startIcon={<CachedIcon fontSize="inherit" />}
                    >
                        Recalcular totais
                    </Button>
                </Grid>
            </Grid>
            <PageControl style={{ marginTop: "10px" }} tabindex={tabIndex} onchangetab={(index) => setTabIndex(index)}>
                <TabSheet label="Itens">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <QueryItems
                                title={`Consulta de Mercadorias`}
                                typeItem="goods"
                                label={"Item"}
                                value={documentItem.item}
                                onChangeValue={(data) => setDocumentItem({ ...documentItem, item: data })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                        <Grid item xs={12} sm={2}>
                            <InputDecimal
                                label={"Quantidade"}
                                value={documentItem.amount}
                                symbol={documentItem?.item?.id ? `${documentItem?.item.unit_inventory_id}${documentItem?.item.amount_unit_inventory}` : "UN"}
                                decimal={3}
                                onChangeValue={(e) =>
                                    setDocumentItem({ ...documentItem, amount: e.target.valueNumber, total: e.target.valueNumber * documentItem.price_unit })
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDecimal
                                label={"Valor unitário"}
                                value={documentItem.price_unit}
                                symbol="R$"
                                decimal={2}
                                onChangeValue={(e) =>
                                    setDocumentItem({ ...documentItem, price_unit: e.target.valueNumber, total: e.target.valueNumber * documentItem.amount })
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDecimal label={"Total do item"} value={documentItem.total} symbol="R$" decimal={2} disabled={true} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                        <Grid item xs={12} sm={12}>
                            <Button
                                style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={() => setDocumentItem(initialValuesItems)}
                            >
                                Novo item
                            </Button>
                            <Button
                                style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    // Search list suppliers
                                    const indexDocumentoItem = documentItems.findIndex((rec) => rec.item_seq === documentItem.item_seq);
                                    const documentItemsCustom = [...documentItems];

                                    if (indexDocumentoItem === -1) {
                                        documentItem.item_seq = (documentItems.length || 0) + 1;
                                        documentItem.item_id = documentItem.item.id;
                                        documentItemsCustom.push(documentItem);
                                    } else {
                                        documentItemsCustom[indexDocumentoItem] = documentItem;
                                    }

                                    setDocumentItems(documentItemsCustom);
                                    setDocumentItem(initialValuesItems);
                                }}
                            >
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grade dataSource={documentItems} metaData={metaDataItems} disablepagination={true} />
                    </Grid>
                </TabSheet>
                <TabSheet label={"Tributos"}>
                    <DocumentsTaxesChange documentTaxes={documentTaxes} setDocumentTaxes={setDocumentTaxes} />
                </TabSheet>
                <TabSheet label="Faturas">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <QueryPaymentMethods
                                label={"Metodo de Pagamento"}
                                value={documentInvoice.payment_method}
                                onChangeValue={(data) => setDocumentInvoice({ ...documentInvoice, payment_method: data })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                        <Grid item xs={12} sm={2}>
                            <InputText
                                label={"Parcelas"}
                                value={documentInvoice.installment}
                                onChangeValue={(e) => setDocumentInvoice({ ...documentInvoice, installment: e.target.value })}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDate
                                label="Data vencimento"
                                value={documentInvoice.date_due}
                                onChangeValue={(date) => setDocumentInvoice({ ...documentInvoice, date_due: date })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDecimal
                                label={"Valor Pagamento"}
                                value={documentInvoice.total_payment}
                                symbol="R$"
                                decimal={2}
                                onChangeValue={(e) => setDocumentInvoice({ ...documentInvoice, total_payment: e.target.valueNumber })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                        <Grid item xs={12} sm={12}>
                            <Button
                                style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={() => setDocumentInvoice(initialValuesInvoices)}
                            >
                                Novo item
                            </Button>
                            <Button
                                style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    // Search list suppliers
                                    const index = documentInvoices.findIndex((rec) => rec.payment_method_id === documentInvoice.payment_method.id);
                                    const documentInvoicesCustom = [...documentInvoices];

                                    // Fix fields
                                    documentInvoice.payment_method_id = documentInvoice.payment_method.id;
                                    documentInvoice.installment = Number(documentInvoice.installment) || 1;
                                    documentInvoice.date_due = FormatsUtils().dateAnsi(documentInvoice.date_due) + " 00:00:00";

                                    if (index === -1) {
                                        documentInvoicesCustom.push(documentInvoice);
                                    } else {
                                        documentInvoicesCustom[index] = documentInvoice;
                                    }

                                    setDocumentInvoices(documentInvoicesCustom);
                                    setDocumentInvoice(initialValuesInvoices);
                                }}
                            >
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grade dataSource={documentInvoices} metaData={metaDataInvoices} disablepagination={true} />
                    </Grid>
                </TabSheet>
            </PageControl>
        </>
    );
}
