const getInicioMes = (data) => {
    let dataLocal = data;

    if (!dataLocal) {
        dataLocal = new Date();
        dataLocal.setHours(0);
        dataLocal.setMinutes(0);
        dataLocal.setSeconds(0);
    }

    return new Date(dataLocal.getUTCFullYear(), dataLocal.getUTCMonth(), 1, 0, 0, 0);
};

const getFinalMes = (data) => {
    let dataLocal = data;

    if (!dataLocal) {
        dataLocal = new Date();
        dataLocal.setHours(0);
        dataLocal.setMinutes(0);
        dataLocal.setSeconds(0);
    }

    if (dataLocal.getUTCMonth() === 11) {
        dataLocal.setUTCFullYear(dataLocal.getUTCFullYear() + 1);
        dataLocal.setUTCMonth(0);
    } else {
        dataLocal.setUTCMonth(dataLocal.getUTCMonth() + 1);
    }

    dataLocal.setDate(getInicioMes(dataLocal).getDate() - 1);

    return dataLocal;
};

const getDecMes = (data) => {
    let dataLocal = data;

    if (!dataLocal) {
        dataLocal = new Date();
        dataLocal.setHours(0);
        dataLocal.setMinutes(0);
        dataLocal.setSeconds(0);
    }

    if (dataLocal.getUTCMonth() === 0) {
        dataLocal.setUTCFullYear(dataLocal.getUTCFullYear() - 1);
        dataLocal.setUTCMonth(11);
    } else {
        dataLocal.setUTCMonth(dataLocal.getUTCMonth() - 1);
    }

    return dataLocal;
};

const getIncMes = (data) => {
    let dataLocal = data;

    if (!dataLocal) {
        dataLocal = new Date();
        dataLocal.setHours(0);
        dataLocal.setMinutes(0);
        dataLocal.setSeconds(0);
    }

    if (dataLocal.getUTCMonth() === 11) {
        dataLocal.setUTCFullYear(dataLocal.getUTCFullYear() + 1);
        dataLocal.setUTCMonth(0);
    } else {
        dataLocal.setUTCMonth(dataLocal.getUTCMonth() + 1);
    }

    return dataLocal;
};

const getDateJson = (data) => {
    let dataLocal = data;

    if (!dataLocal) {
        dataLocal = new Date();
        dataLocal.setHours(0);
        dataLocal.setMinutes(0);
        dataLocal.setSeconds(0);
    }

    return (
        String(dataLocal.getUTCFullYear()).padStart(4, "0") +
        "-" +
        String(dataLocal.getUTCMonth() + 1).padStart(2, "0") +
        "-" +
        String(dataLocal.getUTCDate()).padStart(2, "0")
    );
};

const getTimeJson = (data = new Date()) => {
    return String(data.getHours()).padStart(2, "0") + ":" + String(data.getMinutes()).padStart(2, "0") + ":" + String(data.getSeconds()).padStart(2, "0");
};

const getDateTimeJson = (data = new Date()) => {
    return getDateJson(data) + " " + getTimeJson(data);
};

export { getInicioMes, getFinalMes, getDateJson, getDateTimeJson, getTimeJson, getDecMes, getIncMes };
