import React, { useContext, useEffect, useState } from "react";
import dashboardService from "./dashboard.service";
import { AppContext } from "../../App";
import { AppOptionsContext } from "../../components/main/main";

export default function Dashboard() {
    const { showMessageError } = useContext(AppContext);
    const { setOptions, setTitle } = useContext(AppOptionsContext);

    const [user, setUser] = useState({});

    useEffect(() => {
        setTitle("Favoritos");

        const loadDashBoard = async () => {
            const service = new dashboardService();
            const response = await service.CurrentUser();

            if (!response.status) {
                showMessageError(response.message);
                return;
            }

            setUser(response.data);
        };

        loadDashBoard();
        setOptions(null);
    }, []);

    return (
        <>
            <h3>Bem-vindo(a) {user.nome}.</h3>
        </>
    );
}
