import React, { useState, useEffect, useRef, useContext } from 'react';

import ApiService from "../../pages/planosContas/ApiService";

import { Grade, InputText } from "../";

import { Grid, Button, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AppContext } from '../../App';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const useStyles = makeStyles((theme) => ({
    buttonSearch: {
        margin: theme.spacing(1, 0, 0, 0),
        float: "right"
    },
    textSearch: {
        margin: theme.spacing(0),
    },
    loading: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const initialFilter = {
    nome: ""
}

export default function ModalPlanosContas({label, valueId, onChangeValue, ...other}) {
    const { setLoading, setMessageError } = useContext(AppContext);

    const [ dataSource, setDataSource ] = useState([]);
    const [ nome, setNome] = useState("");
    const [ nomeAnterior, setNomeAnterior] = useState("");
    const [ rowsCount, setRowsCount] = useState(0);
    const [ condition, setCondition] = useState({ 
        rowsPage: 5, 
        currentPage: 0,
        filter: initialFilter,
        orderBy: "nome,dh_alt-desc"
    });
    const [ filter, setFilter] = useState(initialFilter);
    const [ openModal, setOpenModal ] = useState(false);

    const valueIdPrev = useRef(valueId);
    
    const classes = useStyles();

    const metaData = [
        { label: "Opções", 
            render: (record) => {
                return (
                    <IconButton size="small" onClick={() => onSelectRecord(record)}>
                        <ArrowDownwardIcon fontSize="inherit" />
                    </IconButton>
                )
            }
        },
        {label: "Nome", field: "nome"},
    ];

    useEffect(() => {
        if (valueIdPrev.current !== valueId)
            onLoadRecord(valueId);
    }, [valueId]);

    useEffect(() => {
        onLoadData();
    }, [condition]);

    const onLoadRecord = async (id) => {
        valueIdPrev.current = id;

        if (id) {
            const response = await ApiService.GetId(id);

            onChangeValue(response.data);

            setNome(response.data.nome);
            setNomeAnterior(response.data.nome);
        } else {
            onChangeValue(null);
            setNome(""); 
            setNomeAnterior("");
        }
    }

    const onLoadData = async () => {
        if (!openModal) {
            setDataSource([]);
            return;
        }

        setLoading(true);

        const where = {};

        if (condition.filter) {
            if ((condition.filter.nome) && (condition.filter.nome !== "")) {
                where.nome = condition.filter.nome;
            }
        }       

        try {
            const response = await ApiService.GetAll(condition.currentPage + 1, where, condition.rowsPage, condition.orderBy);
            if (!response.status) {
                setMessageError({ active: true, msg: response.message});
                return;
            }

            setDataSource(response.data.rows);
            setRowsCount(response.data.infoRows.count);
        } finally {
            setLoading(false);
        }
    }

    const onSelectRecord = async (record) => {
        valueIdPrev.current = (record?.id_plano_conta || null);

        onChangeValue(record);

        setNome(record.nome);
        setNomeAnterior(record.nome);

        setOpenModal(false);
        setDataSource([]);
    }

    const handleChangeNome = (event) => {
        setNome(event.target.value);

        if (event.target.value === "") {
            onChangeValue(null);
        }
    };

    const handleLoseFocus = (event) => {
        if (nome !== nomeAnterior && valueId !== 0) {
            onChangeValue(null);

            setNome("");
            setNomeAnterior("");
        }
    };

    return (
        <>
            <InputText
                {...other}
                label={label}
                value={nome}
                onChangeValue={(e) => handleChangeNome(e)}
                onBlur={(e) => handleLoseFocus(e)}
                InputProps={{
                    endAdornment: (
                        <Button 
                            size="small"
                            variant="contained"
                            className={classes.buttonRight}
                            onClick={() => setOpenModal(true)}>
                            Buscar
                        </Button>
                    ),
                }}
            />                
            <Dialog
                fullWidth
                maxWidth={"md"}
                open={openModal}
                onClose={() => setOpenModal(false)}>
                <DialogTitle>Consulta Participantes</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={10}>
                            <InputText
                                className={classes.textSearch}
                                label="Pesquisa"
                                autoFocus
                                value={filter.nome}                            
                                onChangeValue={e => setFilter({ ...filter, nome: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button 
                                size="small"
                                variant="contained"
                                className={classes.buttonSearch}
                                onClick={() => setCondition({ ...condition, filter, currentPage: 0})}>Pesquisar
                            </Button>
                        </Grid>
                    </Grid>
                    <Grade 
                        dataSource={dataSource} 
                        metaData={metaData}
                        rowCount={rowsCount}
                        rowPage={condition.rowsPage}
                        currentPage={condition.currentPage}
                        onChangePage={(e, page) => setCondition({ ...condition, currentPage: page})}
                        onChangeRowPage={(e) => setCondition({ ...condition, rowsPage: e.target.value})}/>
                </DialogContent>
            </Dialog>
        </>
    )
}