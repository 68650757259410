import React, { useEffect, useMemo, useState } from "react";

import { Grade, GradeOpcoes, InputDecimal } from "../../../../../components";

import { Grid, Button } from "@material-ui/core";

import QueryTaxes from "../../../query/taxes";
import FormatsUtils from "../../../../../services/FormatsUtils";

const initialValuesTaxes = {
    tax_id: 0,
    tax: {},
    base: 0.0,
    total: 0.0,
};

export default function DocumentsTaxesChange({ documentTaxes, setDocumentTaxes }) {
    const [documentTax, setDocumentTax] = useState(initialValuesTaxes);

    const metaDataTaxes = useMemo(() => [
        {
            label: "Opções",
            render: (record, index) => {
                const options = [
                    { tipo: "edit", label: "Edição" },
                    { tipo: "remove", label: "Exclusão" },
                ];

                return (
                    <span>
                        <GradeOpcoes record={record} indexRecord={index} onClickOpcoes={onClickOptionsTaxes} opcoes={options} />
                    </span>
                );
            },
        },
        { label: "Código", field: "tax_id", align: "right" },
        {
            label: "Nome do tributo",
            render: (record) => {
                return <>{record.tax?.name}</>;
            },
        },
        { label: "Base de Cálculo", field: "base", format: "float", decimal: 2, align: "right" },
        {
            label: "Alíquota",
            align: "right",
            render: (record) => {
                return <>{FormatsUtils().float(record.tax?.tax_fees, 2)}%</>;
            },
        },
        { label: "Total", field: "total", format: "float", decimal: 2, align: "right" },
        { label: "Dh.Criação", field: "created_at", format: "datetime", minWidth: 160 },
        { label: "Dh.Atualização", field: "updated_at", format: "datetime", minWidth: 160 },
    ]);

    const onClickOptionsTaxes = async (record, type, index) => {
        switch (type) {
            case "edit":
                setDocumentTax({ ...record });
                break;
            case "remove":
                const documentTaxesCustom = [...documentTaxes];
                documentTaxesCustom.splice(index, 1);
                setDocumentTaxes(documentTaxesCustom);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (documentTax.tax?.tax_fees) {
            setDocumentTax({ ...documentTax, total: (documentTax.tax?.tax_fees / 100) * documentTax.base });
        }
    }, [documentTax.base]);

    return (
        <>
            <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <QueryTaxes label={"Tributo"} value={documentTax.tax} onChangeValue={(data) => setDocumentTax({ ...documentTax, tax: data })} />
                    </Grid>
                </Grid>
                <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                    <Grid item xs={12} sm={2}>
                        <InputDecimal
                            label={"Base de cálculo"}
                            value={documentTax.base}
                            decimal={2}
                            onChangeValue={(e) => setDocumentTax({ ...documentTax, base: e.target.valueNumber })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <InputDecimal label={"Alíquota"} value={documentTax.tax?.tax_fees || 0} decimal={2} disabled />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <InputDecimal
                            label={"Total tributo"}
                            value={documentTax.total}
                            decimal={2}
                            disabled={(documentTax.tax?.tax_fees || 0) > 0}
                            onChangeValue={(e) => setDocumentTax({ ...documentTax, total: e.target.valueNumber })}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => setDocumentTax(initialValuesTaxes)}
                    >
                        Novo tributo
                    </Button>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            // Search list suppliers
                            const indexDocumentoTax = documentTaxes.findIndex((rec) => rec.tax_id === documentTax.tax.id);
                            const documentTaxesCustom = [...documentTaxes];

                            documentTax.tax_id = documentTax.tax.id;

                            if (indexDocumentoTax === -1) {
                                documentTaxesCustom.push(documentTax);
                            } else {
                                documentTaxesCustom[indexDocumentoTax] = documentTax;
                            }

                            setDocumentTaxes(documentTaxesCustom);
                            setDocumentTax(initialValuesTaxes);
                        }}
                    >
                        Salvar
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grade dataSource={documentTaxes} metaData={metaDataTaxes} disablepagination={true} />
            </Grid>
        </>
    );
}
