import * as yup from "yup";
import { TypeNatureValues } from "../utils";

export function ValidateMembers(TypeMembersValues) {
    return yup.object().shape({
        name: yup.string().required("Nome é obrigatório").min(3, "Nome deve ter mais que três caracteres"),
        type_nature: yup.string().required("Natureza é obrigatória").oneOf(TypeNatureValues, "Natureza inválida"),    
        type_member: yup.string().required("Tipo de membro é obrigatório").oneOf(TypeMembersValues, "Tipo de membro é inválido"),
        document: yup.string().required("Documento é obrigatório"),
        city_id: yup.number().required("Cidade é obrigatória").moreThan(0, "Cidade inválida."),
    });
}

export function ValidateMembersSuppliers() {
    return yup.object().shape({
        supplier_group_id: yup.number().required("Grupo de fornecedor é obrigatório").moreThan(0, "Grupo de fornecedor inválido."),
    });
}
