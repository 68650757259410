import React, { useState, useEffect, useContext, useMemo } from "react";

/* CONTEXTS */
import { AppContext } from "../../../App";
import { AppOptionsContext } from "../../../components/main/main";

/* UTILS */
import { getInicioMes, getFinalMes, getDecMes } from "../../../services/date.services";
import QueryMembers from "../../basics/query/members";
import FormatsUtils from "../../../services/FormatsUtils";

/* COMPONENTS */
import { Grade, PageControl, TabSheet, GradeOpcoes, InputDate, InputDropDowList, InputDropDowList2, InputText } from "../../../components";
import { Button, MenuItem, Grid, Tooltip } from "@material-ui/core";
import TablePagination from "../../../components/grade/TablePagination";
import ApiOrdersTypes from "../../../api/basics/ordersTypes";
import InboundOrdersDetails from "./details";
import ApiInboundOrders from "../../../api/suppliers/inboundOrders/ApiInboundOrders";
import InboundOrdersReceipt from "./receipt";
import ButtonMenu from "../../../components/buttons/buttonMenu";

/* ICONS */
import SearchIcon from "@material-ui/icons/Search";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import ListAltIcon from "@material-ui/icons/ListAlt";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import { InboundOrdersManualReferences } from "./manualReferences";

const initialCondition = {
    id_in: "",
    number_in: "",
    branch: {},
    supplier: {},
    order_type: "-",
    status: "active",
    status_reference: "-",
    issue_date_less_equal: getFinalMes(getDecMes()),
    issue_date_more_equal: getInicioMes(getDecMes()),
};

const menuReports = [
    {
        label: "Pendentes de Referência",
        type: "pending-reference",
    },
];

export default function InboundOrders() {
    const { setLoading, showMessageError, showMessageInfo } = useContext(AppContext);
    const { setOptions, setTitle } = useContext(AppOptionsContext);

    const [idDetails, setIdDetails] = useState(null);
    const [openDetails, setOpenDetails] = useState(false);

    const [idManualReferences, setIdManualReferences] = useState(null);

    const [tabIndex, setTabIndex] = useState(0);
    const [dataSource, setDataSource] = useState([]);

    const [rowCount, setRowCount] = useState(0);
    const [rowPage, setRowPage] = useState(10);
    const [page, setPage] = useState(0);

    const [condition, setCondition] = useState(initialCondition);
    const [conditionApply, setConditionApply] = useState(initialCondition);

    const [ordersTypes, setOrdersTypes] = useState([]);

    const metaData = useMemo(() => [
        {
            label: "Opções",
            render: (record) => {
                const options = [{ tipo: "details", label: "Detalhes" }];

                if (record.status_reference !== "referenced") {
                    options.push({ tipo: "manual-ref", label: "Referência Manual" });
                }

                return (
                    <span>
                        <GradeOpcoes record={record} onClickOpcoes={onClickOptions} opcoes={options} />
                    </span>
                );
            },
        },
        {
            label: "Info.",
            render: (record) => {
                const listInfo = [];

                if (record.member_stock_id) {
                    const title = (
                        <>
                            Membro Estoque: {record.member_stock_name}
                            <br />
                            Documento: {FormatsUtils().document(record.member_stock_document, record.member_stock_type)}
                        </>
                    );

                    listInfo.push(
                        <Tooltip title={title}>
                            <GroupAddIcon />
                        </Tooltip>
                    );
                }

                return (
                    <>
                        {listInfo.map((rec) => {
                            return <span>{rec}</span>;
                        })}
                    </>
                );
            },
        },
        { label: "Reg.", field: "id", align: "right" },
        {
            label: "Fornecedor",
            render: (record) => {
                return (
                    <>
                        {record.supplier_name}
                        <br />
                        Documento: {FormatsUtils().document(record.supplier_document, record.supplier_type)}
                    </>
                );
            },
        },
        { label: "Data Ordem", field: "issue_date", format: "date" },
        { label: "Remessas", field: "list_deliveries_id" },
        { label: "Documentos Fiscais", field: "list_documents_number" },
        {
            label: "Valor Ordem",
            field: "total",
            format: "float",
            decimal: 2,
            align: "right",
        },
        {
            label: "Situação",
            render: (record) => {
                let situacao = "Pendente";
                switch (record.status) {
                    case "active":
                        situacao = "Ativa";
                        break;
                    case "cancelled":
                        situacao = "Cancelada";
                        break;
                    case "reversed":
                        situacao = "Estornada";
                        break;
                    default:
                        break;
                }

                return <span> {situacao} </span>;
            },
        },
        {
            label: "Situação Referencia",
            render: (record) => {
                let situacao = "Pendente";
                switch (record.status_reference) {
                    case "partial":
                        situacao = "Parcial";
                        break;
                    case "referenced":
                        situacao = "Referenciado";
                        break;
                    default:
                        break;
                }

                return <span> {situacao} </span>;
            },
        },
        {
            label: "Estabelecimento",
            render: (record) => {
                return (
                    <>
                        {record.branch_name}
                        <br />
                        Documento: {FormatsUtils().document(record.branch_document, record.branch_type)}
                    </>
                );
            },
        },
    ]);

    useEffect(() => {
        setTitle("Ordens de Entrada");
        loadDataSource(0);

        // Types Documents
        ApiOrdersTypes()
            .apiDefault.findAll({ type_operation: "in" })
            .then((rec) => {
                setOrdersTypes(rec.data);
            });

        return () => {
            setOptions(null);
        };
    }, []);

    const ordersTypesFilter = useMemo(() => {
        const result = ordersTypes.map((rec) => {
            return {
                value: rec.id,
                text: rec.name,
            };
        });

        result.unshift({
            text: "Todos",
            value: "-",
        });

        return result;
    }, [ordersTypes]);

    const loadDataSource = async (newPage, newRowPage, newCondition) => {
        setLoading(true);

        // Condition
        const conditionCustom = makeCondition(newCondition || conditionApply);

        try {
            const result = await ApiInboundOrders().list({ page: newPage + 1, pageSize: newRowPage || rowPage, condition: conditionCustom });

            if (result.status) {
                setDataSource(result.data);
                setRowCount(result.info.total);
            } else {
                setDataSource([]);
                setRowCount(0);
                if (result.message) {
                    showMessageError(result.message);
                }
            }
        } catch (e) {
            showMessageError({ active: true, message: e.message });
        } finally {
            setLoading(false);
        }
    };

    const makeCondition = (newCondition, type = null) => {
        const result = {};

        // Id
        if (newCondition.id_in) {
            result.id_in = newCondition.id_in
                .split(",")
                .map((rec) => Number(rec))
                .filter(Boolean)
                .join(",");
        } else {
            // Number Document
            if (newCondition.number_in) {
                result.number_in = newCondition.number_in
                    .split(",")
                    .map((rec) => Number(rec))
                    .filter(Boolean)
                    .join(",");
            } else {
                // Supplier
                if (newCondition.supplier?.id) {
                    result.supplier_id = newCondition.supplier?.id;
                }

                // Branch
                if (newCondition.branch?.id) {
                    result.branch_id = newCondition.branch?.id;
                }

                // Date Due
                if (newCondition.issue_date_less_equal) {
                    result.issue_date_less_equal = FormatsUtils().dateAnsi(newCondition.issue_date_less_equal);
                }
                if (newCondition.issue_date_more_equal) {
                    result.issue_date_more_equal = FormatsUtils().dateAnsi(newCondition.issue_date_more_equal);
                }

                // Status
                if (newCondition.status !== "-") {
                    result.status = newCondition.status;
                }

                // Status
                if (newCondition.status_reference !== "-") {
                    if (newCondition.status_reference === "pending") {
                        result.status_reference_in = "partial,pending";
                    } else {
                        result.status_reference = newCondition.status_reference;
                    }
                }

                // Type Document
                if (newCondition.order_type !== "-") {
                    result.order_type_id = Number(newCondition.order_type);
                }

                // Custom Type
                if (type === "pending-reference") {
                    result.status_reference_in = "partial,pending";
                    result.status = "active";
                    delete result.status_reference;
                }
            }
        }

        return result;
    };

    const onClickOptions = (record, tipo) => {
        switch (tipo) {
            case "details":
                setIdDetails(record.id);
                setOpenDetails(true);
                break;
            case "manual-ref":
                if (idManualReferences) {
                    showMessageError("Já existe um registro em edição de referência manual.");
                } else {
                    setIdManualReferences(record.id);
                    setTabIndex(3);
                }
                break;
            default:
                break;
        }
    };

    const onReport = async (type) => {
        const conditionReport = makeCondition(conditionApply, type);
        const message = [];

        if (type === "pending-reference" && !conditionReport.branch_id) {
            message.push(`Informe o estabelecimento.`);
        }

        if (message.length) {
            showMessageError(message);
            return;
        }

        setLoading(true);

        try {
            const fileName = `Ordens-Pendentes-Devolucao-${conditionApply.branch.document}-${FormatsUtils().dateAnsi(
                conditionApply.issue_date_less_equal
            )}${FormatsUtils().dateAnsi(conditionApply.issue_date_less_equal)}`;

            const response = await ApiInboundOrders().report({ layout: type, format: "xlsx", fileName, condition: conditionReport });
            if (!response.status) {
                showMessageError(response.message);
                return;
            }
        } catch (e) {
            showMessageError({ active: true, message: e.message });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (tabIndex === 0) {
            setOptions(
                <>
                    <TablePagination
                        rowCount={rowCount}
                        rowPage={rowPage}
                        page={page}
                        onPageChange={(event, newPage) => {
                            loadDataSource(newPage);
                            setPage(newPage);
                        }}
                        onRowsPerPageChange={(event) => {
                            loadDataSource(0, event.target.value);
                            setPage(0);
                            setRowPage(event.target.value);
                        }}
                    />
                </>
            );
        }
    }, [tabIndex, rowCount, rowPage, page]);

    const onFinalize = async (updated) => {
        setTabIndex(0);
        setIdManualReferences(null);

        if (updated) {
            loadDataSource(page);
        }
    };

    return (
        <>
            <PageControl tabindex={tabIndex} onchangetab={(index) => setTabIndex(index)}>
                <TabSheet label="Ordens de Entrada">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={2}>
                            <InputText label="Registros" value={condition.id_in} onChangeValue={(e) => setCondition({ ...condition, id_in: e.target.value })} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <QueryMembers
                                title="Consulta Estabelecimentos"
                                roleMember="branch"
                                label="Estabelecimento"
                                value={condition.branch}
                                onChangeValue={(data) => setCondition({ ...condition, branch: data })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDate
                                label="Data inicial"
                                value={condition.issue_date_more_equal}
                                onChangeValue={(date) => setCondition({ ...condition, issue_date_more_equal: date })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDate
                                label="Data final"
                                value={condition.issue_date_less_equal}
                                onChangeValue={(date) => setCondition({ ...condition, issue_date_less_equal: date })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDropDowList
                                defaultValue={0}
                                label="Situação"
                                value={condition.status}
                                onChangeValue={(e) => setCondition({ ...condition, status: e.target.value })}
                            >
                                <MenuItem value={"-"}>Todos</MenuItem>
                                <MenuItem value={"active"}>Ativos</MenuItem>
                                <MenuItem value={"cancelled"}>Cancelados</MenuItem>
                                <MenuItem value={"reversed"}>Estornados</MenuItem>
                            </InputDropDowList>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDropDowList2
                                value={condition.order_type}
                                onChangeValue={(e) => setCondition({ ...condition, order_type: e.target.value })}
                                data={ordersTypesFilter}
                                label={"Tipo Ordem"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDropDowList
                                defaultValue={0}
                                label="Situação Referencia"
                                value={condition.status_reference}
                                onChangeValue={(e) => setCondition({ ...condition, status_reference: e.target.value })}
                            >
                                <MenuItem value={"-"}>Todos</MenuItem>
                                <MenuItem value={"pending"}>Pendente (Inclusive Parcial)</MenuItem>
                                <MenuItem value={"partial"}>Parcial</MenuItem>
                                <MenuItem value={"referenced"}>Referenciado</MenuItem>
                            </InputDropDowList>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <QueryMembers
                                title="Consulta Fornecedores"
                                roleMember="supplier"
                                label="Fornecedor"
                                value={condition.supplier}
                                onChangeValue={(data) => setCondition({ ...condition, supplier: data })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputText
                                label="Documents Fiscais"
                                value={condition.number_in}
                                onChangeValue={(e) => setCondition({ ...condition, number_in: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonMenu
                            style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                            variant="contained"
                            color="primary"
                            onClickMenu={(type) => {
                                onReport(type);
                            }}
                            disabled={!dataSource.length}
                            icon={<ListAltIcon fontSize="inherit" />}
                            menuItems={menuReports}
                            label={"Relatórios"}
                        />
                        <Button
                            style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                            variant="contained"
                            size="small"
                            type="primary"
                            onClick={() => {
                                loadDataSource(0, rowPage, condition);
                                setPage(0);
                                setConditionApply(condition);
                            }}
                            startIcon={<SearchIcon fontSize="inherit" />}
                        >
                            Pesquisar
                        </Button>
                        <Button
                            style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                            variant="contained"
                            size="small"
                            type="primary"
                            onClick={() => {
                                setCondition(initialCondition);
                            }}
                            startIcon={<DeleteSweepIcon fontSize="inherit" />}
                        >
                            Limpar
                        </Button>
                    </Grid>
                    <Grade dataSource={dataSource} metaData={metaData} disablepagination={true} />
                </TabSheet>
                <TabSheet label="Importação de Arquivos">
                    <InboundOrdersReceipt showOptions={tabIndex === 1} />
                </TabSheet>
                <TabSheet label="Referencia Manual" visible={!!idManualReferences}>
                    <InboundOrdersManualReferences
                        id={idManualReferences}
                        onLoadRecord={() => setTabIndex(2)}
                        onFinalize={(updated) => onFinalize(updated)}
                        showOptions={tabIndex === 2}
                    />
                </TabSheet>
            </PageControl>
            <InboundOrdersDetails id={idDetails} open={openDetails} setOpen={setOpenDetails} setLoading={setLoading} />
        </>
    );
}
