import apiForms from "../../services/api-forms";
import * as yup from 'yup';
import fileDownload from "js-file-download";

class ApiService extends apiForms {
    contasSchema = yup.object().shape({
        id_conta: yup
            .string()
            .required("Conta é obrigatório")
            .min(1, "Conta não pode ser em branco"),
        nome: yup
            .string()
            .required("Nome é obrigatório")
            .min(1, "Nome não pode ser em branco"),
        referencia: yup
            .string()
            .required("Referencia é obrigatório")
            .min(1, "Referencia não pode ser em branco"),
    });

    planosContasSchema = yup.object().shape({
        nome: yup
            .string()
            .required("Nome é obrigatório")
            .min(1, "Nome não pode ser em branco"),
        contas: yup.array().of(this.contasSchema).min(1, "Plano de contas deverá possuir pelo menos uma conta")
    });

    defaultValues = {
        id_plano_conta: undefined,
        nome: "",
        contas: []
    }

    constructor() {
        super("contabilidade/planoscontas", ["contas"]);
    }

    export = async (data, fileName) => {
        return await this.api.post("/" + this.urlApi + "/export", data, { responseType: "blob", })
        .then(response => {
            if (response.status !== 200) {
                return {
                    status: false,
                    message:
                        "Nenhum dado encontrado para gerar a exportação de contas.",
                };
            }

            fileDownload(response.data, `${fileName}.xlsx`);

            return {
                status: response.status === 200,
                data: [],
            };
        })
        .catch(error => {
            return { 
                status: false, 
                message: error.response.data.message 
            };
        });  
    }

    import = async (data) => {
        return await this.api.post("/" + this.urlApi + "/import", data, { 
            headers: {  
                "Content-Type": "multipart/form-data"
            } 
        })
        .then(response => {
            return { 
                status: (response.status === 200),
                data: response.data 
            };
        })
        .catch(error => {
            return { 
                status: false,
                message: (error.response && error.response.data ? error.response.data.message : error),
            };
        });
    }
}

export default new ApiService();