import React from "react";

import { Grid } from "@material-ui/core";

import { InputText, ModalEstabelecimentos } from "../../../../components";

export default function ArquivosRelatorioCaixa({ atributos, setAtributos }) {
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <ModalEstabelecimentos
                        label="Estabelecimento"
                        valueId={atributos?.estabelecimento?.id_estabelecimento || null}
                        onChangeValue={(data) => setAtributos({ ...atributos, estabelecimento: data })}
                    />
                </Grid>
                <Grid item xs={2}>
                    <InputText
                        label="Conta Débito"
                        value={atributos?.conta_debito || ""}
                        onChangeValue={(e) => setAtributos({ ...atributos, conta_debito: e.target.value })}
                    />
                </Grid>
                <Grid item xs={2}>
                    <InputText
                        label="Conta Crédito"
                        value={atributos?.conta_credito || ""}
                        onChangeValue={(e) => setAtributos({ ...atributos, conta_credito: e.target.value })}
                    />
                </Grid>
                <Grid item xs={2}>
                    <InputText
                        label="Histórico"
                        value={atributos?.historico || ""}
                        onChangeValue={(e) => setAtributos({ ...atributos, historico: e.target.value })}
                    />
                </Grid>
            </Grid>
        </>
    );
}
