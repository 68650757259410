import React, { useEffect, useState } from "react";

import formatUtils from "../../../services/formatUtils";

import { Table as TableCore, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper, IconButton, Collapse, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    tableContainer: {
        margin: theme.spacing(1, 0, 0, 0),
    },
}));

function Row({ metaData, data, index, dataSource, details, renderDetails, onExpandDetails, closeDetails }) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (open) {
            onExpandDetails(data, index);
        }
    }, [open]);

    useEffect(() => {
        if (closeDetails) {
            setOpen(false);
        }
    }, [closeDetails]);

    return (
        <>
            <TableRow hover>
                {details === true && (
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                )}
                {metaData.map((title, j) => {
                    if (title.render) {
                        return <TableCell align={title.align ? title.align : "left"}>{title.render(data, index, dataSource)}</TableCell>;
                    }

                    if (title.format) {
                        switch (title.format) {
                            case "datetime":
                                return (
                                    <TableCell align={title.align ? title.align : "left"}>{formatUtils.formatDateTime(data[title.field] || null)}</TableCell>
                                );
                            case "date":
                                return <TableCell align={title.align ? title.align : "left"}>{formatUtils.formatDate(data[title.field] || null)}</TableCell>;
                            case "time":
                                return <TableCell align={title.align ? title.align : "left"}>{formatUtils.formatTime(data[title.field] || null)}</TableCell>;
                            case "float":
                                return <TableCell align={"right"}>{formatUtils.formatValor(data[title.field], title.decimal || 2)}</TableCell>;
                            default:
                                return <TableCell align={title.align ? title.align : "left"}>{data[title.field]}</TableCell>;
                        }
                    }

                    return (
                        <TableCell key={j} align={title.align ? title.align : "left"}>
                            {data[title.field]}
                        </TableCell>
                    );
                })}
            </TableRow>
            {details === true && (
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={metaData.length + 1}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>{renderDetails(data, index)}</Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}

export default function Table({ metaData, dataSource, details, maxHeight, renderDetails, onExpandDetails, closeDetails, labelDetails }) {
    const classes = useStyles();

    const header = () => {
        return (
            <TableHead>
                <TableRow key={`row-header`}>
                    {details === true && <TableCell>{labelDetails}</TableCell>}
                    {metaData.map((title, i) => {
                        return (
                            <TableCell key={`col-${i}`} align={title.align ? title.align : "left"} style={{ minWidth: title.minWidth ? title.minWidth : 1 }}>
                                {title.label}
                            </TableCell>
                        );
                    })}
                </TableRow>
            </TableHead>
        );
    };

    const content = () => {
        return (
            <TableBody>
                {dataSource.map((record, i) => {
                    return (
                        <Row
                            key={`row-${i}`}
                            metaData={metaData}
                            data={record}
                            details={details}
                            index={i}
                            dataSource={dataSource}
                            renderDetails={renderDetails}
                            onExpandDetails={onExpandDetails}
                            closeDetails={closeDetails}
                            labelDetails={labelDetails}
                        />
                    );
                })}
            </TableBody>
        );
    };

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.tableContainer} aria-label="a dense table" style={{ maxHeight: maxHeight }}>
                <TableCore stickyHeader className={classes.table} aria-label="simple table" size="small">
                    {header()}
                    {content()}
                </TableCore>
            </TableContainer>
        </Paper>
    );
}
