import React, { useState, useEffect, useContext, useMemo } from "react";

import ApiAttributes from "../../../api/system/attributes/ApiAttributes";
import ApiTypes from "../../../api/system/types/ApiTypes";

import { AppContext } from "../../../App";

import { Grade, GradeOpcoes, PageControl, TabSheet, InputText, InputSelector } from "../../../components";
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, Tooltip } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import InputDropDowList from "../../../components/inputs/input.dropdowlist2";

import FormatsUtils from "../../../services/FormatsUtils";
import ApiMembers from "../../../api/basics/members/ApiMembers";
import MembersChange from "./membersChange";
import QueryCities from "../query/cities";
import { AppOptionsContext } from "../../../components/main/main";
import { TypeNatureAllowNull } from "./utils";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import StoreIcon from "@material-ui/icons/Store";
import PeopleIcon from "@material-ui/icons/People";

import TablePagination from "../../../components/grade/TablePagination";
import formatUtils from "../../../services/formatUtils";

const initialCondition = {
    name: "",
    document: "",
    type_nature: "-",
    city: {},
    inactivate: false,
    branch_exists: false,
    customer_exists: false,
    supplier_exists: false,
};

export default function Members() {
    const { setLoading, showMessageError } = useContext(AppContext);
    const { setOptions, setTitle } = useContext(AppOptionsContext);

    const [tabIndex, setTabIndex] = useState(0);
    const [dataSource, setDataSource] = useState([]);

    const [rowCount, setRowCount] = useState(0);
    const [rowPage, setRowPage] = useState(10);
    const [page, setPage] = useState(0);

    const [condition, setCondition] = useState(initialCondition);
    const [conditionApply, setConditionApply] = useState(initialCondition);

    const [idInEdition, setIdInEdition] = useState(0);

    const [attributesMember, setAttributesMember] = useState([]);
    const [typesMember, setTypesMember] = useState([]);

    const metaData = useMemo(
        () => [
            {
                label: "Opções",
                render: (record) => {
                    const options = [
                        { tipo: "edit", label: "Edição" },
                        { tipo: "inactive", label: "Inativação" },
                    ];

                    return (
                        <span>
                            <GradeOpcoes record={record} onClickOpcoes={onClickOptions} opcoes={options} />
                        </span>
                    );
                },
            },
            {
                label: "Info.",
                render: (record) => {
                    const listInfo = [];

                    if (record.branch?.id) {
                        const title = <>Estabelecimento desde {formatUtils.formatDateTime(record.branch.created_at)}</>;

                        listInfo.push(
                            <Tooltip title={title}>
                                <StoreIcon />
                            </Tooltip>
                        );
                    }

                    if (record.supplier?.id) {
                        const title = <>Fornecedor desde {formatUtils.formatDateTime(record.supplier.created_at)}</>;

                        listInfo.push(
                            <Tooltip title={title}>
                                <LocalShippingIcon />
                            </Tooltip>
                        );
                    }

                    if (record.customer?.id) {
                        const title = <>Cliente desde {formatUtils.formatDateTime(record.customer.created_at)}</>;

                        listInfo.push(
                            <Tooltip title={title}>
                                <PeopleIcon />
                            </Tooltip>
                        );
                    }

                    return (
                        <>
                            {listInfo.map((rec) => {
                                return <span>{rec}</span>;
                            })}
                        </>
                    );
                },
            },
            {
                label: "Código",
                field: "id",
                align: "right",
            },
            {
                label: "Documento",
                render: (record) => {
                    return <>{FormatsUtils().document(record.document, record.type_member)}</>;
                },
            },
            {
                label: "Nome/Razão Social",
                field: "name",
            },
            {
                label: "Cidade",
                render: (record) => {
                    return <>{record.city.name}</>;
                },
            },
            {
                label: "UF",
                render: (record) => {
                    return <>{record.city.state_id}</>;
                },
            },
            { label: "Dh.Criação", field: "created_at", format: "datetime", minWidth: 160 },
        ],
        [dataSource]
    );

    useEffect(() => {
        setTitle("Membros");

        loadDataSource(0);

        // Attributes
        ApiAttributes()
            .apiDefault.findAll({ entity_in: "members,members_branchs,members_suppliers,members_customers,integrations,members_relationships" })
            .then((rec) => {
                setAttributesMember(rec.data);
            });

        // Types
        ApiTypes()
            .apiDefault.findAll({ entity_in: "members,members_documents,members_relationships" })
            .then((rec) => {
                setTypesMember(rec.data);
            });

        return () => {
            setOptions(null);
        };
    }, []);

    const loadDataSource = async (newPage, newRowPage, newCondition) => {
        setLoading(true);

        // Condition
        const conditionCustom = makeCondition(newCondition || conditionApply);

        try {
            const result = await ApiMembers().apiDefault.find({
                page: newPage + 1,
                pageSize: newRowPage || rowPage,
                condition: conditionCustom,
                associates: ["city", "branch", "customer", "supplier"],
                orderBy: ["name"],
            });

            if (result.status) {
                setDataSource(result.data);
                setRowCount(result.info.total);
            } else {
                setDataSource([]);
                if (result.message) {
                    showMessageError(result.message);
                }
            }
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const makeCondition = (newCondition) => {
        const result = {};

        if (newCondition.name) {
            result.name_like = encodeURIComponent(newCondition.name + "+");
        }

        if (newCondition.document) {
            result.document = encodeURIComponent(newCondition.document);
        }

        if (newCondition.city?.id) {
            result.city_id = newCondition.city?.id;
        }

        if (!newCondition.inactivate) {
            result.inactivate_at_is_null = true;
        }

        // Type Nature
        if (newCondition.type_nature !== "-") {
            result.type_nature = newCondition.type_nature;
        }

        // Branch
        if (newCondition.branch_exists) {
            result.branch_exists = true;
        }

        // Supplier
        if (newCondition.supplier_exists) {
            result.supplier_exists = true;
        }

        // Customer
        if (newCondition.customer_exists) {
            result.customer_exists = true;
        }

        return result;
    };

    const onClickOptions = (record, tipo) => {
        switch (tipo) {
            case "edit":
                setIdInEdition(record.id);
                break;
            case "inactive":
                break;
            default:
                break;
        }
    };

    const onFinalize = async (updated) => {
        setTabIndex(0);
        setIdInEdition(0);

        if (updated) {
            loadDataSource(page);
        }
    };

    useEffect(() => {
        if (tabIndex === 0) {
            setOptions(
                <>
                    <TablePagination
                        rowCount={rowCount}
                        rowPage={rowPage}
                        page={page}
                        onPageChange={(event, newPage) => {
                            loadDataSource(newPage);
                            setPage(newPage);
                        }}
                        onRowsPerPageChange={(event) => {
                            loadDataSource(0, event.target.value);
                            setPage(0);
                            setRowPage(event.target.value);
                        }}
                    />
                </>
            );
        }
    }, [tabIndex, rowCount, rowPage, page]);

    return (
        <>
            <PageControl tabindex={tabIndex} onchangetab={(index) => setTabIndex(index)}>
                <TabSheet label="Consulta">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                            <InputText
                                label="Nome ou parte do nome do membro"
                                value={condition.name}
                                onChangeValue={(e) => setCondition({ ...condition, name: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDropDowList
                                value={condition.type_nature}
                                onChangeValue={(e) => setCondition({ ...condition, type_nature: e.target.value })}
                                data={TypeNatureAllowNull}
                                label={"Natureza"}
                            ></InputDropDowList>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputText
                                label="Documento"
                                value={condition.document}
                                onChangeValue={(e) => setCondition({ ...condition, document: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <QueryCities
                                country={1058}
                                label="Cidade"
                                value={condition.city}
                                onChangeValue={(data) => setCondition({ ...condition, city: data })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputSelector
                                label={"Exibir inativos"}
                                value={condition.inactivate || false}
                                onChangeValue={(e) => setCondition({ ...condition, inactivate: e.target.checked })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={9}>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={condition.branch_exists}
                                            onChange={(e) => setCondition({ ...condition, branch_exists: e.target.checked })}
                                            name="checkedA"
                                        />
                                    }
                                    label="Estabelecimento"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={condition.customer_exists}
                                            onChange={(e) => setCondition({ ...condition, customer_exists: e.target.checked })}
                                            name="checkedA"
                                        />
                                    }
                                    label="Cliente"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={condition.supplier_exists}
                                            onChange={(e) => setCondition({ ...condition, supplier_exists: e.target.checked })}
                                            name="checkedA"
                                        />
                                    }
                                    label="Fornecedor/Prestador"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                style={{ float: "right", marginTop: "5px", marginBottom: "5px" }}
                                variant="contained"
                                size="small"
                                type="primary"
                                onClick={() => {
                                    loadDataSource(0, rowPage, condition);
                                    setPage(0);
                                    setConditionApply(condition);
                                }}
                                startIcon={<SearchIcon fontSize="inherit" />}
                            >
                                Pesquisar
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grade dataSource={dataSource} metaData={metaData} disablepagination />
                    </Grid>
                </TabSheet>
                <TabSheet label={idInEdition ? `Membro ${idInEdition}` : null} icon={idInEdition ? null : <AddCircleIcon />}>
                    <Grid container spacing={1}>
                        <MembersChange
                            id={idInEdition}
                            onLoadRecord={() => setTabIndex(1)}
                            onFinalize={(updated) => onFinalize(updated)}
                            attributes={attributesMember}
                            types={typesMember}
                            showOptions={tabIndex === 1}
                        />
                    </Grid>
                </TabSheet>
            </PageControl>
        </>
    );
}
