import React from "react";

import { TablePagination as TablePaginationCore } from "@material-ui/core";

export default function TablePagination({ listRowsPerPage, rowCount, rowPage, page, onPageChange, onRowsPerPageChange }) {
    return (
        <TablePaginationCore
            rowsPerPageOptions={listRowsPerPage || [5, 10, 25, 50]}
            count={rowCount}
            rowsPerPage={rowPage}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={(paginationInfo) => {
                return `Página ${paginationInfo?.page || 0 + 1} - Registros ${paginationInfo.from}-${paginationInfo.to} de ${paginationInfo.count}`;
            }}
        />
    );
}
