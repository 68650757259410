import React, { Component } from "react";

import styles from "../../styles/manutencao";

import usuariosService from "./usuarios.service";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";

class ManutencaoUsuarios extends Component {
    state = {
        data: {},
    };

    componentDidMount() {
        this.onClearRecord();
    }

    saveRecord = async (data) => {
        const service = new usuariosService();
        const validate = await service.ValidateData(usuariosService.usuarioSchema, this.state.data);
        if (!validate.status) {
            this.onError(validate.message);
            return;
        }

        /* Senha padrão */
        data.senha = "nidas.password";

        const response = await service.Append(data);
        if (!response.status) {
            this.onError(response.message);
            return;
        }

        this.onClearRecord();
        this.props.onFinalize();
    };

    onGravar() {
        this.saveRecord(this.state.data);
    }

    onClearRecord() {
        this.setState({
            data: {
                nome: "",
                login: "",
            },
        });
    }

    onError(messages) {
        this.props.onError(messages);
    }

    render() {
        const { data } = this.state;
        const { classes } = this.props;

        return (
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <Alert severity="info">
                        Ao efetuar o processo de gravação do usuário, será enviado um e-mail contendo instruções para criação da senha de login do sistema.
                    </Alert>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <TextField
                        required
                        size="small"
                        fullWidth
                        label="Nome"
                        variant="outlined"
                        value={data.nome}
                        onChange={(e) => this.setState({ data: { ...this.state.data, nome: e.target.value } })}
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <TextField
                        required
                        size="small"
                        fullWidth
                        label="Usuário"
                        variant="outlined"
                        value={data.login}
                        onChange={(e) => this.setState({ data: { ...this.state.data, login: e.target.value } })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button className={classes.buttonClear} variant="contained" color="secondary" onClick={() => this.onClearRecord()}>
                        Limpar
                    </Button>
                    <Button className={classes.buttonApply} variant="contained" color="primary" onClick={() => this.onGravar()}>
                        Gravar
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(ManutencaoUsuarios);
