import React, { useState, useEffect, useContext, useMemo } from "react";

import FormatsUtils from "../../../../services/FormatsUtils";

import { Grade, GradeOpcoes, InputDate, InputDropDowList, InputText } from "../../../../components";

import { Button, MenuItem, Grid } from "@material-ui/core";
import { AppContext } from "../../../../App";

import SearchIcon from "@material-ui/icons/Search";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";

import { AppOptionsContext } from "../../../../components/main/main";

import TablePagination from "../../../../components/grade/TablePagination";
import ApiProcessFiles from "../../../../api/system/processFiles";
import ApiLayoutsFiles from "../../../../api/system/layoutsFiles/ApiLayoutsFiles";

import BackupIcon from "@material-ui/icons/Backup";
import UpdateIcon from "@material-ui/icons/Update";
import OutboundOrdersReceiptImport from "../receiptImport";
import ApiOutboundOrders from "../../../../api/stocks/outboundOrders/ApiOutboundOrders";

const initialCondition = {
    file_name_like: "",
    message_like: "",
    status: "pending",
    created_at_less_equal: null,
    created_at_more_equal: null,
};

export default function OutboundOrdersReceipt({ showOptions, setOutboundOrderManual }) {
    const { setLoading, showMessageError, showMessageInfo, showMessageConfirmation } = useContext(AppContext);
    const { setOptions } = useContext(AppOptionsContext);

    const [openImport, setOpenImport] = useState(false);
    const [layoutsFiles, setLayoutsFiles] = useState([]);

    const [dataSource, setDataSource] = useState([]);

    const [rowCount, setRowCount] = useState(0);
    const [rowPage, setRowPage] = useState(10);
    const [page, setPage] = useState(0);

    const [condition, setCondition] = useState(initialCondition);
    const [conditionApply, setConditionApply] = useState(initialCondition);

    const metaData = useMemo(() => [
        {
            label: "Opções",
            render: (record) => {
                const options = [];

                if (record.status === "error") {
                    options.push({ tipo: "remove", label: "Excluir Arquivo" });
                    options.push({ tipo: "process_manual", label: "Processar manualmente" });
                }

                return (
                    <span>
                        <GradeOpcoes record={record} onClickOpcoes={onClickOptions} opcoes={options} />
                    </span>
                );
            },
        },
        { label: "Reg.", field: "id" },
        { label: "Identificador Arquivo", field: "file_name" },
        {
            label: "Situação",
            render: (record) => {
                let situacao = "Pendente";
                switch (record.status) {
                    case "scheduled":
                        situacao = "Agendado";
                        break;
                    case "error":
                        situacao = "Erro";
                        break;
                    case "completed":
                        situacao = "Completo";
                        break;
                    default:
                        break;
                }

                return <span> {situacao} </span>;
            },
        },
        {
            label: "Mensagens",
            render: (record) => {
                const msgs = record.message?.split(";");

                if (!msgs?.length) {
                    return null;
                }

                return (
                    <>
                        {msgs.map((rec) => {
                            return (
                                <>
                                    {rec}
                                    <br />
                                </>
                            );
                        })}
                    </>
                );
            },
        },
        { label: "Dh.Criação", field: "created_at", format: "datetime", minWidth: 160 },
        { label: "Dh.Atualização", field: "updated_at", format: "datetime", minWidth: 160 },
    ]);

    const onClickOptions = (record, tipo) => {
        switch (tipo) {
            case "remove":
                showMessageConfirmation("Confirma exclusão do arquivo? Este processo é irreversível.", () => {
                    onRemoveFile(record.id);
                });
                break;
            case "process_manual":
                showMessageConfirmation("Confirma processamento manual? Esta pendencia será retirada da fila.", () => {
                    onProcessFileManual(record.id);
                });
                break;
            default:
                break;
        }
    };

    const onRemoveFile = async (id) => {
        setLoading(true);

        try {
            const result = await ApiProcessFiles().apiDefault.remove(id);

            if (result.status) {
                showMessageInfo("Arquivo removido com sucesso");
                loadDataSource(page);
            } else {
                if (result.message) {
                    showMessageError(result.message);
                }
            }
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onProcessFileManual = async (id) => {
        setLoading(true);

        try {
            const result = await ApiOutboundOrders().processFileManual(id);

            if (result.status) {
                showMessageInfo("Arquivo carregado com sucesso");
                setOutboundOrderManual(result.data.shift());
            } else {
                if (result.message) {
                    showMessageError(result.message);
                }
            }
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Layouts Import and Export
        ApiLayoutsFiles()
            .apiDefault.findAll({ internal_layout_in: "import-orders-out" })
            .then((rec) => {
                setLayoutsFiles(rec.data);
            });

        return () => {
            setOptions(null);
        };
    }, []);

    const loadDataSource = async (newPage, newRowPage, newCondition) => {
        setLoading(true);

        // Condition
        const conditionCustom = makeCondition(newCondition || conditionApply);

        try {
            const result = await ApiProcessFiles().findAll("import-orders-out", {
                page: newPage + 1,
                pageSize: newRowPage || rowPage,
                condition: conditionCustom,
                orderBy: "created_at-desc",
            });

            if (result.status) {
                setDataSource(result.data);
                setRowCount(result.info.total);
            } else {
                setDataSource([]);
                if (result.message) {
                    showMessageError(result.message);
                }
            }
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const makeCondition = (newCondition, isBody = false) => {
        const result = {};

        // File Name
        if (newCondition.file_name_like) {
            if (isBody) {
                result.file_name_like = newCondition.file_name_like + "+";
            } else {
                result.file_name_like = encodeURIComponent(newCondition.file_name_like + "+");
            }
        }

        // Messages
        if (newCondition.message_like) {
            if (isBody) {
                result.message_like = newCondition.message_like + "+";
            } else {
                result.message_like = encodeURIComponent(newCondition.message_like + "+");
            }
        }

        // Date Due
        if (newCondition.created_at_less_equal) {
            result.created_at_less_equal = FormatsUtils().dateAnsi(newCondition.created_at_less_equal) + "T23:59:59";
        }
        if (newCondition.created_at_more_equal) {
            result.created_at_more_equal = FormatsUtils().dateAnsi(newCondition.created_at_more_equal);
        }

        // Status
        result.status = newCondition.status;

        return result;
    };

    useEffect(() => {
        if (showOptions) {
            setOptions(
                <>
                    <TablePagination
                        rowCount={rowCount}
                        rowPage={rowPage}
                        page={page}
                        onPageChange={(event, newPage) => {
                            loadDataSource(newPage);
                            setPage(newPage);
                        }}
                        onRowsPerPageChange={(event) => {
                            loadDataSource(0, event.target.value);
                            setPage(0);
                            setRowPage(event.target.value);
                        }}
                    />
                </>
            );
        }
    }, [showOptions, rowCount, rowPage, page]);

    const onExecuteImport = async (files, layout, documentTypeDefault) => {
        setLoading(true);

        try {
            const result = await ApiOutboundOrders().receipt(files, layout);

            if (result.status) {
                showMessageInfo(
                    <>
                        <strong>Importação efetuada com sucesso</strong>
                        <br />
                        <li>Total registros: {result.data.count}</li>
                        <li>Arquivos Agendados: {result.data.files_create}</li>
                        <li>Mensagens:</li>
                        {result.message.map((msg) => {
                            return (
                                <>
                                    -{msg}
                                    <br />
                                </>
                            );
                        })}
                    </>
                );
            } else {
                if (result.message) {
                    showMessageError(result.message);
                }
            }
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onProcessPending = async (condition) => {
        setLoading(true);

        try {
            const result = await ApiOutboundOrders().processPending(condition);

            if (result.status) {
                showMessageInfo(
                    <>
                        <strong>Solicitação de processamento efetuada</strong>
                        <br />
                        <li>Arquivos Agendados: {result.data.files_scheduled}</li>
                        <li>Mensagens:</li>
                        {result.message.map((msg) => {
                            return (
                                <>
                                    -{msg}
                                    <br />
                                </>
                            );
                        })}
                    </>
                );
            } else {
                if (result.message) {
                    showMessageError(result.message);
                }
            }
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <InputText
                        label="Nome completo ou parte do arquivo"
                        value={condition.file_name_like}
                        onChangeValue={(e) => setCondition({ ...condition, file_name_like: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <InputDate
                        label="Data inicial"
                        value={condition.created_at_more_equal}
                        onChangeValue={(date) => setCondition({ ...condition, created_at_more_equal: date })}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <InputDate
                        label="Data final"
                        value={condition.created_at_less_equal}
                        onChangeValue={(date) => setCondition({ ...condition, created_at_less_equal: date })}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <InputDropDowList
                        defaultValue={0}
                        label="Situação"
                        value={condition.status}
                        onChangeValue={(e) => setCondition({ ...condition, status: e.target.value })}
                    >
                        <MenuItem value={"pending"}>Pendentes</MenuItem>
                        <MenuItem value={"scheduled"}>Agendados</MenuItem>
                        <MenuItem value={"error"}>Com erro</MenuItem>
                        <MenuItem value={"completed"}>Processados</MenuItem>
                    </InputDropDowList>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <InputText
                        label="Mensagens"
                        value={condition.message_like}
                        onChangeValue={(e) => setCondition({ ...condition, message_like: e.target.value })}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Button
                    style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => setOpenImport(true)}
                    startIcon={<BackupIcon fontSize="inherit" />}
                >
                    Importar Arquivo(s)
                </Button>
                <Button
                    style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={() => {
                        onProcessPending(makeCondition(conditionApply, true));
                    }}
                    startIcon={<UpdateIcon fontSize="inherit" />}
                >
                    Processar Arquivos
                </Button>
                <Button
                    style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                    variant="contained"
                    size="small"
                    type="primary"
                    onClick={() => {
                        loadDataSource(0, rowPage, condition);
                        setPage(0);
                        setConditionApply(condition);
                    }}
                    startIcon={<SearchIcon fontSize="inherit" />}
                >
                    Pesquisar
                </Button>
                <Button
                    style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                    variant="contained"
                    size="small"
                    type="primary"
                    onClick={() => {
                        setCondition(initialCondition);
                    }}
                    startIcon={<DeleteSweepIcon fontSize="inherit" />}
                >
                    Limpar
                </Button>
            </Grid>
            <Grade dataSource={dataSource} metaData={metaData} disablepagination={true} />
            <OutboundOrdersReceiptImport
                layouts={layoutsFiles}
                open={openImport}
                setOpen={setOpenImport}
                title={"Importação Arquivo(s) para Geração de Ordens de Saída"}
                onExecute={onExecuteImport}
            />
        </>
    );
}
