import fileDownload from "js-file-download";
import ApiDefault from "../../ApiDefault";

export default function ApiInboundOrders() {
    const apiDefault = ApiDefault("v2/suppliers/inbound-orders");

    const details = async (id) => {
        return await apiDefault.api
            .get(`/${apiDefault.url}/manager/details/${id}`)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const list = async ({ page = 1, pageSize = 5, condition }) => {
        let urlCustom = `/${apiDefault.url}/manager/list?page=${page}&limit=${pageSize}`;

        for (let field in condition) {
            if (condition[field] !== "") {
                urlCustom += `&${field}=${condition[field]}`;
            }
        }

        return await apiDefault.api
            .get(urlCustom)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const receipt = async (files, layout) => {
        return await apiDefault.api
            .post(`/${apiDefault.url}/receipt/import-files/${layout}`, files, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                    message: response.data.message || [],
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const processPending = async (condition) => {
        return await apiDefault.api
            .post(`/${apiDefault.url}/receipt/process-files`, condition)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                    message: response.data.message || [],
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const referencesPending = async (condition, scheduled = false) => {
        return await apiDefault.api
            .post(`/${apiDefault.url}/manager/link-outbound-orders${scheduled ? "?scheduled" : ""}`, condition)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                    message: response.data.message || [],
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const manualReferences = async (id, data) => {
        return await apiDefault.api
            .put(`/${apiDefault.url}/manager/manual-references/${id}`, data)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                    message: response.data.message || [],
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const scheduleFile = async (id, data) => {
        return await apiDefault.api
            .post(`/${apiDefault.url}/receipt/process-files/schedule/${id}`, data)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                    message: response.data.message || [],
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const report = async ({ layout, format, fileName, condition }) => {
        let urlCustom = `/${apiDefault.url}/manager/report/${layout}`;

        const params = [];
        for (let field in condition) {
            if (condition[field] !== "") {
                params.push(`${field}=${condition[field]}`);
            }
        }

        if (params.length) {
            urlCustom += `?${params.join("&")}`;
        }

        return await apiDefault.api
            .get(urlCustom, { responseType: "blob" })
            .then((response) => {
                if (response.status !== 200) {
                    return {
                        status: false,
                        message: "Nenhum dado encontrado para exportação do arquivo.",
                    };
                }

                fileDownload(response.data, `${fileName}.${format}`);

                return {
                    status: response.status === 200,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    return {
        apiDefault,
        list,
        details,
        manualReferences,
        processPending,
        receipt,
        referencesPending,
        report,
        scheduleFile,
    };
}
