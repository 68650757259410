export default function FormatsUtils() {
    const document = (value, type) => {
        switch (type) {
            case "cnpj":
                return (
                    value.substring(0, 2) +
                    "." +
                    value.substring(2, 5) +
                    "." +
                    value.substring(5, 8) +
                    "/" +
                    value.substring(8, 12) +
                    "-" +
                    value.substring(12, 14)
                );
            case "cpf":
                return value.substring(0, 3) + "." + value.substring(3, 6) + "." + value.substring(6, 9) + "-" + value.substring(9, 11);
            default:
                return value;
        }
    };

    const dateAnsi = (value) => {
        const date = new Intl.DateTimeFormat("en-GB").format(value);
        return date.substring(6, 10) + "-" + date.substring(3, 5) + "-" + date.substring(0, 2);
    };

    const dateTimeAnsi = (value) => {
        const date = new Intl.DateTimeFormat("en-GB").format(value);
        return (
            date.substring(6, 10) +
            "-" +
            date.substring(3, 5) +
            "-" +
            date.substring(0, 2) +
            " " +
            date.substring(11, 13) +
            ":" +
            date.substring(14, 16) +
            ":" +
            date.substring(17, 19)
        );
    };

    const dateTimeUnique = (value) => {
        const date = new Intl.DateTimeFormat("en-GB").format(value);
        return date.substring(6, 10) + date.substring(3, 5) + date.substring(0, 2) + date.substring(11, 13) + date.substring(14, 16) + date.substring(17, 19);
    };

    const float = (value, decimals = 2) => {
        return String(parseFloat(value).toFixed(decimals)).replace(".", ",");
    };

    return {
        document,
        dateAnsi,
        dateTimeAnsi,
        dateTimeUnique,
        float,
    };
}
