import React, { useContext, useMemo, useState } from "react";

import { Grade, GradeOpcoes, InputText } from "../../../../components";
import { Grid, Button, IconButton, Chip, TextField } from "@material-ui/core";
import ListUtils from "../../../../services/ListUtils";
import { CustomizationsRulesModalChange } from "../rulesModalChange";

import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import DeleteIcon from "@material-ui/icons/Delete";
import StringUtils from "../../../../services/StringUtils";
import { CustomizationsRulesModalClone } from "../rulesModalClone";
import ApiCustomizations from "../../../../api/system/customizations/ApiCustomizations";
import { AppContext } from "../../../../App";

const initialValues = {
    priorityAnt: 0,
    name: "",
    priority: 0,
    rules: [],
    value: "",
};

export default function CustomizationsRulesChange({ rules, setRules, types, typesRules, internalLayoutValue }) {
    const { setLoading, showMessageError } = useContext(AppContext);

    const [rule, setRule] = useState(initialValues);
    const [openRules, setOpenRules] = useState(false);
    const [openRulesClone, setOpenRulesClone] = useState(false);
    const [filterNameAndValue, setFilterNameAndValue] = useState("");

    const typesRulesFilter = useMemo(() => {
        return (
            typesRules.map((rec) => {
                return {
                    value: rec.name,
                    text: rec.description,
                };
            }) || []
        );
    }, [typesRules]);

    const rulesDatasource = useMemo(() => {
        let rulesCustom = [...rules];

        if (filterNameAndValue) {
            const filterNameAndValueCustom = StringUtils().clearWord(filterNameAndValue).toUpperCase();
            const filterNameAndValueMatch = new RegExp(`${filterNameAndValueCustom.replace(/\+/g, ".*")}`, "g");

            rulesCustom = rulesCustom.filter((rec) => {
                if (rec.value === filterNameAndValue) {
                    return true;
                }

                const nameCustom = StringUtils().clearWord(rec.name).toUpperCase();

                if (nameCustom.match(filterNameAndValueMatch)) {
                    return true;
                }

                return false;
            });
        }

        return rulesCustom;
    }, [rules, filterNameAndValue]);

    const metaData = useMemo(() => [
        {
            label: "Opções",
            render: (record, index) => {
                const options = [
                    { tipo: "edit", label: "Edição" },
                    { tipo: "remove", label: "Exclusão" },
                ];

                return (
                    <span>
                        <GradeOpcoes record={record} indexRecord={index} onClickOpcoes={onClickOptions} opcoes={options} />
                    </span>
                );
            },
        },
        {
            render: (record, index) => {
                if (index === 0) {
                    return null;
                }

                return (
                    <IconButton
                        size="small"
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        disabled={!!filterNameAndValue}
                        onClick={(event) => {
                            let rulesCustom = [...rules];
                            const priorityOld = rulesCustom[index].priority;
                            const priorityNew = rulesCustom[index - 1].priority;

                            rulesCustom[index].priority = priorityNew;
                            rulesCustom[index - 1].priority = priorityOld;

                            rulesCustom = ListUtils().sort([...rulesCustom], "priority");
                            rulesCustom.forEach((rec, i) => {
                                rec.priority = i + 1;
                            });

                            setRules(rulesCustom);
                        }}
                    >
                        <ArrowUpwardIcon />
                    </IconButton>
                );
            },
        },
        {
            render: (record, index, list) => {
                if (index === list.length - 1) {
                    return null;
                }

                return (
                    <IconButton
                        size="small"
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        disabled={!!filterNameAndValue}
                        onClick={(event) => {
                            let rulesCustom = [...rules];
                            const priorityOld = rulesCustom[index].priority;
                            const priorityNew = rulesCustom[index + 1].priority;

                            rulesCustom[index].priority = priorityNew;
                            rulesCustom[index + 1].priority = priorityOld;

                            rulesCustom = ListUtils().sort([...rulesCustom], "priority");
                            rulesCustom.forEach((rec, i) => {
                                rec.priority = i + 1;
                            });

                            setRules(rulesCustom);
                        }}
                    >
                        <ArrowDownwardIcon />
                    </IconButton>
                );
            },
        },

        { label: "Prioridade", field: "priority", align: "right" },
        { label: "Nome da Regra", field: "name" },
        {
            label: "Regras",
            render: (record) => {
                const listItemRules = record.rules.map((rule) => {
                    const type = typesRulesFilter.find((rec) => rec.value === rule.name);
                    return `${type?.text || "NDA"}`;
                });

                return (
                    <>
                        {listItemRules.map((rec) => {
                            return <Chip size="small" label={rec} color={rec === "NDA" ? "secondary" : "primary"} />;
                        })}
                    </>
                );
            },
        },
        { label: "Valor", field: "value" },
    ]);

    const onClickOptions = async (record, type, index) => {
        switch (type) {
            case "edit":
                setRule({ ...record, priorityAnt: record.priority });
                break;
            case "remove":
                const rulesCustom = ListUtils().sort([...rules], "priority");
                const indexRemove = rulesCustom.findIndex((rec) => rec.priority === record.priority);

                rulesCustom.splice(indexRemove, 1);

                rulesCustom.forEach((rec, i) => {
                    rec.priority = i + 1;
                });

                setRules(rulesCustom);
                break;
            default:
                break;
        }
    };

    const onApplyRulesClone = async (value) => {
        // Find Rules
        setLoading(true);

        try {
            const result = await ApiCustomizations().apiDefault.findId(value.data.id, { associates: ["rules"] });

            if (!result.status) {
                showMessageError(result.message);
                return;
            }

            // Clone
            let rulesCustom = [...result.data.rules];

            // Clear all
            if (value.type === "clear") {
                setRules(rulesCustom);
                return;
            }

            const rulesCurrent = [...rules] || [];

            // New Only
            if (value.type === "new") {
                rulesCustom.forEach((rec) => {
                    const ruleFind = rulesCurrent.find((cur) => cur.value === rec.value);

                    if (!ruleFind) {
                        rulesCurrent.push(rec);
                    }
                });
            }

            // Merge
            if (value.type === "merge") {
                rulesCustom.forEach((rec) => {
                    const ruleFindIndex = rulesCurrent.findIndex((cur) => cur.value === rec.value);

                    if (ruleFindIndex === -1) {
                        rulesCurrent.push(rec);
                    } else {
                        rulesCurrent[ruleFindIndex].rules = [...rec.rules];
                    }
                });
            }

            const rulesSort = ListUtils().sort(rulesCurrent, "priority");
            rulesSort.forEach((rec, i) => {
                rec.priority = i + 1;
            });
            setRules(rulesSort);
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <InputText required label="Nome" value={rule.name} onChangeValue={(e) => setRule({ ...rule, name: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <InputText
                            required
                            type="number"
                            label="Prioridade"
                            value={rule.priority}
                            onChangeValue={(e) => setRule({ ...rule, priority: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <InputText required label="Valor" value={rule.value} onChangeValue={(e) => setRule({ ...rule, value: e.target.value })} />
                    </Grid>
                </Grid>
                <Grid container spacing={1} style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                    <Grid item xs={12} sm={8}>
                        <Button
                            style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => setRule({ ...initialValues, priority: (rules || []).length + 1 })}
                        >
                            Nova regra
                        </Button>
                        <Button
                            style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => setOpenRules(true)}
                        >
                            Condições*
                        </Button>
                        <Button
                            style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                const rulesCustom = [...rules];

                                // Fix
                                rule.priority = Number(rule.priority);

                                const indexPriority = rulesCustom.findIndex((rec) => rec.priority === rule.priority);

                                if (rule.priorityAnt) {
                                    const index = rulesCustom.findIndex((rec) => rec.priority === rule.priorityAnt);

                                    if (indexPriority && indexPriority !== index) {
                                        rulesCustom.forEach((rec, i) => {
                                            if (i >= indexPriority) {
                                                rec.priority = rec.priority + 1;
                                            }
                                        });
                                    }

                                    rulesCustom[index] = rule;
                                } else {
                                    if (indexPriority) {
                                        rulesCustom.forEach((rec, i) => {
                                            if (i >= indexPriority) {
                                                rec.priority = rec.priority + 1;
                                            }
                                        });
                                    }

                                    rulesCustom.push(rule);
                                }

                                const rulesSort = ListUtils().sort(rulesCustom, "priority");

                                rulesSort.forEach((rec, i) => {
                                    rec.priority = i + 1;
                                });

                                setRules(rulesSort);
                                setRule({ ...initialValues, priority: rulesCustom.length + 1 });
                            }}
                        >
                            Salvar
                        </Button>
                        <Button
                            style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => setOpenRulesClone(true)}
                            disabled={!internalLayoutValue}
                        >
                            Clonar Regras
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            size="small"
                            label="Filtro rápido - Nome ou valor"
                            autoFocus
                            fullWidth
                            variant="outlined"
                            value={filterNameAndValue}
                            onChange={(e) => setFilterNameAndValue(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <IconButton size="small" onClick={() => setFilterNameAndValue("")}>
                                        <DeleteIcon fontSize="inherit" />
                                    </IconButton>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grade dataSource={rulesDatasource} metaData={metaData} disablepagination={true} />
            </Grid>
            <CustomizationsRulesModalChange
                open={openRules}
                setOpen={setOpenRules}
                onChangeValue={(value) => setRule({ ...rule, rules: value })}
                value={rule.rules}
                types={types}
                typesRules={typesRules}
                typesRulesFilter={typesRulesFilter}
                title={rule.name}
            />
            <CustomizationsRulesModalClone
                open={openRulesClone}
                setOpen={setOpenRulesClone}
                onApplyOptions={(value) => onApplyRulesClone(value)}
                typeInternalLayout={internalLayoutValue}
            />
        </>
    );
}
