import React, { Component } from "react";

import ApiLocalidade from "./api-localidade";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    
});

class ListaCidades extends Component { 
    state = {
        data: [],
        value : {}
    }  

    componentDidMount() {
        this.loadData("SC");
    }

    componentDidUpdate(prevProps) {
        const { valueId, valueUF } = this.props;

        if ((valueId !== prevProps.valueId && valueId > 0) || 
            (valueUF !== prevProps.valueUF)) {
            this.setValue(valueUF, valueId, valueUF !== prevProps.valueUF);
        }
    }

    setValue = async (uf, id = 0, reloadUF = false) => {
        let valor = {};

        /* Recarrega a cidade */
        let cidades = [];

        if (reloadUF) {
            const response = await ApiLocalidade.GetCidades(uf);
            cidades = response.data;          
        } else {
            cidades = this.state.data;
        }

        /* Acha a cidade*/        
        for (let cidade in cidades) {
            if ((cidades[cidade].id_cidade === id) && (cidades[cidade].uf === uf)) {
                valor = cidades[cidade];
                break;
            }
        }

        /* Cidades inálida */
        if (!valor.id_cidade) {
            await this.props.onCidadeInvalida();
        }

        /* Grava a nova lista de cidade */
        if (reloadUF) {
            this.setState({
                value: valor,
                data: cidades,
            });
        } else {
            this.setState({
                value: valor,
            });
        }
    }

    loadData = async (uf) => {
        const response = await ApiLocalidade.GetCidades(uf);

        this.setState({
            data: response.data,
        });

        await this.setValue(uf);
    }

    render() {
        const { classes, ...other } = this.props;
        const { data, value } = this.state;

        return (
            <Autocomplete
                {...other}
                id="paises-select-demo"
                options={data}
                autoHighlight
                fullWidth
                getOptionLabel={(option) => option.nome}
                renderOption={(option) => (
                        <React.Fragment>
                            {option.nome} ({option.id_cidade})
                        </React.Fragment>
                    )}
                value={value}
                renderInput={(params) => (
                <TextField
                    {...params}
                    label="Cidade"
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                    }}
                />
                )}
            />
        )
    }
}

export default withStyles(styles)(ListaCidades);