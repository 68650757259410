import React from "react";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";

export default function InputSelectMultiple({ label, data, value, onChangeValue, disabled }) {
    return (
        <FormControl size="small" disabled={disabled ? true : false} fullWidth variant="outlined">
            <InputLabel id="demo-mutiple-chip-label" shrink>
                {label}
            </InputLabel>
            <Select
                labelId="demo-mutiple-chip-label"
                multiple
                value={value}
                onChange={(e) => onChangeValue(e.target.value)}
                renderValue={(selected) => (
                    <div>
                        {selected.map((value) => {
                            const select = data.find((rec) => rec.value === value);

                            if (!select) {
                                return <Chip key={value} label={`<${value}>`} size="small" />;
                            }

                            return <Chip key={value} label={select.text} size="small" />;
                        })}
                    </div>
                )}
            >
                {data.map((rec) => (
                    <MenuItem key={rec.value} value={rec.value} fullWidth>
                        <Checkbox checked={value.indexOf(rec.value) > -1} />
                        <ListItemText primary={rec.text} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
