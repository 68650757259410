import ApiDefault from "../../ApiDefault";

export default function ApiDocumentsOutbound() {
    const apiDefault = ApiDefault("v2/fiscal/documents");

    const managerDetails = async (id) => {
        return await apiDefault.api
            .get(`/${apiDefault.url}/manager-out/details/${id}`)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const managerList = async ({ page = 1, pageSize = 5, condition }) => {
        let urlCustom = `/${apiDefault.url}/manager-out/list?page=${page}&limit=${pageSize}`;

        for (let field in condition) {
            if (condition[field] !== "") {
                urlCustom += `&${field}=${condition[field]}`;
            }
        }

        return await apiDefault.api
            .get(urlCustom)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const cancel = async (documentId) => {
        return await apiDefault.api
            .put(`/${apiDefault.url}/cancel/${documentId}`)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                    message: response.data.message || [],
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    return {
        apiDefault,
        cancel,
        managerList,
        managerDetails,
    };
}
