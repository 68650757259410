import React, { useMemo } from "react";

import { FormControl, Select, InputLabel, MenuItem } from "@material-ui/core";

export default function InputDropDowList({ label, value, onChangeValue, defaultValue, children, modeGrid, disabled, allowNull, data, ...rest }) {
    const items = useMemo(() => {
        const list = [];

        if (allowNull) {
            list.push({ value: null, text: "-" });
        }

        list.push(...data);

        return list;
    }, [allowNull, data]);

    return (
        <FormControl variant={modeGrid ? "" : "outlined"} size="small" disabled={disabled ? true : false} fullWidth>
            {modeGrid ? (
                ""
            ) : (
                <InputLabel id="demo-simple-outlined-label" shrink>
                    {label}
                </InputLabel>
            )}
            <Select {...rest} defaultValue={defaultValue} labelId="demo-simple-outlined-label" value={value} onChange={onChangeValue}>
                {items.map((item) => {
                    return (
                        <MenuItem key={item.value} value={item.value}>
                            {item.text}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}
