import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { InputText } from "../../../../components";

import { Grid, Button, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { AppContext } from "../../../../App";

import { AppOptionsContext } from "../../../../components/main/main";
import ApiPaymentMethods from "../../../../api/financial/paymentMethods/ApiPaymentMethods";
import { TypePayment } from "../utils";
import { AttributesModal } from "../../../../components/attributes";
import { ValidatePaymentMethods } from "../validates";

const initialValues = {
    name: "",
    type_payment: "sight",
    attributes: {},
};

export default function PaymentMethodsChange({ id, onLoadRecord, onFinalize, showOptions, attributes }) {
    const { setLoading, showMessageError, showMessageInfo } = useContext(AppContext);
    const { setOptions } = useContext(AppOptionsContext);

    const [paymentMethod, setPaymentMethod] = useState(initialValues);
    const [openAttributes, setOpenAttributes] = useState(false);

    const paymentMethodAttributes = useMemo(() => {
        const attributesCustom = (attributes || []).find((rec) => rec.entity === "payment_methods" && rec.field === "attributes");

        if (attributesCustom) {
            return attributesCustom.values;
        }

        return [];
    }, [attributes]);

    useEffect(() => {
        loadRecord(id);
    }, [id]);

    const loadRecord = async (id) => {
        if (!id || id === 0) return;

        setLoading(true);

        try {
            const result = await ApiPaymentMethods().apiDefault.findId(id, {});

            if (!result.status) {
                showMessageError(result.message);
                return;
            }

            setPaymentMethod(result.data);

            onLoadRecord();
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onClearRecord = () => {
        setPaymentMethod(initialValues);

        if (id) {
            onFinalize(false);
        }
    };

    const onSave = useCallback(
        async (paymentMethod) => {
            const paymentMethodApply = {
                name: paymentMethod.name,
                type_payment: paymentMethod.type_payment,
                attributes: paymentMethod.attributes,
            };

            // Validate
            const validate = await ApiPaymentMethods().apiDefault.validate(ValidatePaymentMethods, paymentMethodApply);
            if (!validate.status) {
                showMessageError(validate.message);
                return;
            }

            setLoading(true);
            try {
                let response = {};

                if (id) {
                    response = await ApiPaymentMethods().apiDefault.update(id, paymentMethodApply);
                } else {
                    response = await ApiPaymentMethods().apiDefault.creates(paymentMethodApply);
                }

                if (!response.status) {
                    showMessageError(response.message);
                    return;
                }

                showMessageInfo("Registro atualizado com sucesso");

                onClearRecord();
                onFinalize(true);
            } finally {
                setLoading(false);
            }
        },
        [id]
    );

    useEffect(() => {
        if (showOptions) {
            setOptions(
                <>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => onClearRecord()}
                    >
                        {id ? "Cancelar" : "Limpar tela"}
                    </Button>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => onSave(paymentMethod)}
                    >
                        Gravar
                    </Button>
                </>
            );
        }
    }, [showOptions, paymentMethod, id]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                    <InputText
                        required
                        label="Nome"
                        value={paymentMethod.name}
                        onChangeValue={(e) => setPaymentMethod({ ...paymentMethod, name: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormControl variant="outlined" required size="small" fullWidth>
                        <InputLabel id="demo-simple-outlined-label" shrink={true}>
                            Tipo Pagamento
                        </InputLabel>
                        <Select
                            defaultValue={"sight"}
                            labelId="demo-simple-outlined-label"
                            id="demo-simple-outlined"
                            value={paymentMethod.type_payment}
                            onChange={(e) => setPaymentMethod({ ...paymentMethod, type_payment: e.target.value })}
                        >
                            {TypePayment.map((rec) => {
                                return <MenuItem value={rec.value}>{rec.text}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                <Grid item xs={12} sm={4}>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => setOpenAttributes(true)}
                    >
                        Parâmetros
                    </Button>
                </Grid>
            </Grid>
            <AttributesModal
                open={openAttributes}
                setOpen={(data) => setOpenAttributes(data)}
                attributes={paymentMethodAttributes}
                value={paymentMethod.attributes || {}}
                onChangeValue={(data) => setPaymentMethod({ ...paymentMethod, attributes: data })}
            />
        </>
    );
}
