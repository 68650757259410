import React, { useState, useEffect, useContext } from "react";

import { Grade, GradeOpcoes, InputFile, InputText } from "../../components";

import ApiService from "./ApiService";

import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getDateTimeJson } from "../../services/date.services";
import { AppContext } from "../../App";

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1, 1, 0, 0),
        float: "left"
    },
    buttonSelecao: {
        margin: theme.spacing(1, 0, 1, 1),
        float: "left"
    },
}));

const contaInitialValue = {
    status: "new",
    id_conta: "",
    nome: "",
    referencia: "",
    dh_desativacao: null,
}

export default function ManutencaoPlanosContas(props) {
    const { setLoading, setMessageError, setMessageInfo } = useContext(AppContext);

    const [ data, setData ] = useState(ApiService.defaultValues);
    const [ contas, setContas ] = useState([]);
    const [ contasView, setContasView ] = useState([]);
    const [ contasPage, setContasPage ] = useState(0);
    const [ contasPerPage, setContasPerPage ] = useState(10);
    const [ conta, setConta ] = useState(contaInitialValue);

    const classes = useStyles();
    const metaDataContas = [
        {label: "Opções", 
            render: (record) => {
                const opcoes = [ { tipo: "editar", label: "Editar", } ];
                if (["new", "edit"].includes(record.status)) {
                    opcoes.push({ tipo: "excluir", label: "Excluir", });
                } else {    
                    if (record.dh_desativacao) {
                        opcoes.push({ tipo: "ativar", label: "Ativar", });
                    } else {
                        opcoes.push({ tipo: "desativar", label: "Desativar", });
                    }
                }

                return (<span>
                            <GradeOpcoes 
                                record={record} 
                                onClickOpcoes={handleClickOpcoes} 
                                opcoes={opcoes}
                            />
                        </span>)
            }
        },
        {label: "Situação", align: "center",
            render: (record) => {
                return <span>{record.dh_desativacao ? "Inativo" : "Ativo"}</span>
            }
        },
        {label: "Conta", with: "50px", field: "id_conta", },
        {label: "Nome da conta", with: "50px", field: "nome", },
        {label: "Referencia", with: "50px", field: "referencia", },
    ]

    const handleClickOpcoes = async (record, tipo) => {
        const index = contas.findIndex(rec => rec.id_conta === record.id_conta);

        switch (tipo) {
            case "editar":
                setConta({ ...record });
                break;
            case "desativar":
                const contasCustom1 = [ ...contas];
                contasCustom1[index].dh_desativacao = getDateTimeJson();
                setContas(contasCustom1);
                break;
            case "ativar":
                const contasCustom2 = [ ...contas];
                contasCustom2[index].dh_desativacao = null;
                setContas(contasCustom2);
                break;
            case "excluir":
                const contasCustom3 = [ ...contas];
                contasCustom3.splice(index, 1);
                setContas(contasCustom3);
                refreshContasView(contasPage, contasPerPage, contasCustom3);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        loadRecord(props.id);
    }, [props.id]);

    const refreshContasView = (page, perPage, contas) => {
        setContasPerPage(perPage);
        setContasPage(page);

        const initial = (page === 0 ? 0 : (perPage * page));
        const length = (page === 0 ? perPage: (perPage * (page + 1)));

        setContasView(contas.slice(initial, length));
    }

    const loadRecord = async(id) => {
        if (!id || id === 0) return;

        setLoading(true);
        try {
            const response = await ApiService.GetId(id);
            if (!response.status) {
                setMessageError({ active: true, message: response.message });
                return;
            }

            setData(response.data);
            setContas(response.data.contas || []);
            refreshContasView(0, contasPerPage, response.data.contas);

            props.onLoadRecord();
        } finally {
            setLoading(false);
        }
    } 

    const saveRecord = async(data) => {
        const data_apply = { ...data };
        
        //Atributos
        data_apply.contas = contas.map(({status, ...rest}) => rest);

        const validate = await ApiService.ValidateData(ApiService.planosContasSchema, data_apply);
        if (!validate.status) {
            setMessageError({ active: true, message: validate.message });
            return;
        }

        setLoading(true);
        try {
            let response = {};

            if (props.id) {
                response = await ApiService.Update(props.id, data_apply);
            } else {
                response = await ApiService.Append(data_apply);
            }

            if (!response.status) {
                setMessageError({ active: true, message: response.message });
                return;
            }

            onClearRecord();
            props.onFinalize(true);
        } finally {
            setLoading(false);
        }
    }

    const onGravar = () => {
        saveRecord(data);
    }

    const onClearRecord = () => {
        setData(ApiService.defaultValues);
        setContas([]);
        setContasView([]);
        setContasPage(0);

        if (props.id) {
            props.onFinalize(false);
        }
    }

    const exportContas = async () => {
        setLoading(true);
        try {
            const response = await ApiService.export(contas, (data.nome || "novo plano de contas"));

            if (!response.status) {
                setMessageError({ active: true, message: response.message });
                return;
            }
        } finally {
            setLoading(false);
        }
    }

    const newConta = async () => {
        const contasCustom = [ ...contas ];

        if (conta.status === "new") {
            contasCustom.unshift({ ...conta, status: "edit" });
        } else {
            const contaCustom = contasCustom.find(rec => rec.id_conta === conta.id_conta);
            contaCustom.nome = conta.nome;
            contaCustom.referencia = conta.referencia;
        }

        setContas(contasCustom);
        refreshContasView(contasPage, contasPerPage, contasCustom);

        setConta(contaInitialValue);
    }

    const checkConta = async () => {
        const contaCustom = contas.find(rec => rec.id_conta === conta.id_conta);
        if (contaCustom) {
            setConta(contaCustom);
        }
    }
    
    const importContas = async (e) => {
        setLoading(true);
       
        try {
            const file = e.target.files[Object.keys(e.target.files)[0]];

            const form = new FormData();
            form.append("conta", file, "contas.xlsx");

            const response = await ApiService.import(form);

            if (!response.status) {
                setMessageError({ active: true, message: response.message });
                return;
            }

            const contasImport = response.data;
            const contasCustom = [ ...contas ];
            let contasCountNew = 0, contasCountRefresh = 0;

            for (const conta of contasImport) {
                const contaFind = contasCustom.find(rec => rec.id_conta === conta.id_conta);

                if (!contaFind) {
                    contasCustom.push({ ...conta, dh_desativacao: null, status: "edit", });
                    contasCountNew++
                } else {
                    contaFind.nome = conta.nome;
                    contaFind.referencia = conta.referencia;
                    contasCountRefresh++
                }
            }

            if (contasCountNew || contasCountRefresh) {
                setContas(contasCustom);
                refreshContasView(0, contasPerPage, contasCustom);
            }

            setMessageInfo({ active: true, message: ["Contas importadas com sucesso", `Novas: ${contasCountNew}`, `Atualizadas: ${contasCountRefresh}`] });
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={10}>
                    <InputText 
                        required 
                        className={classes.textUpperCase}
                        label="Nome do Plano de Contas" 
                        value={data.nome}                            
                        onChangeValue={e => setData({ ...data, nome: e.target.value })}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid item xs={12}>                    
                    <Button
                        size="small"
                        variant="contained"
                        className={classes.buttonSelecao}
                        color="default"
                        onClick={() => exportContas()}>Exportar contas
                    </Button>
                    <InputFile
                        className={classes.buttonSelecao}
                        color="default"      
                        onFileSelect={(e) => importContas(e)}
                        fileExtension={".xlsx"}
                        label={"Importar contas"}
                    />
                    <Button
                        size="small"
                        variant="contained"
                        className={classes.buttonSelecao}
                        color="secondary"
                        onClick={() => setConta(contaInitialValue)}>Novo registro
                    </Button>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={2}>
                        <InputText 
                            required
                            label="Conta"
                            disabled={!conta.status || conta.status === "edit"}
                            className={classes.textUpperCase}
                            value={conta.id_conta}
                            onChangeValue={e => setConta({ ...conta, id_conta: e.target.value })}
                            onBlur={e => checkConta()}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputText 
                            required 
                            label="Nome"
                            className={classes.textUpperCase} 
                            value={conta.nome}
                            onChangeValue={e => setConta({ ...conta, nome: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <InputText 
                            required 
                            label="Referência"
                            className={classes.textUpperCase} 
                            value={conta.referencia}
                            onChangeValue={e => setConta({ ...conta, referencia: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button
                            size="small"
                            variant="contained"
                            className={classes.buttonSelecao}
                            color="secondary"
                            onClick={() => newConta()}>
                            {conta.status === "new" ? "Adicionar" : "Atualizar"}
                        </Button>
                    </Grid>
                </Grid>
                <Grade 
                    dataSource={contasView} 
                    metaData={metaDataContas}
                    rowCount={contas.length}
                    rowPage={contasPerPage}
                    currentPage={contasPage}
                    onChangePage={(e, page) => refreshContasView(page, contasPerPage, contas)}
                    onChangeRowPage={(e) => refreshContasView(0, e.target.value, contas)}/>
            </Grid>
            <Grid item xs={12}>
                <Button
                    size="small"
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={() => onClearRecord()}>{props.id?"Cancelar":"Limpar tela"}
                </Button>
                <Button 
                    size="small"
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => onGravar()}>Gravar
                </Button>
            </Grid>
        </>
    )
}