export default function StringUtils() {
    const numberOnly = (value) => {
        return String(value).replace(/\D/g, "");
    };

    const clearWord = (value) => {
        return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const camelCase = (name) => {
        const result = name
            .split("_")
            .map((rec) => this.capitalize(rec))
            .join("");
        return result.charAt(0).toLowerCase() + result.slice(1);
    };

    const pascalCase = (name) => {
        return name
            .split("_")
            .map((rec) => this.capitalize(rec))
            .join("");
    };

    const initialWorks = (value, separator = " ") => {
        let result = value.substring(0, 1);

        let useNext = true;

        for (const idx in Array.from(value)) {
            if (useNext) {
                result += value[idx];
                useNext = false;
            }

            if (value[idx] === separator) {
                useNext = true;
            }
        }

        return result;
    };

    return {
        numberOnly,
        camelCase,
        clearWord,
        pascalCase,
        initialWorks,
    };
}
