import React, { Component } from "react";

import styles from "../../styles/consulta";

import usuariosService from "./usuarios.service";
import ManutencaoUsuarios from "./manutencao";

import { Grade, PageControl, TabSheet, MsgError } from "../../components";

import { Backdrop, CircularProgress, Grid, TextField, Select, InputLabel, MenuItem, Button, FormControl } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

class Usuarios extends Component {
    state = {
        tabIndex: 0,

        metaData: [
            { label: "Login", field: "login" },
            { label: "Nome", field: "nome" },
            {
                label: "Permite Login?",
                align: "center",
                render: (record) => {
                    return <span>{record.visivel && !record.dh_desativacao ? "Sim" : "Não"}</span>;
                },
            },
            {
                label: "Situação",
                align: "center",
                render: (record) => {
                    return <span>{record.dh_desativacao ? "Desativado" : "Ativo"}</span>;
                },
            },
            { label: "Dh.Registro", field: "dh_alt", format: "datetime" },
            {
                label: "Opções",
                render: (record) => {
                    return (
                        <span>
                            <Link onClick={() => this.onEditStatus(record)}>{record.dh_desativacao ? "Ativar" : "Inativar"}</Link>
                        </span>
                    );
                },
            },
        ],
        dataSource: [],
        rowPage: 5,
        currentPage: 0,
        rowCount: 0,
        filtro: {},

        error: false,
        errorMsg: "",
        loading: false,
    };

    componentDidMount() {
        this.loadData();
    }

    loadData = async (page = 0, pageSize = this.state.rowPage) => {
        this.setState({ loading: true });

        const { status, ...rest } = this.state.filtro;
        const filtros = { ...rest };

        //Filtro de status
        switch (this.state.filtro.status) {
            case 1:
                filtros["status"] = "ativo";
                break;
            case 2:
                filtros["status"] = "desativado";
                break;
            default:
                break;
        }

        const service = new usuariosService();
        const response = await service.GetAll(page + 1, filtros, pageSize, "nome,login");

        if (!response.status) {
            await this.onError(response.message);
            return;
        }

        this.setState({
            dataSource: response.data.rows,
            currentPage: page,
            rowPage: pageSize,
            rowCount: response.data.infoRows.count,
            loading: false,
        });
    };

    changeStatusUsuario = async (id, status) => {
        const service = new usuariosService();
        let response = {};

        // Valida
        if (!status) {
            response = await service.Remove(id);
        } else {
            response = await service.Update(id, { dh_desativacao: null });
        }

        // Reprocessa
        if (response.status) {
            this.loadData();
            this.setState({
                tabIndex: 0,
            });
        } else {
            this.setState({
                error: true,
                errorMsg: response.message,
            });
        }
    };

    onEditStatus(record) {
        this.changeStatusUsuario(record.id_usuario, record.dh_desativacao !== null);
    }

    onSearch = async () => {
        await this.loadData();
    };

    onChangePage = async (event, newPage) => {
        await this.loadData(newPage);
    };

    onChangeRowPage = async (event) => {
        await this.loadData(0, event.target.value);
    };

    onchangetab = (index) => {
        this.setState({ tabIndex: index });
    };

    onCloseError = async () => {
        this.setState({
            error: false,
            errorMsg: "",
        });
    };

    onError = async (messages) => {
        this.setState({
            error: true,
            errorMsg: messages,
            loading: false,
        });
    };

    onFinalize = async () => {
        this.setState({
            tabIndex: 0,
        });

        this.loadData();
    };

    render() {
        const { loading, error, errorMsg, dataSource, metaData, rowCount, rowPage, currentPage, filtro, tabIndex } = this.state;
        const { classes } = this.props;

        return (
            <>
                <PageControl tabindex={tabIndex} onchangetab={this.onchangetab}>
                    <TabSheet label="Consulta">
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={8}>
                                <TextField
                                    className={classes.textSearch}
                                    label="Pesquisa"
                                    size="small"
                                    autoFocus
                                    fullWidth
                                    variant="outlined"
                                    value={filtro.nome}
                                    onChange={(e) => this.setState({ filtro: { ...this.state.filtro, nome: e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <FormControl variant="outlined" className={classes.TextField} size="small">
                                    <InputLabel id="demo-simple-outlined-label">Situação</InputLabel>
                                    <Select
                                        defaultValue={0}
                                        labelId="demo-simple-outlined-label"
                                        value={filtro.status}
                                        onChange={(e) => this.setState({ filtro: { ...this.state.filtro, status: e.target.value } })}
                                    >
                                        <MenuItem value={0}>Todos</MenuItem>
                                        <MenuItem value={1}>Ativos</MenuItem>
                                        <MenuItem value={2}>Inativos</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Button variant="contained" size="small" className={classes.buttonSearch} type="primary" onClick={() => this.onSearch()}>
                                    Pesquisar
                                </Button>
                            </Grid>
                        </Grid>
                        <Grade
                            dataSource={dataSource}
                            metaData={metaData}
                            rowCount={rowCount}
                            rowPage={rowPage}
                            currentPage={currentPage}
                            onChangePage={this.onChangePage}
                            onChangeRowPage={this.onChangeRowPage}
                        />
                    </TabSheet>
                    <TabSheet label="Novo Usuário">
                        <ManutencaoUsuarios onError={this.onError} onFinalize={this.onFinalize} />
                    </TabSheet>
                </PageControl>
                <MsgError error={error} errorMsg={errorMsg} onclose={this.onCloseError} />
                <Backdrop className={classes.loading} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        );
    }
}

export default withStyles(styles)(Usuarios);
