import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import ApiTaxes from "../../../../api/fiscal/taxes/ApiTaxes";

import { InputText, InputDecimal, InputDropDowList2 as InputDropDowList } from "../../../../components";

import { Grid, Button, Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import { AppContext } from "../../../../App";

import { AppOptionsContext } from "../../../../components/main/main";
import { AttributesModal } from "../../../../components/attributes";
import { TypeCalculate } from "../utils";
import { Alert } from "@material-ui/lab";
import { ValidateTaxes } from "../validates";

const initialValues = {
    name: "",
    tax_fees: 0,
    type_tax_ref: null,
    type_calculate: "inside",
    attributes: {},
};

export default function ItemsChange({ id, onLoadRecord, onFinalize, showOptions, attributes, types }) {
    const { setLoading, showMessageError, showMessageInfo } = useContext(AppContext);
    const { setOptions } = useContext(AppOptionsContext);

    const [tax, setTax] = useState(initialValues);
    const [openAttributes, setOpenAttributes] = useState(false);

    useEffect(() => {
        loadRecord(id);
    }, [id]);

    const typeTax = useMemo(() => {
        return (
            types
                .find((rec) => rec.entity === "taxes" && rec.field === "type_tax_ref")
                ?.values?.map((rec) => {
                    return {
                        value: rec.value,
                        text: `${rec.name}`,
                    };
                }) || []
        );
    });

    const taxAttributes = useMemo(() => {
        const attributesCustom = (attributes || []).find((rec) => rec.entity === "taxes" && rec.field === "attributes");

        if (attributesCustom) {
            return attributesCustom.values;
        }

        return [];
    }, [attributes]);

    const loadRecord = async (id) => {
        if (!id || id === 0) return;

        setLoading(true);

        try {
            const result = await ApiTaxes().apiDefault.findId(id, {});

            if (!result.status) {
                showMessageError(result.message);
                return;
            }

            setTax(result.data);

            onLoadRecord();
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onClearRecord = () => {
        setTax(initialValues);

        if (id) {
            onFinalize(false);
        }
    };

    const onSave = useCallback(
        async (tax) => {
            const taxApply = {
                name: tax.name,
                tax_fees: tax.tax_fees,
                type_tax_ref: tax.type_tax_ref || null,
                type_calculate: tax.type_calculate,
                attributes: tax.attributes,
            };

            // Validate
            const validate = await ApiTaxes().apiDefault.validate(ValidateTaxes, taxApply);
            if (!validate.status) {
                showMessageError(validate.message);
                return;
            }

            setLoading(true);
            try {
                let response = {};

                if (id) {
                    response = await ApiTaxes().apiDefault.update(id, taxApply);
                } else {
                    response = await ApiTaxes().apiDefault.creates(taxApply);
                }

                if (!response.status) {
                    showMessageError(response.message);
                    return;
                }

                showMessageInfo("Registro atualizado com sucesso");

                onClearRecord();
                onFinalize(true);
            } finally {
                setLoading(false);
            }
        },
        [id]
    );

    useEffect(() => {
        if (showOptions) {
            setOptions(
                <>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => onClearRecord()}
                    >
                        {id ? "Cancelar" : "Limpar tela"}
                    </Button>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => onSave(tax)}
                    >
                        Gravar
                    </Button>
                </>
            );
        }
    }, [showOptions, tax, id]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={5}>
                    <InputText required label="Nome" value={tax.name} onChangeValue={(e) => setTax({ ...tax, name: e.target.value })} />
                </Grid>
                <Grid item xs={12} sm={1}>
                    <InputDecimal
                        label="Alíquota"
                        value={tax.tax_fees}
                        symbol="%"
                        decimal={6}
                        onChangeValue={(e) => setTax({ ...tax, tax_fees: e.target.valueNumber })}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Alert severity="warning" size="small">
                        Informe a alíquota ZERO caso o tributo possua variação de acordo com a aplicação.
                    </Alert>
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                <Grid item xs={12} sm={2}>
                    <InputDropDowList
                        allowNull
                        value={tax.type_tax_ref}
                        onChangeValue={(e) => setTax({ ...tax, type_tax_ref: e.target.value })}
                        data={typeTax}
                        label={"Tipo Tributo Referencia"}
                    ></InputDropDowList>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormControl variant="outlined" required size="small" fullWidth>
                        <InputLabel id="demo-simple-outlined-label" shrink={true}>
                            Tipo cálculo
                        </InputLabel>
                        <Select
                            defaultValue={"inside"}
                            labelId="demo-simple-outlined-label"
                            id="demo-simple-outlined"
                            value={tax.type_calculate}
                            onChange={(e) => setTax({ ...tax, type_calculate: e.target.value })}
                        >
                            {TypeCalculate.map((rec) => {
                                return <MenuItem value={rec.value}>{rec.text}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                <Grid item xs={12} sm={4}>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => setOpenAttributes(true)}
                    >
                        Parâmetros
                    </Button>
                </Grid>
            </Grid>
            <AttributesModal
                open={openAttributes}
                setOpen={(data) => setOpenAttributes(data)}
                attributes={taxAttributes}
                value={tax.attributes || {}}
                onChangeValue={(data) => setTax({ ...tax, attributes: data })}
            />
        </>
    );
}
