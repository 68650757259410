import React, { useState, useEffect, useMemo } from "react";

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, MenuItem } from "@material-ui/core";
import { InputDropDowList, InputFile } from "../../../../components";

import BackupIcon from "@material-ui/icons/Backup";

export default function OutboundOrdersReceiptImport({ layouts, open, setOpen, title, onExecute }) {
    const [layout, setLayout] = useState(layouts[0]?.name);

    useEffect(() => {
        setLayout(layouts[0]?.name);
    }, [layouts]);

    const layoutsSelected = useMemo(() => {
        return layouts.find((rec) => rec.name === layout);
    }, [layout, layouts]);

    const onLoadFiles = async (e) => {
        // Close window
        setOpen(false);

        const files = Array.from(e.target.files);
        const formData = new FormData();
        files.forEach((rec) => {
            formData.append(rec.name, rec, rec.name);
        });

        await onExecute(formData, layout);
    };

    return (
        <Dialog
            fullWidth
            maxWidth={"md"}
            open={open}
            onClose={(e, reason) => {
                if (!["backdropClick", "escapeKeyDown"].includes(reason)) {
                    setOpen(false);
                }
            }}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <InputDropDowList defaultValue={layouts[0]?.name} label="Leiaute" value={layout} onChangeValue={(e) => setLayout(e.target.value)}>
                            {layouts?.map((rec, index) => {
                                return (
                                    <MenuItem key={`leiaute${index}`} value={rec.name}>
                                        {rec.description}
                                    </MenuItem>
                                );
                            })}
                        </InputDropDowList>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={10}>
                        <InputFile
                            style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                            color="primary"
                            multiple={layoutsSelected?.multiple || false}
                            onFileSelect={(e) => onLoadFiles(e)}
                            fileExtension={layoutsSelected?.extensions || ".*"}
                            disabled={!layout}
                            label={"Importar Arquivos"}
                            startIcon={<BackupIcon fontSize="inherit" />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button
                            style={{ float: "right", marginRight: "15px" }}
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => setOpen(false)}
                        >
                            Fechar
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
