import api from "../../services/api";

class ApiLocalidade {
    static GetPaises = async() => {
        return await api.get("/paises/")
        .then(response => {
            return { 
                status: (response.status === 200),
                data: response.data.rows 
            };
        })
        .catch(error => {
            return { 
                status: false, 
                message: error.response.data.message 
            };
        });
    }

    static GetEstados = async() => {
        return await api.get("/estados/")
        .then(response => {
            return { 
                status: (response.status === 200),
                data: response.data.rows 
            };
        })
        .catch(error => {
            return { 
                status: false, 
                message: error.response.data.message 
            };
        });
    }

    static GetCidades = async(uf) => {
        return await api.get("/cidades/?uf=" + uf)
        .then(response => {
            return { 
                status: (response.status === 200),
                data: response.data.rows 
            };
        })
        .catch(error => {
            return { 
                status: false, 
                message: error.response.data.message 
            };
        });
    }
}

export default ApiLocalidade;