export const TypeNature = [
    {
        text: "Serviços",
        value: "services",
    },
    {
        text: "Mercadorias",
        value: "goods",
    },
    {
        text: "Financeiro",
        value: "financial",
    },
    {
        text: "Transportes",
        value: "transportation",
    },
];

export default function DocumentsTypesUtils() {
    const typeNatureText = (value) => {
        return TypeNature.find((rec) => rec.value === value)?.text || null;
    };

    return {
        typeNatureText,
    };
}
