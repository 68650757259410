import React, { useMemo, useState } from "react";

import { GradeOpcoes, InputDropDowList2 } from "../../../../components";
import { Grid, Button } from "@material-ui/core";

import Table from "../../../../components/grade/Table";
import QueryOperationsNature from "../../query/operationsNature";

const initialValues = {
    operation_nature_reference_id: 0,
    reference: {},
};

export default function OperationsNatureReferencesChange({ references, setReferences }) {
    const [reference, setReference] = useState(initialValues);

    const metaData = useMemo(() => [
        {
            label: "Opções",
            render: (record, index) => {
                const options = [
                    { tipo: "edit", label: "Edição" },
                    { tipo: "remove", label: "Exclusão" },
                ];

                return (
                    <span>
                        <GradeOpcoes record={record} indexRecord={index} onClickOpcoes={onClickOptions} opcoes={options} />
                    </span>
                );
            },
        },
        { label: "Nat.Ope.Ref", field: "operation_nature_reference_id", align: "right" },
        {
            label: "Nome",
            render: (record) => {
                return <>{record.reference?.name}</>;
            },
        },
        { label: "Dh.Criação", field: "created_at", format: "datetime", minWidth: 160 },
        { label: "Dh.Atualização", field: "updated_at", format: "datetime", minWidth: 160 },
    ]);

    const onClickOptions = async (record, type, index) => {
        switch (type) {
            case "edit":
                setReference({ ...record, priorityAnt: record.priority });
                break;
            case "remove":
                const referencesCustom = [...references];
                referencesCustom.splice(index, 1);

                setReferences(referencesCustom);
                break;
            default:
                break;
        }
    };

    return (
        <>
            <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <QueryOperationsNature
                            title="Consulta Natureza Operações"
                            label="Natureza Operação Ref."
                            value={reference.reference}
                            onChangeValue={(data) => setReference({ ...reference, reference: data })}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                    <Grid item xs={12} sm={8}>
                        <Button
                            style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => setReference(initialValues)}
                        >
                            Nova referencia
                        </Button>
                        <Button
                            style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                const referenceCustomIndex = references.findIndex(
                                    (rec) => rec.operation_nature_reference_id === reference.operation_nature_reference_id
                                );
                                const referenceCustom = [...references];

                                reference.operation_nature_reference_id = reference.reference.id;

                                if (referenceCustomIndex === -1) {
                                    referenceCustom.push(reference);
                                } else {
                                    referenceCustom[referenceCustomIndex] = reference;
                                }

                                setReferences(referenceCustom);
                                setReference(initialValues);
                            }}
                        >
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Table dataSource={references} metaData={metaData} />
            </Grid>
        </>
    );
}
