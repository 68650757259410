import React, { useState, useEffect, useMemo } from "react";

import ApiDocuments from "../../../../../api/fiscal/documents/ApiDocumentsService";
import { Grade, PageControl, TabSheet } from "../../../../../components";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Link } from "@material-ui/core";

export default function DocumentsServiceDetails({ id, open, setOpen, setLoading }) {
    const [tabIndex, setTabIndex] = useState(0);

    const [dataSourceItems, setDataSourceItems] = useState([]);
    const [dataSourceTaxes, setDataSourceTaxes] = useState([]);
    const [dataSourceInvoices, setDataSourceInvoices] = useState([]);
    const [dataSourceFiles, setDataSourceFiles] = useState([]);

    const metaDataItems = useMemo(() => [
        { label: "Código", field: "item_id" },
        { label: "Nome do item", field: "name", minWidth: 175 },
        { label: "Quantidade", field: "amount", format: "float", decimal: 3, align: "right" },
        { label: "Preço Unitário", field: "price_unit", format: "float", decimal: 2, align: "right" },
        { label: "Total", field: "total", format: "float", decimal: 2, align: "right" },
    ]);

    const metaDataTaxes = useMemo(() => [
        { label: "Código", field: "tax_id" },
        { label: "Nome do tributo", field: "name", minWidth: 175 },
        { label: "Alíquota", field: "tax_fees", format: "float", decimal: 2, align: "right" },
        { label: "Base de Cálculo", field: "base", format: "float", decimal: 2, align: "right" },
        { label: "Total", field: "total", format: "float", decimal: 2, align: "right" },
    ]);

    const metaDataInvoices = useMemo(() => [
        { label: "Código", field: "payment_method_id" },
        { label: "Nome do metodo de pagamento", field: "name", minWidth: 175 },
        { label: "Parcelas", field: "installment", align: "center" },
        { label: "Data vencimento", field: "date_due", format: "date", align: "center" },
        { label: "Total pagamento", field: "total_payment", format: "float", decimal: 2, align: "right" },
    ]);

    useEffect(() => {
        onLoadData();
    }, [id, open]);

    const onLoadData = async () => {
        if (!id || !open) {
            return;
        }

        setLoading(true);
        try {
            const result = await ApiDocuments().managerServiceDetails(id);

            if (result.status) {
                setDataSourceItems(result.data?.items || []);
                setDataSourceTaxes(result.data?.taxes || []);
                setDataSourceInvoices(result.data?.invoices || []);
                setDataSourceFiles(result.data?.files || []);
            } else {
                setDataSourceItems([]);
                setDataSourceTaxes([]);
                setDataSourceInvoices([]);
                setDataSourceFiles([]);
                setOpen(false);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog
            fullWidth
            maxWidth={"md"}
            open={open}
            onClose={(e, reason) => {
                if (!["backdropClick", "escapeKeyDown"].includes(reason)) {
                    setOpen(false);
                }
            }}
        >
            <DialogTitle>Detalhes - Registro {id}</DialogTitle>
            <DialogContent>
                <PageControl tabindex={tabIndex} onchangetab={(index) => setTabIndex(index)}>
                    <TabSheet label="Itens">
                        <Grade dataSource={dataSourceItems} metaData={metaDataItems} disablepagination />
                    </TabSheet>
                    <TabSheet label="Tributos">
                        <Grade dataSource={dataSourceTaxes} metaData={metaDataTaxes} disablepagination />
                    </TabSheet>
                    <TabSheet label="Faturas">
                        <Grade dataSource={dataSourceInvoices} metaData={metaDataInvoices} disablepagination />
                    </TabSheet>
                </PageControl>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={10}>
                        {dataSourceFiles.map((rec, index) => {
                            let name = "Download";

                            switch (rec.type_file) {
                                case "link-pdf":
                                    name = "Donwload PDF";
                                    break;
                                default:
                                    break;
                            }

                            return (
                                <Link target="_blank" style={{ float: "left", marginLeft: "15px" }} href={rec.content}>
                                    {name}
                                </Link>
                            );
                        })}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button style={{ float: "right", marginRight: "15px" }} size="small" variant="contained" color="primary" onClick={() => setOpen(false)}>
                            Fechar
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
