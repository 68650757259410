import apiForms from "../../../../services/api-forms";
import api from "../../../../services/api";
import fileDownload from "js-file-download";

class ApiService extends apiForms {
    constructor() {
        super("");
    }

    importFiles = async (idEstabelecimento, dataLancamento, files) => {
        return await api.post(`/ferramentas/importadores/consorcios/${idEstabelecimento}/${dataLancamento}`, 
                files, { 
                    headers: {  
                        "Content-Type": "multipart/form-data"
                    } 
                })
            .then(response => {
                return { 
                    status: (response.status === 200),
                    data: response.data 
                };
            })
            .catch(error => {
                return { 
                    status: false,
                    message: (error.response && error.response.data ? error.response.data.message : error),
                };
            });  
    }

    exportInconsistencias = async (data) => {
        return await api.post(`/ferramentas/exportadores/consorcios/inconsistencias`, data, { responseType: "blob", })
            .then(response => {
                fileDownload(response.data, `InconsistenciasConsorcios.xlsx`);

                return { 
                    status: (response.status === 200),
                    data: []
                }; 
            })
            .catch(error => {
                return { 
                    status: false, 
                    message: error.response.data.message 
                };
            });
    }

    export = async (data, fileName) => {
        return await api.post(`/ferramentas/exportadores/consorcios/dominio`, data, { responseType: "blob", })
            .then(response => {
                fileDownload(response.data, fileName + ".txt");

                return { 
                    status: (response.status === 200),
                    data: []
                }; 
            })
            .catch(error => {
                return { 
                    status: false, 
                    message: error.response.data.message 
                };
            });
    }
}

export default new ApiService();