import apiForms from "../../services/api-forms";
import api from "../../services/api";
import * as yup from "yup";

class UsuariosService extends apiForms {
    static recuperarSenhaSchema = yup.object().shape({
        usuario: yup.string().required("Usuário é obrigatório").email("Usuário deve ser um e-mail"),
    });

    static definirSenhaSchema = yup.object().shape({
        usuario: yup.string().required("Usuário é obrigatório").email("Usuário deve ser um e-mail"),
        senha: yup.string().required("Senha é obrigatória"),
        senha_confirmacao: yup
            .string()
            .required("Confirmação de senha é obrigatória")
            .when("senha", (senha, schema) => {
                return schema.matches(senha, "Confirmação de senha inválida");
            }),
    });

    static alterarSenhaSchema = yup.object().shape({
        senha_antiga: yup.string().required("Senha atual é obrigatória"),
        senha: yup.string().required("Senha é obrigatória"),
        senha_confirmacao: yup
            .string()
            .required("Confirmação de senha é obrigatória")
            .when("senha", (senha, schema) => {
                return schema.matches(senha, "Confirmação de senha inválida");
            }),
    });

    static usuarioSchema = yup.object().shape({
        nome: yup.string().required("Nome é obrigatório"),
        login: yup.string().required("Usuário é obrigatório").email("Usuário deve ser um e-mail"),
    });

    constructor() {
        super("usuarios");
    }

    ChangePassword = async (data) => {
        return await api
            .post("/" + this.urlApi + "/alterarsenha/", data)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    ValidateRecovery = async (chave) => {
        return await api
            .get("/" + this.urlApi + "/recuperar/" + chave)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    ChangePasswordRecovery = async (chave, data) => {
        return await api
            .put("/" + this.urlApi + "/recuperar/" + chave, data)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    Recovery = async (login) => {
        return await api
            .post("/" + this.urlApi + "/recuperar/" + login, {})
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };
}

export default UsuariosService;
