import * as yup from "yup";
import { TypeIssuerItemValues, TypeStocksKeeperValues } from "../utils";

export function ValidateOperationsNature(listTypeStock) {
    return yup.object().shape({
        name: yup.string().required("Nome é obrigatório").min(3, "Nome deve ter mais que três caracteres"),
        stock_keeper: yup
            .string()
            .nullable()
            .oneOf([null, ...TypeStocksKeeperValues], "Tipo de posse/propriedade inválido"),
        type_stock: yup
            .string()
            .nullable()
            .oneOf([null, ...listTypeStock], "Tipo de estoque inválido"),
        type_issuer_item: yup.string().required("Tipo de regra de item é obrigatória").oneOf(TypeIssuerItemValues, "Tipo de regra de item inválida"),
        control_third_stock: yup.boolean().required("Indicativo de controle de estoque em terceira pessoa é obrigatório"),
        order_type_id: yup.string().required("Tipo de ordem é obrigatório"),
    });
}
