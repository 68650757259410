import React, { useState, useEffect, useContext, useMemo } from "react";

import { AppContext } from "../../../App";

import { Grade, GradeOpcoes, PageControl, TabSheet, InputText, InputSelector } from "../../../components";
import { Button, Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import { AppOptionsContext } from "../../../components/main/main";
import ApiTypes from "../../../api/system/types/ApiTypes";
import ApiCustomizations from "../../../api/system/customizations/ApiCustomizations";
import CustomizationsChange from "./change";
import InputDropDowList from "../../../components/inputs/input.dropdowlist2";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import QueryMembers from "../../basics/query/members";
import TablePagination from "../../../components/grade/TablePagination";

const initialCondition = {
    name: "",
    internal_layout: "-",
    branch: {},
    inactivate: false,
};

export default function Customizations() {
    const { setLoading, showMessageError } = useContext(AppContext);
    const { setOptions, setTitle } = useContext(AppOptionsContext);

    const [tabIndex, setTabIndex] = useState(0);
    const [dataSource, setDataSource] = useState([]);

    const [rowCount, setRowCount] = useState(0);
    const [rowPage, setRowPage] = useState(10);
    const [page, setPage] = useState(0);

    const [condition, setCondition] = useState(initialCondition);
    const [conditionApply, setConditionApply] = useState(initialCondition);

    const [idInEdition, setIdInEdition] = useState(0);

    const [attributesCustomization, setAttributesCustomization] = useState([]);
    const [typesCustomization, setTypesCustomization] = useState([]);

    const typesInternalLayout = useMemo(() => {
        if (!typesCustomization?.length) {
            return [];
        }

        return typesCustomization
            .find((rec) => rec.entity === "customizations" && rec.field === "internal_layout")
            .values.map((rec) => {
                return {
                    text: rec.description,
                    value: rec.name,
                };
            });
    }, [typesCustomization]);

    const typesInternalLayoutFilter = useMemo(() => {
        const result = [...typesInternalLayout];
        result.unshift({
            text: "Todos",
            value: "-",
        });

        return result;
    }, [typesInternalLayout]);

    const metaData = useMemo(
        () => [
            {
                label: "Opções",
                render: (record) => {
                    const options = [
                        { tipo: "edit", label: "Edição" },
                        { tipo: "inactive", label: "Inativação" },
                    ];

                    return (
                        <span>
                            <GradeOpcoes record={record} onClickOpcoes={onClickOptions} opcoes={options} />
                        </span>
                    );
                },
            },
            { label: "Código", field: "id", align: "right" },
            { label: "Nome", field: "name" },
            {
                label: "Leiaute interno",
                render: (record) => {
                    let value = "NDA";

                    if (typesInternalLayout?.length) {
                        value = typesInternalLayout.find((rec) => rec.value === record.internal_layout).text;
                    }

                    return <span>{value}</span>;
                },
            },
            {
                label: "Qtd.Estab.",
                align: "center",
                render: (record) => {
                    return <span>{record.branchs?.length || 0}</span>;
                },
            },
            { label: "Dh.Criação", field: "created_at", format: "datetime", minWidth: 160 },
            { label: "Dh.Atualização", field: "updated_at", format: "datetime", minWidth: 160 },
        ],
        [dataSource, typesInternalLayout]
    );

    useEffect(() => {
        setTitle("Customizações");
        loadDataSource(0);

        // Types
        ApiTypes()
            .apiDefault.findAll({ entity_in: "customizations,customizations_rules,members,documents" })
            .then((rec) => {
                if (rec.status) {
                    setTypesCustomization(rec.data);
                }
            });

        return () => {
            setOptions(null);
        };
    }, []);

    const loadDataSource = async (newPage, newRowPage, newCondition) => {
        setLoading(true);

        // Condition
        const conditionCustom = makeCondition(newCondition || conditionApply);

        try {
            const result = await ApiCustomizations().apiDefault.find({
                page: newPage + 1,
                pageSize: newRowPage || rowPage,
                condition: conditionCustom,
                associates: ["branchs"],
                orderBy: ["name"],
            });

            if (result.status) {
                setDataSource(result.data);
                setRowCount(result.info.total);
            } else {
                setDataSource([]);
                if (result.message) {
                    showMessageError(result.message);
                }
            }
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const makeCondition = (newCondition) => {
        const result = {};

        // Branch
        if (newCondition.name) {
            result.name_like = encodeURIComponent(newCondition.name + "+");
        }

        // Branch
        if (newCondition.branch?.id) {
            result["branchs.branch_id"] = newCondition.branch?.id;
        }

        // Internal layout
        if (newCondition.internal_layout !== "-") {
            result.internal_layout = newCondition.internal_layout;
        }

        if (!newCondition.inactivate) {
            result.inactivate_at_is_null = true;
        }

        return result;
    };

    const onClickOptions = (record, tipo) => {
        switch (tipo) {
            case "edit":
                setIdInEdition(record.id);
                break;
            case "inactive":
                break;
            default:
                break;
        }
    };

    const onFinalize = async (updated) => {
        setTabIndex(0);
        setIdInEdition(0);

        if (updated) {
            loadDataSource(page);
        }
    };

    useEffect(() => {
        if (tabIndex === 0) {
            setOptions(
                <>
                    <TablePagination
                        rowCount={rowCount}
                        rowPage={rowPage}
                        page={page}
                        onPageChange={(event, newPage) => {
                            loadDataSource(newPage);
                            setPage(newPage);
                        }}
                        onRowsPerPageChange={(event) => {
                            loadDataSource(0, event.target.value);
                            setPage(0);
                            setRowPage(event.target.value);
                        }}
                    />
                </>
            );
        }
    }, [tabIndex, rowCount, rowPage, page]);

    return (
        <>
            <PageControl tabindex={tabIndex} onchangetab={(index) => setTabIndex(index)}>
                <TabSheet label="Consulta">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={3}>
                            <InputText
                                label="Nome ou parte do nome do tributo"
                                size="small"
                                autoFocus
                                fullWidth
                                variant="outlined"
                                value={condition.name}
                                onChangeValue={(e) => setCondition({ ...condition, name: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <QueryMembers
                                title="Consulta Estabelecimentos"
                                roleMember="branch"
                                label="Estabelecimento"
                                value={condition.branch}
                                onChangeValue={(data) => setCondition({ ...condition, branch: data })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <InputDropDowList
                                value={condition.internal_layout}
                                onChangeValue={(e) => setCondition({ ...condition, internal_layout: e.target.value })}
                                data={typesInternalLayoutFilter}
                                label={"Leaiute Interno"}
                            ></InputDropDowList>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputSelector
                                label={"Exibir inativos"}
                                value={condition.inactivate || false}
                                onChangeValue={(e) => setCondition({ ...condition, inactivate: e.target.checked })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Button
                                style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                                variant="contained"
                                size="small"
                                type="primary"
                                onClick={() => {
                                    loadDataSource(0, rowPage, condition);
                                    setConditionApply(condition);
                                }}
                                startIcon={<SearchIcon fontSize="inherit" />}
                            >
                                Pesquisar
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grade dataSource={dataSource} metaData={metaData} disablepagination />
                    </Grid>
                </TabSheet>
                <TabSheet label={idInEdition ? `Customização ${idInEdition}` : null} icon={idInEdition ? null : <AddCircleIcon />}>
                    <Grid container spacing={1}>
                        <CustomizationsChange
                            id={idInEdition}
                            onLoadRecord={() => setTabIndex(1)}
                            onFinalize={(updated) => onFinalize(updated)}
                            types={typesCustomization}
                            attributes={attributesCustomization}
                            showOptions={tabIndex === 1}
                            typesInternalLayout={typesInternalLayout}
                        />
                    </Grid>
                </TabSheet>
            </PageControl>
        </>
    );
}
