import * as yup from "yup";

export function ValidateOutboundOrders(list) {
    const validateItems = yup.object().shape({
        item_id: yup.number().required("Item não informado."),
        operation_nature_id: yup.string().required("Natureza de Operação não informada."),
        amount: yup.number().required("Quantidade do item não informada.").moreThan(0, "Quantidade deve ser maior que zero."),
        price_unit: yup.number().required("Preço do item não informada.").moreThan(0, "Preço deve ser maior que zero."),
        total: yup.number().required("Valor total do item não informada.").moreThan(0, "Valor total deve ser maior que zero."),
    });

    return yup.object().shape({
        branch_id: yup.number().required("Estabelecimento não informado."),
        receiver_id: yup.number().required("Destinatário não informado."),
        order_type_id: yup.number().required("Tipo de orden não informada."),
        issue_date: yup.date().required("Data de emissão da ordem não informada."),
        total: yup.number().required("Valor da ordem não informada.").moreThan(0, "Valor total da ordem não pode ser zero."),
        items: yup.array().required("Itens não informados").min(1, "Nenhum item informado").of(validateItems),
    });
}
