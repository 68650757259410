import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

function MaskFone(props) {
    const { inputRef, ...other } = props;
    const masks = {
        foneCelular: ['(', /[0-9]/, /[0-9]/, ')', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,'-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
        foneConvencional: ['(', /[0-9]/, /[0-9]/, ')', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,'-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
    };

    const defineMask = function(rawValue){
        let valor = rawValue
                        .replace("(", "")
                        .replace(")", "")
                        .replace("-", "").trim();

        if (valor.length <= 10) {
            return masks.foneConvencional;
        } else {
            return masks.foneCelular;
        }
    }

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={defineMask}
            placeholderChar={'\u2000'}
            showMask={false}
        />
    );
}
  
MaskFone.propTypes = {
    inputRef: PropTypes.func.isRequired
};

export default MaskFone;