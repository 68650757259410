import React, { Component } from "react";

import usuariosService from "../usuarios/usuarios.service";

import { MsgError } from "../../components";
import styles from "../../styles/manutencao";

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

class AlterarSenha extends Component {
    state = {
        data: {},
        error: false,
        errorMsg: "",
    }

    saveRecord = async(data) => {
        const service = new usuariosService();
        const validate = await service.ValidateData(usuariosService.alterarSenhaSchema, this.state.data);

        if (!validate.status) {
            this.setState({ 
                error: true,
                errorMsg: validate.message,
            });
            return;
        }

        /* Validação da senha atual */
        const response = await service.ChangePassword(data);

        if (!response.status) {
            this.setState({ 
                error: true,
                errorMsg: response.message,
            });
            return;
        }

        this.onClearRecord();
    }

    onAlterarSenha() {
        this.saveRecord(this.state.data);
    }

    onClearRecord() {
        this.setState({ 
            data: {
                senha_antiga: "",
                senha: "",
                senha_confirmacao:"",
            }
        });
    }

    onCloseError = async () => {
        this.setState({ 
            error: false, 
            errorMsg: "",
        });
    }

    render() {
        const { data, error, errorMsg } = this.state;
        const { classes } = this.props;

        return (
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            required
                            size="small"
                            label="Senha Atual"
                            type="password"
                            fullWidth
                            variant="outlined"
                            value={data.senha_antiga}                            
                            onChange={e => this.setState({ data: { ...this.state.data, senha_antiga: e.target.value }})}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            required
                            size="small"
                            label="Nova Senha"
                            name="senha"
                            type="password"
                            fullWidth
                            variant="outlined"
                            value={data.senha}                            
                            onChange={e => this.setState({ data: { ...this.state.data, senha: e.target.value }})}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            required
                            size="small"
                            label="Confirmação da Nova Senha"
                            type="password"
                            fullWidth
                            variant="outlined"
                            value={data.senha_confirmacao}                            
                            onChange={e => this.setState({ data: { ...this.state.data, senha_confirmacao: e.target.value }})}
                        />
                    </Grid>                        
                </Grid>
                <Grid item xs={12}>
                    <Button
                        size="small"
                        className={classes.buttonClear}
                        variant="contained"
                        color="secondary"
                        onClick={() => this.onClearRecord()}>Limpar
                    </Button>
                    <Button 
                        size="small"
                        className={classes.buttonApply}
                        variant="contained"
                        color="primary"
                        onClick={() => this.onAlterarSenha()}>Gravar
                    </Button>
                </Grid>
                <MsgError error={error} errorMsg={errorMsg} onclose={this.onCloseError}/>
            </div>       
        )
    }
}

export default withStyles(styles)(AlterarSenha);