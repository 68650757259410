import fileDownload from "js-file-download";

import ApiDefault from "../../ApiDefault";

export default function ApiDocumentsInbound() {
    const apiDefault = ApiDefault("v2/fiscal/documents");

    const generateInboundOrdersAndMovementsItems = async (condition) => {
        return await apiDefault.api
            .post(`/${apiDefault.url}/manager-in/generate-inbound-orders`, condition)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                    message: response.data.message || [],
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const managerDetails = async (id) => {
        return await apiDefault.api
            .get(`/${apiDefault.url}/manager-in/details/${id}`)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const managerList = async ({ page = 1, pageSize = 5, condition }) => {
        let urlCustom = `/${apiDefault.url}/manager-in/list?page=${page}&limit=${pageSize}`;

        for (let field in condition) {
            if (condition[field] !== "") {
                urlCustom += `&${field}=${condition[field]}`;
            }
        }

        return await apiDefault.api
            .get(urlCustom)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const receipt = async (files, layout, documentTypeDefault) => {
        return await apiDefault.api
            .post(`/${apiDefault.url}/receipt-in/${layout}/${documentTypeDefault}`, files, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                    message: response.data.message || [],
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const defineItemsAndTaxes = async (condition) => {
        return await apiDefault.api
            .post(`/${apiDefault.url}/receipt-in/define-items-taxes`, condition)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                    message: response.data.message || [],
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const exportDocuments = async (condition, layin, fileName) => {
        let urlCustom = `/${apiDefault.url}/manager-in/export-file/${layin}`;
        const urlConditions = [];

        for (let field in condition) {
            if (condition[field] !== "") {
                urlConditions.push(`${field}=${condition[field]}`);
            }
        }

        if (urlConditions.length) {
            urlCustom += `?${urlConditions.join("&")}`;
        }

        return await apiDefault.api
            .get(urlCustom)
            .then((response) => {
                fileDownload(response.data, fileName + ".txt");

                return {
                    status: response.status === 200,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const cancel = async (documentId) => {
        return await apiDefault.api
            .put(`/${apiDefault.url}/cancel/${documentId}`)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                    message: response.data.message || [],
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    return {
        apiDefault,
        cancel,
        defineItemsAndTaxes,
        exportDocuments,
        generateInboundOrdersAndMovementsItems,
        managerList,
        managerDetails,
        receipt,
    };
}
