import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { PageControl, TabSheet, Grade, GradeOpcoes, InputDate, InputDecimal, InputText } from "../../../../components";

import { Grid, Button, Checkbox } from "@material-ui/core";
import { AppContext } from "../../../../App";

import FormatsUtils from "../../../../services/FormatsUtils";
import QueryMembers from "../../../basics/query/members";
import QueryItems from "../../../basics/query/items";

import { AppOptionsContext } from "../../../../components/main/main";
import QueryOrdersTypes from "../../../basics/query/ordersTypes";
import QueryOperationsNature from "../../../basics/query/operationsNature";
import ApiOutboundOrders from "../../../../api/stocks/outboundOrders/ApiOutboundOrders";
import { ValidateOutboundOrders } from "../validates";

const initialValues = {
    branch_id: 0,
    branch: {},
    receiver_id: 0,
    receiver: {},
    order_type_id: 0,
    order_type: {},
    issue_date: new Date(),
    total: 0.0,
    status: "pending",
};

const initialValuesItems = {
    item_seq: -1,
    item_id: 0,
    item: {},
    operation_nature_id: 0,
    operation_nature: {},
    amount: 1.0,
    price_unit: 0.0,
    total: 0.0,
    purchase_number: "",
    purchase_number_item: "",
};

export default function OutboundOrdersChange({ id, onLoadRecord, onFinalize, showOptions, dataManual }) {
    const { setLoading, showMessageError, showMessageInfo } = useContext(AppContext);
    const { setOptions } = useContext(AppOptionsContext);

    const [tabIndex, setTabIndex] = useState(0);

    const [outboundOrder, setOutboundOrder] = useState(initialValues);

    const [outboundOrderItems, setOutboundOrderItems] = useState([]);
    const [outboundOrderItem, setOutboundOrderItem] = useState(initialValuesItems);

    const metaDataItems = useMemo(() => [
        {
            label: "Opções",
            render: (record, index) => {
                const options = [
                    { tipo: "edit", label: "Edição" },
                    { tipo: "remove", label: "Exclusão" },
                ];

                return (
                    <span>
                        <GradeOpcoes record={record} indexRecord={index} onClickOpcoes={onClickOptionsItems} opcoes={options} />
                    </span>
                );
            },
        },
        { label: "#", field: "item_seq", align: "right" },
        { label: "Nat.Operação", field: "operation_nature_id" },
        {
            label: "Estoque",
            render: (record) => {
                return <Checkbox checked={record.operation_nature.stock_keeper !== null} />;
            },
        },
        {
            label: "Ref.",
            render: (record) => {
                return <Checkbox checked={record.operation_nature.is_references} />;
            },
        },
        {
            label: "Código",
            align: "right",
            render: (record) => {
                return <>{record.item_id || "?????"}</>;
            },
        },
        {
            label: "Nome do item",
            render: (record) => {
                return <>{record.item?.name}</>;
            },
        },
        { label: "Quantidade", field: "amount", format: "float", decimal: 3, align: "right" },
        {
            label: "UN",
            render: (record) => {
                return (
                    <>
                        {record.item?.unit_inventory_id}-{FormatsUtils().float(record.item?.amount_unit_inventory, 0)}
                    </>
                );
            },
        },
        { label: "Preço Unitário", field: "price_unit", format: "float", decimal: 2, align: "right" },
        { label: "Total", field: "total", format: "float", decimal: 2, align: "right" },
        { label: "Pedido", field: "purchase_number" },
        { label: "Pedido Item", field: "purchase_number_item" },
    ]);

    useEffect(() => {
        loadRecord(id);
    }, [id]);

    useEffect(() => {
        if (dataManual.status === "pending") {
            setOutboundOrder({
                ...dataManual,
                issue_date: new Date(dataManual.issue_date),
            });
            setOutboundOrderItems([...(dataManual.items || [])]);
            onLoadRecord();
        }
    }, [dataManual?.status]);

    const onClickOptionsItems = async (record, type, index) => {
        switch (type) {
            case "edit":
                setOutboundOrderItem({ ...record });
                break;
            case "remove":
                const outboundOrderItemsCustom = [...outboundOrderItems];
                outboundOrderItemsCustom.splice(index, 1);
                setOutboundOrderItems(outboundOrderItemsCustom);
                break;
            default:
                break;
        }
    };

    const loadRecord = async (id) => {
        if (!id || id === 0) return;

        setLoading(true);
        try {
            const result = await ApiOutboundOrders().apiDefault.findId(id, {
                associates: ["items", "branch", "receiver", "order_type", "items.item", "items.operation_nature"],
            });

            if (!result.status) {
                showMessageError(result.message);
                return;
            }

            setOutboundOrder({
                ...result.data,
                issue_date: new Date(result.data.issue_date),
            });
            setOutboundOrderItems(result.data.items || []);

            onLoadRecord();
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onClearRecord = () => {
        setOutboundOrder(initialValues);
        setOutboundOrderItems([]);
        setOutboundOrderItem(initialValuesItems);

        if (id || dataManual.status === "pending") {
            onFinalize(false);
        }
    };

    const onSave = useCallback(
        async (outboundOrder, outboundOrderItems, finalizeEdition) => {
            const outboundOrderApply = {
                branch_id: outboundOrder.branch.id,
                receiver_id: outboundOrder.receiver.id,
                order_type_id: outboundOrder.order_type.id,
                issue_date: FormatsUtils().dateAnsi(outboundOrder.issue_date),
                total: outboundOrder.total,
                status: outboundOrder.status,
                process_file_id: outboundOrder.process_file_id,
                items: [],
            };

            // Items
            outboundOrderApply.items = (outboundOrderItems || []).map((rec) => {
                return {
                    item_seq: rec.item_seq,
                    item_id: rec.item.id,
                    operation_nature_id: rec.operation_nature.id,
                    amount: rec.amount,
                    amount_reference: rec.operation_nature.is_references ? 0 : null,
                    amount_delivery: 0,
                    price_unit: rec.price_unit,
                    total: rec.total,
                    purchase_number: rec.purchase_number || null,
                    purchase_number_item: rec.purchase_number_item || null,
                };
            });

            if (finalizeEdition) {
                outboundOrderApply.status = "active";
            }

            // Validate
            const validate = await ApiOutboundOrders().apiDefault.validate(ValidateOutboundOrders(), outboundOrderApply);
            if (!validate.status) {
                showMessageError(validate.message);
                return;
            }

            setLoading(true);
            try {
                let response = {};

                if (id) {
                    response = await ApiOutboundOrders().apiDefault.update(id, outboundOrderApply);
                } else {
                    response = await ApiOutboundOrders().apiDefault.creates(outboundOrderApply);
                }

                if (!response.status) {
                    showMessageError(response.message);
                    return;
                }

                showMessageInfo("Registro atualizado com sucesso");

                onClearRecord();
                onFinalize(true);
            } finally {
                setLoading(false);
            }
        },
        [id]
    );

    useEffect(() => {
        if (showOptions) {
            setOptions(
                <>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => onClearRecord()}
                    >
                        {id ? "Cancelar" : "Limpar tela"}
                    </Button>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => onSave(outboundOrder, outboundOrderItems, false)}
                    >
                        Gravar
                    </Button>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => onSave(outboundOrder, outboundOrderItems, true)}
                    >
                        Gravar e Finalizar Edição
                    </Button>
                </>
            );
        }
    }, [showOptions, outboundOrder, outboundOrderItems, id]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <QueryMembers
                        disabled={!!id}
                        title="Consulta Estabelecimento"
                        roleMember={"branch"}
                        label="Estabelecimento"
                        value={outboundOrder.branch}
                        onChangeValue={(data) => setOutboundOrder({ ...outboundOrder, branc: data })}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <QueryMembers
                        title="Consulta Destinatários"
                        roleMember={"customer,supplier"}
                        label="Destinatário"
                        value={outboundOrder.receiver}
                        onChangeValue={(data) => setOutboundOrder({ ...outboundOrder, receiver: data })}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <QueryOrdersTypes
                        title="Consulta Tipos de Ordens"
                        label="Tipo de Ordem"
                        value={outboundOrder.order_type}
                        onChangeValue={(data) => setOutboundOrder({ ...outboundOrder, order_type: data })}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <InputDate
                        label="Data Ordem"
                        value={outboundOrder.issue_date}
                        onChangeValue={(date) => setOutboundOrder({ ...outboundOrder, issue_date: date })}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <InputDecimal
                        label={"Valor Ordem"}
                        value={outboundOrder.total}
                        symbol="R$"
                        decimal={2}
                        disabled={true}
                        onChangeValue={(e) => setOutboundOrder({ ...outboundOrder, total: e.target.valueNumber })}
                    />
                </Grid>
            </Grid>
            <PageControl style={{ marginTop: "10px" }} tabindex={tabIndex} onchangetab={(index) => setTabIndex(index)}>
                <TabSheet label="Itens">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <QueryOperationsNature
                                title={`Consulta Natureza de Operação`}
                                label={"Natureza Operação"}
                                orderType={outboundOrder.order_type?.id}
                                value={outboundOrderItem.operation_nature}
                                onChangeValue={(data) => setOutboundOrderItem({ ...outboundOrderItem, operation_nature: data })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <QueryItems
                                title={`Consulta de Mercadorias`}
                                typeItem="goods"
                                label={"Item"}
                                value={outboundOrderItem.item}
                                disabled={!outboundOrderItem.operation_nature?.id}
                                onChangeValue={(data) => setOutboundOrderItem({ ...outboundOrderItem, item: data })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                        <Grid item xs={12} sm={2}>
                            <InputDecimal
                                label={"Quantidade"}
                                value={outboundOrderItem.amount}
                                symbolEnds={
                                    outboundOrderItem?.item?.id
                                        ? `${outboundOrderItem?.item.unit_inventory_id}${outboundOrderItem?.item.amount_unit_inventory}`
                                        : "UN"
                                }
                                decimal={3}
                                onChangeValue={(e) =>
                                    setOutboundOrderItem({
                                        ...outboundOrderItem,
                                        amount: e.target.valueNumber,
                                        total: e.target.valueNumber * outboundOrderItem.price_unit,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <InputDecimal
                                label={"Valor unitário"}
                                value={outboundOrderItem.price_unit}
                                symbol="R$"
                                decimal={2}
                                onChangeValue={(e) =>
                                    setOutboundOrderItem({
                                        ...outboundOrderItem,
                                        price_unit: e.target.valueNumber,
                                        total: e.target.valueNumber * outboundOrderItem.amount,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <InputDecimal label={"Total do item"} value={outboundOrderItem.total} symbol="R$" decimal={2} disabled={true} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputText
                                label="Pedido"
                                value={outboundOrderItem.purchase_number || ""}
                                onChangeValue={(e) => setOutboundOrderItem({ ...outboundOrderItem, purchase_number: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputText
                                label="Pedido Item"
                                value={outboundOrderItem.purchase_number_item || ""}
                                onChangeValue={(e) => setOutboundOrderItem({ ...outboundOrderItem, purchase_number_item: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                        <Grid item xs={12} sm={12}>
                            <Button
                                style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={() => setOutboundOrderItem(initialValuesItems)}
                            >
                                Novo item
                            </Button>
                            <Button
                                style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    const indexItem = outboundOrderItems.findIndex((rec) => rec.item_seq === outboundOrderItem.item_seq);
                                    const outboundOrderItemsCustom = [...outboundOrderItems];

                                    // Populate default id's
                                    outboundOrderItem.item_id = outboundOrderItem.item.id;
                                    outboundOrderItem.operation_nature_id = outboundOrderItem.operation_nature.id;

                                    if (indexItem === -1) {
                                        outboundOrderItem.item_seq = (outboundOrderItems.length || 0) + 1;
                                        outboundOrderItemsCustom.push(outboundOrderItem);
                                    } else {
                                        outboundOrderItemsCustom[indexItem] = outboundOrderItem;
                                    }

                                    setOutboundOrderItems(outboundOrderItemsCustom);
                                    setOutboundOrderItem(initialValuesItems);
                                }}
                            >
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grade dataSource={outboundOrderItems} metaData={metaDataItems} disablepagination={true} maxHeight={300} />
                    </Grid>
                </TabSheet>
            </PageControl>
        </>
    );
}
