import React, { useMemo, useState } from "react";

import { Grade, GradeOpcoes, InputDropDowList2 as InputDropDowList } from "../../../../components";

import { Grid, Button } from "@material-ui/core";

import QueryTaxesRules from "../../../fiscal/query/taxesRules";
import QueryMembers from "../../../basics/query/members";

import OrdersTypesUtils, { TypeOperation } from "../../ordersTypes/utils";

const initialValuesTaxes = {
    branch_id: 0,
    branch: {},
    type_operation: "in",
    tax_rule_id: 0,
    tax_rule: {},
};

export default function ItemsTaxesChange({ itemTaxes, setItemTaxes }) {
    const [itemTax, setItemTax] = useState(initialValuesTaxes);

    const metaDataItemTaxes = useMemo(() => [
        {
            label: "Opções",
            render: (record, index) => {
                const options = [
                    { tipo: "edit", label: "Edição" },
                    { tipo: "remove", label: "Exclusão" },
                ];

                return (
                    <span>
                        <GradeOpcoes record={record} indexRecord={index} onClickOpcoes={onClickOptionsTaxes} opcoes={options} />
                    </span>
                );
            },
        },
        { label: "Cód.Estab", field: "branch_id", align: "right" },
        {
            label: "Estabelecimento",
            render: (record) => {
                return <>{record.branch?.name}</>;
            },
        },
        {
            label: "Tipo Operação",
            render: (record) => {
                return <>{OrdersTypesUtils.typeOperationText(record.type_operation, false)}</>;
            },
        },
        { label: "Cód.Regra", field: "tax_rule_id", align: "right" },

        {
            label: "Regra Tributária",
            render: (record) => {
                return <>{record.tax_rule?.name}</>;
            },
        },
        { label: "Dh.Criação", field: "created_at", format: "datetime", minWidth: 160 },
        { label: "Dh.Atualização", field: "updated_at", format: "datetime", minWidth: 160 },
    ]);

    const onClickOptionsTaxes = async (record, type, index) => {
        switch (type) {
            case "edit":
                setItemTax({ ...record });
                break;
            case "remove":
                const itemTaxesCustom = [...itemTaxes];
                itemTaxesCustom.splice(index, 1);
                setItemTaxes(itemTaxesCustom);
                break;
            default:
                break;
        }
    };

    return (
        <>
            <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <QueryMembers
                            title="Consulta Estabelecimentos"
                            roleMember="branch"
                            label="Estabelecimento"
                            value={itemTax.branch}
                            onChangeValue={(data) => setItemTax({ ...itemTax, branch: data })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <InputDropDowList
                            value={itemTax.type_operation}
                            onChangeValue={(e) => setItemTax({ ...itemTax, type_operation: e.target.value })}
                            data={TypeOperation}
                            label={"Tipo Operação"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <QueryTaxesRules
                            label={"Regra Tributária"}
                            value={itemTax.tax_rule}
                            onChangeValue={(data) => setItemTax({ ...itemTax, tax_rule: data })}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => setItemTax(initialValuesTaxes)}
                    >
                        Nova regra
                    </Button>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            const indexDocumentoTax = itemTaxes.findIndex(
                                (rec) => rec.tax_id === itemTax.branch.id && rec.type_operation === itemTax.type_operation
                            );
                            const itemTaxesCustom = [...itemTaxes];

                            itemTax.branch_id = itemTax.branch.id;
                            itemTax.tax_rule_id = itemTax.tax_rule.id;

                            if (indexDocumentoTax === -1) {
                                itemTaxesCustom.push(itemTax);
                            } else {
                                itemTaxesCustom[indexDocumentoTax] = itemTax;
                            }

                            setItemTaxes(itemTaxesCustom);
                            setItemTax(initialValuesTaxes);
                        }}
                    >
                        Salvar
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grade dataSource={itemTaxes} metaData={metaDataItemTaxes} disablepagination={true} />
            </Grid>
        </>
    );
}
