import React, { useCallback, useMemo, useState } from "react";

import { Grade, GradeOpcoes, InputDropDowList2 as InputDropDowList } from "../../../../components";

import { Grid, Button } from "@material-ui/core";

import QueryMembers from "../../../basics/query/members";
import FormatsUtils from "../../../../services/FormatsUtils";
import { AttributesModal } from "../../../../components/attributes";

const initialValues = {
    member_id: 0,
    member: {},
    relation_type: "",
    attributes: {},
};

export default function MembersRelationshipsChange({ relationTypes, relationAttrs, membersRelationships, setMembersRelationships }) {
    const [relationships, setRelationships] = useState(initialValues);
    const [openMemberRelationships, setOpenMemberRelationships] = useState(false);

    const attributesByRelationType = useCallback(
        (relationType) => {
            const attr = relationAttrs.find((rec) => rec.field === `$${String(relationType).replace("_reverse", "")}`);

            if (attr) {
                return attr.values;
            }

            return [];
        },
        [relationAttrs]
    );

    const relationTypesGroup = useMemo(() => {
        const result = {};

        if (relationTypes?.length) {
            relationTypes.forEach((rec) => {
                result[rec.value] = rec.name;
                result[rec.value + "_reverse"] = rec.nameReverse;
            });
        }

        return result;
    }, [relationTypes]);

    const relationTypesOptions = useMemo(() => {
        const result = [];

        if (relationTypes?.length) {
            relationTypes.forEach((rec) => {
                result.push({
                    text: rec.name,
                    value: rec.value,
                });
                result.push({
                    text: rec.nameReverse,
                    value: rec.value + "_reverse",
                });
            });
        }

        return result;
    }, [relationTypes]);

    const metaData = useMemo(() => [
        {
            label: "Opções",
            render: (record, index) => {
                const options = [
                    { tipo: "edit", label: "Edição" },
                    { tipo: "remove", label: "Exclusão" },
                ];

                return (
                    <span>
                        <GradeOpcoes record={record} indexRecord={index} onClickOpcoes={onClickOptions} opcoes={options} />
                    </span>
                );
            },
        },
        { label: "Membro", field: "member_id", align: "right" },
        {
            label: "Tipo Relação",
            render: (record) => {
                return <>{relationTypesGroup[record.relation_type]}</>;
            },
        },
        {
            label: "Documento",
            render: (record) => {
                return <>{FormatsUtils().document(record.member?.document, record.member?.type_member)}</>;
            },
        },
        {
            label: "Nome",
            render: (record) => {
                return <>{record.member?.name}</>;
            },
        },
    ]);

    const onClickOptions = async (record, type, index) => {
        switch (type) {
            case "edit":
                setRelationships({ ...record });
                break;
            case "remove":
                const relationshipsCustom = [...membersRelationships];
                relationshipsCustom.splice(index, 1);
                setMembersRelationships(relationshipsCustom);
                break;
            default:
                break;
        }
    };

    return (
        <>
            <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={2}>
                        <InputDropDowList
                            value={relationships.relation_type}
                            onChangeValue={(e) => setRelationships({ ...relationships, relation_type: e.target.value })}
                            data={relationTypesOptions}
                            label={"Tipo Relação"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <QueryMembers
                            title="Consulta Membros"
                            label="Membro"
                            value={relationships.member}
                            onChangeValue={(data) => setRelationships({ ...relationships, member: data })}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => setRelationships(initialValues)}
                    >
                        Nova relação
                    </Button>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        disabled={!relationships?.relation_type}
                        onClick={() => {
                            setOpenMemberRelationships(true);
                        }}
                    >
                        Parâmetros
                    </Button>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            const membersRelationshipsIndex = membersRelationships.findIndex(
                                (rec) => rec.member_id === relationships.member.id && rec.relation_type === relationships.relation_type
                            );
                            const membersRelationshipsCustom = [...membersRelationships];

                            relationships.member_id = relationships.member.id;

                            if (membersRelationshipsIndex === -1) {
                                membersRelationshipsCustom.push(relationships);
                            } else {
                                membersRelationshipsCustom[membersRelationshipsIndex] = relationships;
                            }

                            setMembersRelationships(membersRelationshipsCustom);
                            setRelationships(initialValues);
                        }}
                    >
                        Salvar
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grade dataSource={membersRelationships} metaData={metaData} disablepagination={true} />
            </Grid>
            <AttributesModal
                open={openMemberRelationships}
                setOpen={(open) => setOpenMemberRelationships(open)}
                attributes={attributesByRelationType(relationships.relation_type)}
                value={relationships.attributes}
                onChangeValue={(data) => setRelationships({ ...relationships, attributes: data })}
            />
        </>
    );
}
