export default function ListUtils() {
    const removeDuplicates = (value) => {
        return [...new Set(value)].filter((record) => record !== null);
    };

    /*
    const groupingUnique<T> = (list: T[], fieldNumber: string): TGrouping<T> => {
        const result: TGrouping<T> = {}

        list.forEach((rec: T) => {
            result[rec[fieldNumber]] = rec;
        });

        return result;
    },
    grouping<T>(list: T[], fieldNumber: string): TGroupingList<T> {
        const result: TGroupingList<T> = {}

        list.forEach((rec: T) => {
            if (!result[rec[fieldNumber]]) {
                result[rec[fieldNumber]] = [];
            }

            result[rec[fieldNumber]].push(rec);
        });

        return result;
    },
    */

    const sort = (list, field, direction = "asc") => {
        if (direction === "asc") {
            return list.sort((itemA, itemB) => {
                if (itemA[field] < itemB[field]) return -1;
                if (itemA[field] > itemB[field]) return 1;
                return 0;
            });
        } else {
            return list.sort((itemA, itemB) => {
                if (itemA[field] > itemB[field]) return -1;
                if (itemA[field] < itemB[field]) return 1;
                return 0;
            });
        }
    };

    return {
        removeDuplicates,
        sort,
    };
}
