import React, { useState, useEffect, useMemo } from "react";

import { PageControl, TabSheet } from "../../../../components";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from "@material-ui/core";
import ApiInboundOrders from "../../../../api/suppliers/inboundOrders/ApiInboundOrders";
import Table from "../../../../components/grade/Table";
import TablePagination from "../../../../components/grade/TablePagination";

export default function InboundOrdersDetails({ id, open, setOpen, setLoading }) {
    const [tabIndex, setTabIndex] = useState(0);

    const [dataSourceItems, setDataSourceItems] = useState([]);
    const [dataSourceReferences, setDataSourceReferences] = useState([]);

    const [dataSourceItemsView, setDataSourceItemsView] = useState([]);
    const [dataSourceItemsPage, setDataSourceItemsPage] = useState(0);
    const [dataSourceItemsPerPage, setDataSourceItemsPerPage] = useState(10);

    const [dataSourceRefView, setDataSourceRefView] = useState([]);
    const [dataSourceRefPage, setDataSourceRefPage] = useState(0);
    const [dataSourceRefPerPage, setDataSourceRefPerPage] = useState(10);

    const metaDataItems = useMemo(() => [
        { label: "Seq", field: "item_seq" },
        { label: "Código", field: "item_id" },
        { label: "Nome do item", field: "item_name", minWidth: 175 },
        { label: "Quant.", field: "amount", format: "float", decimal: 3, align: "right" },
        { label: "Preço Unit.", field: "price_unit", format: "float", decimal: 2, align: "right" },
        { label: "Total", field: "total", format: "float", decimal: 2, align: "right" },
        { label: "Qtd.Remessa", field: "amount_delivery", format: "float", decimal: 3, align: "right" },
        { label: "Qtd.Ref.", field: "amount_reference", format: "float", decimal: 3, align: "right" },
        { label: "Nat.Operação", field: "operation_nature_id", align: "center" },
        { label: "Pedido", field: "purchase_number" },
        { label: "Ped.Item", field: "purchase_number_item" },
    ]);

    const metaDataReferences = useMemo(() => [
        { label: "Código", field: "item_id" },
        { label: "Nome do item", field: "item_name", minWidth: 175 },
        { label: "Quant.", field: "amount", format: "float", decimal: 3, align: "right" },
        { label: "Total", field: "total", format: "float", decimal: 2, align: "right" },
        { label: "Nat.Operação", field: "operation_nature_id", align: "center" },
        { label: "Qtd.Ref", field: "outbound_amount_reference", format: "float", decimal: 3, align: "right" },
        { label: "Total.Ref", field: "outbound_total", format: "float", decimal: 2, align: "right" },
        { label: "Nat.Op.Ref", field: "outbound_operation_nature_id", align: "center" },
        { label: "Ord.Saída", field: "outbound_order_id" },
        { label: "Doctos Fiscais", field: "outbound_list_document_number" },
    ]);

    useEffect(() => {
        onLoadData();
    }, [id, open]);

    const onLoadData = async () => {
        if (!id || !open) {
            return;
        }

        setLoading(true);
        try {
            setDataSourceItems([]);

            const result = await ApiInboundOrders().details(id);

            if (result.status) {
                setDataSourceItems(result.data?.items || []);
                setDataSourceReferences(result.data?.references || []);

                refreshItemsView(0, dataSourceItemsPerPage, result.data?.items || []);
                refreshReferencesView(0, dataSourceRefPerPage, result.data?.references || []);
            } else {
                setDataSourceItems([]);
                setDataSourceReferences([]);

                setDataSourceItemsView([]);
                setDataSourceRefView([]);

                setOpen(false);
            }
        } finally {
            setLoading(false);
        }
    };

    const refreshItemsView = (page, perPage, data) => {
        setDataSourceItemsPerPage(perPage);
        setDataSourceItemsPage(page);

        const initial = page === 0 ? 0 : perPage * page;
        const length = page === 0 ? perPage : perPage * (page + 1);

        setDataSourceItemsView(data.slice(initial, length));
    };

    const refreshReferencesView = (page, perPage, data) => {
        setDataSourceRefPerPage(perPage);
        setDataSourceRefPage(page);

        const initial = page === 0 ? 0 : perPage * page;
        const length = page === 0 ? perPage : perPage * (page + 1);

        setDataSourceRefView(data.slice(initial, length));
    };

    return (
        <Dialog
            fullWidth
            maxWidth={"xl"}
            open={open}
            onClose={(e, reason) => {
                if (!["backdropClick", "escapeKeyDown"].includes(reason)) {
                    setOpen(false);
                }
            }}
        >
            <DialogTitle>Detalhes - Registro {id}</DialogTitle>
            <DialogContent>
                <PageControl tabindex={tabIndex} onchangetab={(index) => setTabIndex(index)}>
                    <TabSheet label="Itens">
                        <Table dataSource={dataSourceItemsView} metaData={metaDataItems} />
                        <TablePagination
                            rowCount={dataSourceItems.length}
                            rowPage={dataSourceItemsPerPage}
                            page={dataSourceItemsPage}
                            onPageChange={(event, newPage) => {
                                refreshItemsView(newPage, dataSourceItemsPerPage, dataSourceItems);
                            }}
                            onRowsPerPageChange={(event) => {
                                refreshItemsView(0, event.target.value, dataSourceItems);
                            }}
                        />
                    </TabSheet>
                    <TabSheet label="Referências">
                        <Table dataSource={dataSourceRefView} metaData={metaDataReferences} />
                        <TablePagination
                            rowCount={dataSourceReferences.length}
                            rowPage={dataSourceRefPerPage}
                            page={dataSourceRefPage}
                            onPageChange={(event, newPage) => {
                                refreshReferencesView(newPage, 10, dataSourceReferences);
                            }}
                            onRowsPerPageChange={(event) => {
                                refreshReferencesView(0, event.target.value, dataSourceReferences);
                            }}
                        />
                    </TabSheet>
                </PageControl>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <Button style={{ float: "right", marginRight: "15px" }} size="small" variant="contained" color="primary" onClick={() => setOpen(false)}>
                            Fechar
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
