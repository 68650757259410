import React, { useContext, useState, useMemo, useEffect } from "react";

import { Button, Grid, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ApiService from './ApiService';

import { AppContext } from "../../../../App";
import { ModalEstabelecimentos, Grade, InputFile, InputDate } from "../../../../components";

import formatUtils from "../../../../services/formatUtils";
import { getFinalMes, getInicioMes } from "../../../../services/date.services";
import { AppOptionsContext } from "../../../../components/main/main";

const useStyles = makeStyles((theme) => ({
    buttonVinculos: {
        margin: theme.spacing(1, 1, 0, 0),
        float: "left"
    },
    buttonExportar: {
        margin: theme.spacing(1, 1, 0, 0),
        float: "left"
    },
    card: {
        margin: theme.spacing(0, 0, 1, 0),
    }
}));

export default function LeiauteConsorcios() {
    const { setLoading, setMessageError } = useContext(AppContext);
    const { setOptions, setTitle } = useContext(AppOptionsContext);

    const [ selecionados, setSelecionados] = useState(0);
    const [ dataFiles, setDataFiles] = useState([]);
    const [ estabelecimento, setEstabelecimento] = useState({});
    const [ dataLancamento, setDataLancamento ] = useState(getFinalMes(getInicioMes()));
    const classes = useStyles();

    const metaDataFiles = [
        {label: "", render: (record, index) => { 
            return (
                <Checkbox 
                    checked={record.selecionado}
                    onChange={e => onCheckDataFile(index, e.target.checked)}
                />) 
            }
        },
        {label: "Situação", field: "situacao" },
        {label: "Consorcio", render: (record, index) => {
            let consorcio = "ND";

            if (record.consorcio) {
                consorcio = `${record.consorcio} (${formatUtils.formatCPF_CNPJ(record.consorcio_cnpj_cpf)})`;
            }

            return (
                <span>
                    {record.arquivo}<br />
                    {consorcio}
                </span>
            )
        }},
        {label: "Débito", field: "valor_debito", format: "float", decimal: 2, align: "right" },
        {label: "Crédito", field: "valor_credito", format: "float", decimal: 2, align: "right" },
        {label: "Diferença", field: "valor_diferenca", format: "float", decimal: 2, align: "right" },
        {label: "Contas", field: "quantidade_contas", align: "right" },
        {label: "Inconsistências", field: "quantidade_inconsistencias", align: "right" },
    ]

    const onCheckDataFile = (index, selecionado) => {
        const dataFilesCustom = [ ...dataFiles];
        dataFilesCustom[index].selecionado = selecionado;

        setSelecionados(selecionados + (selecionado ? 1 : -1));
        setDataFiles(dataFilesCustom);
    }

    const onProcessFiles = async(dataFiles) => {
        setLoading(true);

        try {
            const files = new FormData();

            dataFiles.forEach(data => {
                files.append(data.arquivo, data.file, data.arquivo);
            });

            const response = await ApiService.importFiles(
                estabelecimento.id_estabelecimento,
                formatUtils.formatDateAnsi(dataLancamento),
                files
            );

            if (!response.status) {
                setMessageError({ active: true, msg: response.message });
            } else {
                const consorcios = response.data.data;
                const dataFilesCustom = [...dataFiles];

                for (const consorcio of consorcios) {
                    const dataFileIndex = dataFilesCustom.findIndex(rec => rec.arquivo === consorcio.arquivo);

                    if (dataFileIndex !== -1) {
                        dataFilesCustom[dataFileIndex] = { ...dataFilesCustom[dataFileIndex], ...consorcio };
                    }
                }

                setDataFiles(dataFilesCustom);
            }
        } finally {
            setLoading(false);
        }
    }

    const onClearData = () => {
        setDataFiles([]);
        setEstabelecimento({});
        setDataLancamento(null);
        setSelecionados(0);
    }

    const onLoadFiles = async(e) => {
        setLoading(true);

        const files = Array.from(e.target.files);
        const dataFilesCustom = [];
       
        try {
            for (const file of files) {        
                dataFilesCustom.push({
                    selecionado: true,
                    situacao: "Em processamento",
                    arquivo: file.name,
                    path: "",
                    consorcio: "ND",
                    consorcio_cnpj_cpf: "",
                    valor_debito: 0,
                    valor_credito: 0,
                    valor_diferenca: 0,
                    quantidade_contas: 0,
                    quantidade_inconsistencias: 0,
                    file,
                });
            }

            setDataFiles(dataFilesCustom);
            setSelecionados(dataFilesCustom.length);            
        } finally {
            setLoading(false);
        }

        // Processa os arquivos
        onProcessFiles(dataFilesCustom);
    }

    const onExportInconsistencias = async() => {
        const dataFilesCustom = dataFiles.filter(rec => rec.selecionado && rec.quantidade_inconsistencias);

        if (!dataFilesCustom.length) {
            setMessageError({ active: true, message: "Não há inconsistencias nos arquivos selecionados" });
            return;
        }

        const response = await ApiService.exportInconsistencias({
            estabelecimento: estabelecimento.id_estabelecimento,
            arquivos: dataFilesCustom.map(rec => rec.path),
        });

        if (!response.status) {
            setMessageError({ active: true, message: response.message });
            return;
        }
    }

    const onExport = async() => {
        const dataFilesCustom = dataFiles.filter(rec => rec.selecionado && rec.quantidade_contas);

        if (!dataFilesCustom.length) {
            setMessageError({ active: true, message: "Não há dados para serem gerados nos arquivos selecionados" });
            return;
        }

        const dataLancamentoCustom = formatUtils.formatDateAnsi(dataLancamento);
        const fileName = `Consorcio-${estabelecimento.cnpj_cpf}-${dataLancamentoCustom}`;
        const response = await ApiService.export({
            estabelecimento: estabelecimento.id_estabelecimento,
            data: dataLancamentoCustom,
            arquivos: dataFilesCustom.map(rec => rec.path),            
        }, fileName);

        if (!response.status) {
            setMessageError({ active: true, message: response.message });
            return;
        }
    }

    const onValidImport = useMemo(() => {
        return estabelecimento?.id_estabelecimento && dataLancamento;
    }, [ estabelecimento, dataLancamento ]);

    useEffect(() => {
        setTitle("Consórcios");
        return () => {
            setOptions(null);
        };
    }, []);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <ModalEstabelecimentos
                        label="Estabelecimento"
                        valueId={estabelecimento?.id_estabelecimento || null}
                        onChangeValue={(data) => setEstabelecimento(data)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <InputDate
                        label="Data Lançamento"
                        value={dataLancamento}
                        onChangeValue={(date) => setDataLancamento(date) } 
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <InputFile
                        className={classes.buttonExportar}
                        color="primary"
                        multiple             
                        onFileSelect={(e) => onLoadFiles(e)}
                        fileExtension={".xlsx"}
                        label={"Importar Arquivos"}
                        disabled={!onValidImport}
                    />
                    <Button 
                        size="small"
                        className={classes.buttonExportar}
                        variant="contained"
                        color="secundary"
                        disabled={!selecionados || !onValidImport}
                        onClick={() => onExport()}
                    >
                        Gerar Leiautes
                    </Button>
                    <Button 
                        size="small"
                        className={classes.buttonVinculos}
                        variant="contained"
                        color="secondary"
                        disabled={!selecionados || !onValidImport}
                        onClick={() => onExportInconsistencias()}
                    >
                        Gerar Inconsistências
                    </Button>
                    <Button 
                        size="small"
                        className={classes.buttonVinculos}
                        variant="contained"
                        color="secondary"
                        disabled={dataFiles.length === 0}
                        onClick={() => onClearData()}
                    >
                        Limpar Dados
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grade 
                    dataSource={dataFiles} 
                    metaData={metaDataFiles}
                    disablepagination
                />
            </Grid>       
        </>
    )
}