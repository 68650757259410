import React, { useContext, useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";

import logo from "../../files/logo.ico";

import auth from "../../services/auth";
import { login, loginValidate } from "./login-service";

import { InputText } from "../../components";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import Grid from "@material-ui/core/Grid";
import { AppContext } from "../../App";

export function Logout() {
    useEffect(() => {
        auth.RemoveToken();
    }, []);

    return <Redirect to="/login" />;
}

export default function Login() {
    const { showMessageError } = useContext(AppContext);

    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [logged, setLogged] = useState(auth.IsAuthenticed());

    const onLogin = async () => {
        //Validação
        const validate = await loginValidate({ usuario: user, senha: password });
        if (!validate.status) {
            showMessageError(validate.message);
            return;
        }

        //Login
        const response = await login(user, password);
        if (!response.status) {
            showMessageError(response.message);
            return;
        }

        auth.SetToken(response.token);
        setLogged(auth.IsAuthenticed());
    };

    if (logged) {
        return <Redirect to="/" />;
    }

    return (
        <Container
            component="main"
            maxWidth="xs"
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                spacing: "8px",
                justifyContent: "flex-end",
                marginTop: "10%",
            }}
        >
            <img src={logo} style={{ width: "100px", height: "100px" }} alt="Logo" />
            <Typography component="h1" variant="h5">
                nidas.app
            </Typography>
            <InputText
                required
                label="Usuário"
                name="email"
                autoComplete="email"
                margin="normal"
                autoFocus
                value={user}
                onChangeValue={(e) => setUser(e.target.value)}
            />
            <InputText
                required
                name="password"
                label="Senha"
                type="password"
                margin="normal"
                autoComplete="current-password"
                value={password}
                onChangeValue={(e) => setPassword(e.target.value)}
            />
            <Grid container>
                <Grid item>
                    <Link to={"/recuperarsenha/"}>Esqueci minha senha?</Link>
                </Grid>
            </Grid>
            <Button fullWidth size="large" variant="contained" color="primary" onClick={onLogin} disabled={!user || !password}>
                Acessar
            </Button>
        </Container>
    );
}
