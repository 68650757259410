import React from "react";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";

function InputDecimalCustom({ inputRef, onChange, symbol, decimal, ...rest }) {
    return (
        <NumberFormat
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                        valueNumber: values.floatValue || 0,
                    },
                });
            }}
            decimalScale={decimal}
            isNumericString
            fixedDecimalScale
            thousandSeparator="."
            decimalSeparator=","
            prefix={symbol ? symbol + " " : ""}
            {...rest}
        />
    );
}

export default function InputDecimal({ label, value, symbol, decimal, onChangeValue, ...rest }) {
    return (
        <TextField
            label={label}
            fullWidth
            size="small"
            variant="outlined"
            value={value}
            onChange={(e) => {
                onChangeValue(e.target.valueNumber, e.target.value);
            }}
            InputProps={{
                inputComponent: InputDecimalCustom,
                inputProps: {
                    symbol: symbol,
                    decimal: decimal,
                },
            }}
            {...rest}
        />
    );
}
