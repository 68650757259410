import api from "../services/api";

export default function ApiDefault(url) {
    const validate = async (schema, data) => {
        return schema
            .validate(data, { abortEarly: false })
            .then(function (value) {
                return {
                    status: true,
                };
            })
            .catch(function (err) {
                return {
                    status: false,
                    message: err.errors,
                };
            });
    };

    const findAll = async (condition, orderBy, associates) => {
        let urlCustom = `/${url}`;
        let urlConditions = [];

        for (const field in condition) {
            if (condition[field] !== "") {
                urlConditions.push(`${field}=${condition[field]}`);
            }
        }

        if (orderBy) {
            urlConditions.push(`orderby=${orderBy}`);
        }

        if (urlConditions.length) {
            urlCustom += `?${urlConditions.join("&")}`;
        }

        if (associates?.length > 0) {
            urlCustom += `&associates=${associates.join(",")}`;
        }

        return await api
            .get(urlCustom)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const find = async ({ page = 1, pageSize = 5, condition, orderBy, associates }) => {
        let urlCustom = `/${url}?page=${page}&limit=${pageSize}`;

        for (let field in condition) {
            if (condition[field] !== "") {
                urlCustom += `&${field}=${condition[field]}`;
            }
        }

        if (orderBy) {
            urlCustom += `&orderby=${orderBy}`;
        }

        if (associates?.length > 0) {
            urlCustom += `&associates=${associates.join(",")}`;
        }

        return await api
            .get(urlCustom)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const findId = async (id, { associates }) => {
        let urlCustom = `/${url}/${id}`;

        if (associates?.length > 0) {
            urlCustom += `?associates=${associates.join(",")}`;
        }

        return await api
            .get(urlCustom)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const creates = async (data) => {
        return await api
            .post(`/${url}`, data)
            .then((response) => {
                return {
                    status: response.status === 201,
                    data: response.data,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const update = async (id, data) => {
        return await api
            .put(`/${url}/${id}`, data)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const remove = async (id) => {
        return await api
            .delete(`/${url}/${id}`)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const inactive = async (id) => {
        return await api
            .put(`/${url}/${id}/inactive`)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    return {
        find,
        findAll,
        findId,
        creates,
        update,
        remove,
        inactive,
        validate,
        url,
        api,
    };
}
