import React, { useState } from "react";

import { IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

export default function GradeOpcoes(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    const opcoesOpen = Boolean(anchorEl);

    const handleClickOpcoes = (tipo) => {
        let index = props.indexRecord;

        if (index === undefined) {
            index = null;
        }

        props.onClickOpcoes(props.record, tipo, index);
        setAnchorEl(false);
    };

    return (
        <>
            <IconButton
                disabled={!props.opcoes?.length}
                size="small"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(event) => setAnchorEl(event.currentTarget)}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu size="small" anchorEl={anchorEl} keepMounted open={opcoesOpen} onClose={() => setAnchorEl(null)}>
                {props.opcoes.map((opcao, index) => {
                    return (
                        <MenuItem key={index} onClick={() => handleClickOpcoes(opcao.tipo)} disabled={opcao.disabled}>
                            {opcao.label}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
}
