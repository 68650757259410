import React, { useState, useEffect, useContext, useCallback } from "react";

import ApiService from "./ApiService";

import ManutencaoEstabelecimentos from "./ManutencaoEstabelecimentos";

import { Grade, GradeOpcoes, PageControl, TabSheet, InputText } from "../../components";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { AppContext } from "../../App";
import { AppOptionsContext } from "../../components/main/main";

const useStyles = makeStyles((theme) => ({
    buttonSearch: {
        margin: theme.spacing(1, 1, 0, 0),
        float: "right",
    },
    textSearch: {
        margin: theme.spacing(0),
    },
    loading: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const initialFilter = {
    nome: "",
};

const opcoesTipo = [
    {
        tipo: "editar",
        label: "Editar",
    },
];

export default function Estabelecimentos(props) {
    const { setLoading, setMessageError } = useContext(AppContext);
    const { setOptions, setTitle } = useContext(AppOptionsContext);

    const [tabIndex, setTabIndex] = useState(0);
    const [data, setData] = useState([]);
    const [rowsCount, setRowsCount] = useState(0);
    const [condition, setCondition] = useState({
        rowsPage: 5,
        currentPage: 0,
        filter: initialFilter,
        orderBy: "razao_nome,dh_alt-desc",
    });
    const [filter, setFilter] = useState(initialFilter);
    const [currentIdRecord, setCurrentIdRecord] = useState(props.match.params.id || 0);

    const classes = useStyles();

    const metaData = [
        {
            label: "Opções",
            render: (record) => {
                return (
                    <span>
                        <GradeOpcoes record={record} onClickOpcoes={handleClickOpcoes} opcoes={opcoesTipo} />
                    </span>
                );
            },
        },
        { label: "Número", field: "id_estabelecimento", align: "right" },
        {
            label: "Matriz",
            align: "center",
            render: (record) => {
                return <span>{record.id_matriz === 0 ? "-" : record.id_matriz}</span>;
            },
        },
        { label: "CPF/CNPJ", field: "cnpj_cpf", format: "cnpjcpf", minWidth: 175 },
        { label: "Nome", field: "razao_nome" },
        { label: "Cidade", field: "cidade_nome" },
        { label: "UF", field: "cidade_uf" },
        { label: "Dh.Registro", field: "dh_alt", format: "datetime", minWidth: 160 },
    ];

    useEffect(() => {
        onLoadData();
    }, [condition]);

    useEffect(() => {
        setTitle("Estabelecimentos (Obsoleto)");
        return () => {
            setOptions(null);
        };
    }, []);

    const onLoadData = useCallback(async () => {
        setLoading(true);

        const where = {};

        if (condition.filter) {
            if (condition.filter.nome) {
                where.nome = encodeURIComponent(condition.filter.nome);
            }
        }

        try {
            const response = await ApiService.GetAll(condition.currentPage + 1, where, condition.rowsPage, condition.orderBy);

            if (!response.status) {
                setMessageError({ active: true, msg: response.message });
                return;
            }

            setData(response.data.rows);
            setRowsCount(response.data.infoRows.count);
        } finally {
            setLoading(false);
        }
    }, [condition]);

    const handleClickOpcoes = async (record, tipo) => {
        switch (tipo) {
            case "editar":
                setCurrentIdRecord(record.id_estabelecimento);
                break;
            default:
                break;
        }
    };

    const onFinalize = async (updated) => {
        setTabIndex(0);
        setCurrentIdRecord(0);

        if (updated) {
            onLoadData();
        }
    };

    return (
        <>
            <PageControl tabindex={tabIndex} onchangetab={(index) => setTabIndex(index)}>
                <TabSheet label="Consulta">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={10}>
                            <InputText
                                className={classes.textSearch}
                                label="Pesquisa"
                                size="small"
                                autoFocus
                                fullWidth
                                variant="outlined"
                                value={filter.nome}
                                onChangeValue={(e) => setFilter({ ...filter, nome: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button
                                variant="contained"
                                className={classes.buttonSearch}
                                size="small"
                                type="primary"
                                onClick={() => setCondition({ ...condition, filter, currentPage: 0 })}
                            >
                                Pesquisar
                            </Button>
                        </Grid>
                    </Grid>
                    <Grade
                        dataSource={data}
                        metaData={metaData}
                        rowCount={rowsCount}
                        rowPage={condition.rowsPage}
                        currentPage={condition.currentPage}
                        onChangePage={(e, page) => setCondition({ ...condition, currentPage: page })}
                        onChangeRowPage={(e) => setCondition({ ...condition, rowsPage: e.target.value, currentPage: 0 })}
                    />
                </TabSheet>
                <TabSheet label={currentIdRecord > 0 ? "Registro " + currentIdRecord : "Novo Registro"}>
                    <ManutencaoEstabelecimentos id={currentIdRecord} onLoadRecord={() => setTabIndex(1)} onFinalize={onFinalize} />
                </TabSheet>
            </PageControl>
        </>
    );
}
