export const TypeNature = [
    {
        text: "Pessoa",
        value: "natural_person",
    },
    {
        text: "Entidade Privada",
        value: "legal_entity",
    },
    {
        text: "Entidade Pública",
        value: "government",
    },
];

export const TypeNatureAllowNull = [
    {
        text: "Todos",
        value: "-",
    },
    ...TypeNature,
];

export const TypeNatureValues = TypeNature.map(rec => rec.value);

export default function MembersUtils() {
    const typeNatureText = (value) => {
        return TypeNature.find((rec) => rec.value === value)?.text || null;
    };

    return {
        typeNatureText,
    };
}
