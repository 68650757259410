import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import usuariosService from "../usuarios/usuarios.service";

import { MsgError } from '../../components';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';

import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class DefinirSenha extends Component {
    state = {
        data: {
            usuario: "",
            senha: "",
            senha_confirmacao: "",
        },

        changed: false,
        redirect: false,

        error: false,
        errorMsg: "",
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        const { chave } = this.props.match.params;

        if (!chave) {
            this.setState({
                data: { usuario: "<Nao definido>" },
                error: true,
                errorMsg: "Chave não informada na URL.",
            });
            return;
        }

        const service = new usuariosService();

        /* Validação a chave */
        const response = await service.ValidateRecovery(chave);

        if (!response.status) {
            this.setState({ 
                error: true,
                errorMsg: response.message,
            });
            return;
        }

        this.setState({ 
            data: {
                usuario: response.data.login,
            }
        });
    }

    onChangePassword = async(e) => {
        e.preventDefault();

        const { chave } = this.props.match.params;
        const { data } = this.state;

        const service = new usuariosService();
        const validate = await service.ValidateData(usuariosService.definirSenhaSchema, data);

        if (!validate.status) {
            this.setState({ 
                error: true,
                errorMsg: validate.message,
            });
            return;
        }

        /* Validação da senha atual */
        const response = await service.ChangePasswordRecovery(chave, data);

        if (!response.status) {
            this.setState({ 
                error: true,
                errorMsg: response.message,
            });
            return;
        }

        this.setState({ 
            changed: true,
            redirect: false,
        });        
    };

    onCloseError = async () => {
        this.setState({ 
            error: false, 
            errorMsg: "",
        });
    }

    handleCloseChanged = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ 
            changed: false,
            redirect: true,
        });
    }

    render () {
        const { classes } = this.props;
        const { redirect, data, error, errorMsg, changed } = this.state;

        if (redirect) {
            return <Redirect to="/"/>
        }

        return (
            <Container component="main" maxWidth="xs">                
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Recuperação de Login
                    </Typography>
                    <form className={classes.form}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Usuário"
                            autoFocus
                            value={data.usuario}
                            disabled
                            onChange={e => this.setState({ data: { ...this.state.data, usuario: e.target.value }})}
                        />                        
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            autoFocus
                            label="Senha"
                            type="password"
                            value={data.senha} 
                            onChange={e => this.setState({ data: { ...this.state.data, senha: e.target.value }})}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Confirmação de Senha"
                            type="password"
                            value={data.senha_confirmacao} 
                            onChange={e => this.setState({ data: { ...this.state.data, senha_confirmacao: e.target.value }})}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.onChangePassword}>
                            Definir Nova Senha
                        </Button>
                    </form>
                </div>
                <MsgError error={error} errorMsg={errorMsg} onclose={this.onCloseError}/>
                <Snackbar
                    open={changed}
                    autoHideDuration={3000}
                    onClose={this.handleCloseChanged}>
                    <MuiAlert elevation={6} variant="filled" onClose={this.handleCloseChanged} severity="success">
                        Senha alterada com sucesso!
                    </MuiAlert>
                </Snackbar>
            </Container>
    )}
}

export default withStyles(styles)(DefinirSenha);