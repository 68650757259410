import React, { Component } from "react";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
});

class PageControl extends Component {
    state = {
        tabindex: 0,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.tabindex !== this.props.tabindex) {
            this.setState({
                tabindex: this.props.tabindex,
            });
        }
    }

    handleChange = (event, newValue) => {
        this.setState({
            tabindex: newValue,
        });

        this.props.onchangetab(newValue);
    };

    a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    render() {
        const { classes, children, ...rest } = this.props;
        const { tabindex } = this.state;

        const tabsContent = () => {
            let index = -1;

            return React.Children.map(children, (tab, i) => {
                if (tab.props.visible === false) {
                    return null;
                }

                index++;

                return React.cloneElement(tab, { index, tabindex: tabindex, name: tab.props.name });
            });
        };

        const tabs = () => {
            let index = -1;

            return React.Children.map(children, (tab, i) => {
                if (tab.props.visible === false) {
                    return null;
                }

                index++;

                return <Tab label={tab.props.label} name={tab.props.name} icon={tab.props.icon} disabled={tab.props.disabled} {...this.a11yProps(index)} />;
            });
        };

        return (
            <AppBar position="static" color="default" {...rest}>
                <Tabs
                    className={classes.root}
                    value={tabindex}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="auto"
                    variant="scrollable"
                    aria-label="full width tabs example"
                >
                    {tabs()}
                </Tabs>
                {tabsContent()}
            </AppBar>
        );
    }
}

export default withStyles(styles)(PageControl);
