import React, { useCallback, useContext, useEffect, useState } from "react";

import { InputSelector, InputText } from "../../../../components";

import { Grid, Button, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { AppContext } from "../../../../App";

import { AppOptionsContext } from "../../../../components/main/main";
import { TypeItem, TypeOperation } from "../utils";
import { ValidateOrdersTypes } from "../validates";
import ApiOrdersTypes from "../../../../api/basics/ordersTypes";
import QueryDocumentsTypes from "../../query/documentsTypes";

const initialValues = {
    name: "",
    type_operation: "in",
    operation_third: false,
    type_item: "goods",
    document_type_id: 0,
    document_type: {},
    attributes: {},
};

export default function OrdersTypesChange({ id, onLoadRecord, onFinalize, showOptions }) {
    const { setLoading, showMessageError, showMessageInfo } = useContext(AppContext);
    const { setOptions } = useContext(AppOptionsContext);

    const [ordersType, setOrdersType] = useState(initialValues);

    useEffect(() => {
        loadRecord(id);
    }, [id]);

    const loadRecord = async (id) => {
        if (!id || id === 0) return;

        setLoading(true);

        try {
            const result = await ApiOrdersTypes().apiDefault.findId(id, { associates: ["document_type"] });

            if (!result.status) {
                showMessageError(result.message);
                return;
            }

            setOrdersType(result.data);

            onLoadRecord();
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onClearRecord = () => {
        setOrdersType(initialValues);

        if (id) {
            onFinalize(false);
        }
    };

    const onSave = useCallback(
        async (ordersType) => {
            const ordersTypeApply = {
                name: ordersType.name,
                type_operation: ordersType.type_operation,
                type_item: ordersType.type_item,
                document_type_id: ordersType.document_type.id,
                operation_third: ordersType.operation_third,
                attributes: {},
            };

            // Validate
            const validate = await ApiOrdersTypes().apiDefault.validate(ValidateOrdersTypes(), ordersTypeApply);
            if (!validate.status) {
                showMessageError(validate.message);
                return;
            }

            setLoading(true);
            try {
                let response = {};

                if (id) {
                    delete ordersTypeApply.id;

                    response = await ApiOrdersTypes().apiDefault.update(id, ordersTypeApply);
                } else {
                    response = await ApiOrdersTypes().apiDefault.creates(ordersTypeApply);
                }

                if (!response.status) {
                    showMessageError(response.message);
                    return;
                }

                showMessageInfo("Registro atualizado com sucesso");

                onClearRecord();
                onFinalize(true);
            } finally {
                setLoading(false);
            }
        },
        [id]
    );

    useEffect(() => {
        if (showOptions) {
            setOptions(
                <>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => onClearRecord()}
                    >
                        {id ? "Cancelar" : "Limpar tela"}
                    </Button>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => onSave(ordersType)}
                    >
                        Gravar
                    </Button>
                </>
            );
        }
    }, [showOptions, ordersType, id]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <InputText
                            required
                            label="Nome"
                            value={ordersType.name}
                            onChangeValue={(e) => setOrdersType({ ...ordersType, name: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                    <Grid item xs={12} sm={2}>
                        <FormControl variant="outlined" required size="small" fullWidth>
                            <InputLabel id="demo-simple-outlined-label" shrink={true}>
                                Tipo de Operação
                            </InputLabel>
                            <Select
                                defaultValue={"sight"}
                                labelId="demo-simple-outlined-label"
                                id="demo-simple-outlined"
                                value={ordersType.type_operation}
                                onChange={(e) =>
                                    setOrdersType({
                                        ...ordersType,
                                        type_operation: e.target.value,
                                        operation_third: e.target.value === "out" ? false : ordersType.operation_third,
                                    })
                                }
                            >
                                {TypeOperation.map((rec) => {
                                    return <MenuItem value={rec.value}>{rec.text}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputSelector
                            label={"Operação de Terceiros"}
                            value={ordersType.operation_third || false}
                            onChangeValue={(e) => setOrdersType({ ...ordersType, operation_third: e.target.checked })}
                            disabled={ordersType.type_operation === "out"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <FormControl variant="outlined" required size="small" fullWidth>
                            <InputLabel id="demo-simple-outlined-label" shrink={true}>
                                Tipo de Item
                            </InputLabel>
                            <Select
                                defaultValue={"sight"}
                                labelId="demo-simple-outlined-label"
                                id="demo-simple-outlined"
                                value={ordersType.type_item}
                                onChange={(e) => setOrdersType({ ...ordersType, type_item: e.target.value })}
                            >
                                {TypeItem.map((rec) => {
                                    return <MenuItem value={rec.value}>{rec.text}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                    <Grid item xs={12} sm={6}>
                        <QueryDocumentsTypes
                            title="Consulta Tipos de Documentos"
                            label="Tipo de Documento"
                            value={ordersType.document_type}
                            onChangeValue={(data) => setOrdersType({ ...ordersType, document_type: data })}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
