import ApiDefault from "../../ApiDefault";

export default function ApiMembers() {
    const apiDefault = ApiDefault("v2/basics/members");

    // Query
    const query = async ({ page = 1, pageSize = 5, condition }) => {
        let urlCustom = `/${apiDefault.url}/query?page=${page}&limit=${pageSize}`;

        for (let field in condition) {
            if (condition[field] !== "") {
                urlCustom += `&${field}=${condition[field]}`;
            }
        }

        return await apiDefault.api.get(urlCustom)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    return {
        apiDefault,
        query,
    };
}
